<template>
  <div>
    <div v-if="productsCart" class="container-bag">
      <div class="container-products col-12 col-md-7 col-xl-8">
        <div class="alert-info-products-in-cart">
          <p class="mb-0">
            Ten en cuenta que los productos añadidos a la bolsa no se reservan.
            Finaliza tu compra para hacerlos tuyos.
          </p>
        </div>
        <card-product-bag
          v-for="(product, index) in groupProductsCart"
          :key="index"
          :product="product"
          :index="index"
        />
        <card-strategy
          v-for="(product, index) in productsEstrategia"
          :key="index + 'estrategia'"
          :data-product="product"
        />
        <div
          v-if="vptCart"
          class="col-12 d-none d-md-block"
          style="background-color: white"
        >
          <div class="container-carousel-bag">
            <h4 class="w-100 mb-4 text-center text-md-start">
              También te podría interesar
            </h4>
            <div class="col-12 col-md-11 mx-auto">
              <carousel
                id="vpt-carousel"
                :dots="false"
                :loop="true"
                :nav="false"
                :responsive="{
                  0: { items: 1, nav: false, stagePadding: 0 },
                  768: { items: 3, nav: false, stagePadding: 0 },
                  1200: { items: 4, nav: false, stagePadding: 0 },
                }"
                class="w-100 position-relative mx-auto"
              >
                <template slot="prev"
                  ><span class="prev nav-button"
                    ><img
                      src="@/assets/icons/left-carousel.svg"
                      alt="left arrow carousel"/></span
                ></template>
                <template slot="next"
                  ><span class="next nav-button"
                    ><img
                      src="@/assets/icons/right-carousel.svg"
                      alt="right arrow carousel"/></span
                ></template>
                <div
                  v-for="(product, index) in vptCart.productos"
                  :key="index + 'carousel'"
                  class="px-1"
                >
                  <product-card
                    class="w-100"
                    :grid-store="1"
                    :index="index"
                    :data-product="product"
                    :vitrinaid="`vpt-cart`"
                    :vitrinaname="`Vitrina vpt card`"
                  />
                </div>
              </carousel>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-5 col-xl-4">
        <div class="container-detail-products">
          <h4 class="text-center mb-4">Resumen de compra</h4>
          <!-- TODO: Falta la parte de eliminar el cupon -->
          <input-cupon v-if="!dataCart.cupon.codigo" />
          <table class="table-detail-products-bag">
            <tbody>
              <tr>
                <td>
                  <span>Subtotal: </span>
                </td>
                <td>
                  <span>
                    ${{
                      new Intl.NumberFormat('de-DE').format(
                        dataCart.carrito_subtotal,
                      )
                    }}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Envío:</span>
                </td>
                <td>
                  <template v-if="dataCart.infoEstrategiaVentas">
                    <span class="fw-bold text-capitalize">
                      {{ dataCart.infoEstrategiaVentas.tipo_estrategia }}
                      <img
                        v-b-popover.hover="
                          dataCart.infoEstrategiaVentas.tooltip_descripcion
                        "
                        class="mx-2"
                        src="@/assets/icons/icon-info.svg"
                        alt="icono información cupon"
                      />
                    </span>
                  </template>
                  <template v-else>
                    <span
                      v-if="parseInt(dataCart.carrito_envio) > 0"
                      class="fw-bold"
                      >${{
                        new Intl.NumberFormat('de-DE').format(
                          dataCart.carrito_envio,
                        )
                      }}</span
                    >
                    <span v-else class="envio-calc fw-bold">Por calcular</span>
                  </template>
                </td>
              </tr>
              <tr v-if="dataCart.cupon.codigo" class="bg-cupon-code">
                <td>
                  <p class="d-flex mb-2">
                    Código: {{ dataCart.cupon.codigo }}
                    <img
                      v-b-popover.hover="dataCart.cupon.tooltip_descripcion"
                      class="mx-2"
                      src="@/assets/icons/icon-info.svg"
                      alt="icono información cupon"
                    />
                  </p>
                  <p class="delet-cupo" @click="deleteCupon()">Eliminar</p>
                </td>
                <td>
                  <span
                    >${{
                      new Intl.NumberFormat('de-DE').format(
                        dataCart.cupon.valor,
                      )
                    }}</span
                  >
                </td>
              </tr>
              <tr v-if="dataCart.cupon.codigo">
                <td>
                  <span>Descuentos:</span>
                </td>
                <td>
                  <span>
                    -${{
                      new Intl.NumberFormat('de-DE').format(
                        dataCart.cupon.valor,
                      )
                    }}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span class="fw-bold">Total:</span>
                </td>
                <td>
                  <span class="fw-bold"
                    >${{
                      new Intl.NumberFormat('de-DE').format(
                        dataCart.carrito_total,
                      )
                    }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mt-5">
            <button
              class="bnt-primary w-100 fw-bold"
              @click="$router.push({ name: 'Checkout' })"
            >
              Finalizar compra
              <img
                style="filter: invert(1); max-width: 32px"
                class="ps-3"
                src="@/assets/icons/arrow-right.svg"
              />
            </button>
          </div>
          <div class="mt-5 d-flex flex-wrap justify-content-center">
            <h4 class="w-100 text-center mb-4">Nuestros medios de pago</h4>
            <img
              src="@/assets/icons/mediosPago/visa.svg"
              alt="icono visa"
              style="max-width: 40px; margin: .3rem; opacity: .7;"
            />
            <img
              src="@/assets/icons/mediosPago/mastercard.svg"
              alt="icono mastercard"
              style="max-width: 32px; margin: .3rem; opacity: .7;"
            />
            <img
              src="@/assets/icons/mediosPago/diners.svg"
              alt="icono diners"
              style="max-width: 66px; margin: .3rem; opacity: .7;"
            />
            <img
              src="@/assets/icons/mediosPago/pse.png"
              alt="icono pse"
              style="max-width: 33px; margin: .3rem; opacity: .7;"
            />
            <img
              src="@/assets/icons/mediosPago/amex.svg"
              alt="icono american express"
              style="max-width: 32px; margin: .3rem; opacity: .7;"
            />
            <img
              src="@/assets/icons/mediosPago/efecty.svg"
              alt="icono efecty"
              style="max-width: 58px; margin: .3rem; opacity: .7;"
            />
            <img
              src="@/assets/icons/mediosPago/nequi.svg"
              alt="icono nequi"
              style="max-width: 72px; margin: .3rem; opacity: .7;"
            />
            <img
              src="@/assets/icons/mediosPago/daviplata.svg"
              alt="icono daviplata"
              style="max-width: 38px; margin: .3rem; opacity: .7;"
            />
            <img
              src="@/assets/icons/mediosPago/rappi.svg"
              alt="icono rappi"
              style="max-width: 39px; margin: .3rem; opacity: .7;"
            />
            <img
              src="@/assets/icons/mediosPago/addi.svg"
              alt="icono addi"
              style="max-width: 47px; margin: .3rem; opacity: .7;"
            />
            <img
              src="@/assets/icons/mediosPago/logo_quac_morado.svg"
              alt="icono quac"
              style="max-width: 60px; margin-right: .3rem"
            />
            <img
              src="@/assets/icons/mediosPago/sistecredito.svg"
              alt="icono sistecredito"
              style="max-width: 82px; margin-right: .3rem"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="loaderBag">
        <skeleton-bag />
      </div>
      <div
        v-else
        class="text-center d-flex flex-wrap align-content-center justify-content-center"
        style="min-height: calc(100vh - 147px)"
      >
        <svg
          id="Shopicons_Light_Smiley-Negative"
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 48 48"
        >
          <path
            id="Trazado_5220"
            data-name="Trazado 5220"
            d="M0,0H48V48H0Z"
            fill="none"
          />
          <g id="Shopicon">
            <path
              id="Trazado_5221"
              data-name="Trazado 5221"
              d="M24,27a5.006,5.006,0,0,1,5,5h2a7,7,0,0,0-14,0h2A5.006,5.006,0,0,1,24,27Z"
            />
            <path
              id="Trazado_5222"
              data-name="Trazado 5222"
              d="M24,42A18,18,0,1,0,6,24,18,18,0,0,0,24,42ZM24,8A16,16,0,1,1,8,24,16.019,16.019,0,0,1,24,8Z"
            />
            <path
              id="Trazado_5223"
              data-name="Trazado 5223"
              d="M27.553,18.9l2,1a1,1,0,0,0,.9-1.79l-2-1a1,1,0,0,0-.9,1.79Z"
            />
            <path
              id="Trazado_5224"
              data-name="Trazado 5224"
              d="M18,20a1,1,0,0,0,.446-.1l2-1a1,1,0,1,0-.9-1.789l-2,1A1,1,0,0,0,18,20Z"
            />
          </g>
        </svg>
        <h4 class="w-100 px-4">
          Aún no tienes productos en tu bolsa de compras
        </h4>
        <p class="px-4">
          Cuando agregues productos a tu bolsa los podrás ver en este espacio
        </p>
        <div class="w-100">
          <button
            class="bnt-primary px-5"
            @click="
              $router.push({
                name: 'StoreCollection',
                params: { collection: 'store' },
              })
            "
          >
            Quiero comprar
          </button>
        </div>
      </div>
    </div>
    <b-modal id="modal-cupon" centered hide-header hide-footer>
      <div v-if="dataCart">
        <h3 class="text-center">{{ dataCart.cupon.tooltip_titulo }}</h3>
        <p class="text-center">{{ dataCart.cupon.tooltip_descripcion }}</p>
        <p class="text-center">
          <a :href="dataCart.cupon.link_pagina_condiciones" target="_blank">
            ver mas
          </a>
        </p>
        <div class="d-flex">
          <button
            class="bnt-primary mx-auto fw-bold px-5"
            @click="$bvModal.hide('modal-cupon')"
          >
            Entendido
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import CardProductBag from '@/components/bag/CardProductBag.vue'
import SkeletonBag from '@/components/bag/SkeletonBag.vue'
import ProductCard from '@/components/bag/ProductCard.vue'
import InputCupon from '@/components/Templates/InputCupon.vue'
import CardStrategy from '@/components/bag/CardStrategy.vue'

import carousel from 'v-owl-carousel'
import { mapActions, mapState } from 'vuex'
import { GA4ViewCart, GA4ViewItemList } from '@/GA4/events'
export default {
  components: {
    CardProductBag,
    InputCupon,
    ProductCard,
    CardStrategy,
    carousel,
    SkeletonBag,
  },
  data() {
    return {
      showVitrina: false,
    }
  },
  computed: {
    ...mapState('cart', [
      'dataCart',
      'productsCart',
      'vptCart',
      'loaderBag',
      'productsEstrategia',
    ]),
    groupProductsCart() {
      let groupProduct = []
      this.productsCart.forEach(product => {
        if (
          !groupProduct.find(
            e =>
              e.producto_sku == product.producto_sku &&
              e.referencia.color_id == product.referencia.color_id &&
              e.referencia.talla.id == product.referencia.talla.id &&
              e.producto_aplica_estrategia == 0,
          ) ||
          product.producto_aplica_estrategia == 1
        ) {
          let dataTemp = product
          dataTemp.countProduct = 1
          groupProduct.push(dataTemp)
        } else {
          groupProduct = groupProduct.filter(e => {
            if (
              e.producto_sku == product.producto_sku &&
              e.referencia.color_id == product.referencia.color_id &&
              e.referencia.talla.id == product.referencia.talla.id &&
              e.producto_aplica_estrategia == product.producto_aplica_estrategia
            ) {
              e.countProduct += 1
            }
            return e
          })
        }
      })
      return groupProduct
    },
  },
  watch: {
    dataCart() {
      GA4ViewCart()
    },
    vptCart() {
      this.validateVitrinaVPT()
    },
  },
  mounted() {
    this.$store.state.layouts.footerLayoutClover = 'footer-clover-simple'
    this.$store.state.layouts.menuLayoutClover = 'menu-layout-simple'
    if (this.dataCart) {
      GA4ViewCart()
      this.validateVitrinaVPT()
    }
    document.addEventListener('scroll', this.validateVitrinaVPT)
  },
  beforeDestroy() {
    this.$store.state.layouts.footerLayoutClover = 'footer-clover'
    this.$store.state.layouts.menuLayoutClover = 'menu-clover'
  },
  methods: {
    ...mapActions('cart', ['deleteCupon']),
    getDataAnalytics() {
      let arrayTemp = []
      this.productsCart.forEach(product => {
        arrayTemp.push({
          name: product.producto_sku + '-' + product.producto_titulo,
          size: product.referencia.talla_id,
          brand: product.marca,
          id: product.producto_sku,
          price: product.precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
    validateVitrinaVPT() {
      let vitrina = document.getElementById('vpt-carousel')
      if (
        vitrina &&
        !this.showVitrina &&
        vitrina.getBoundingClientRect().top -
          (window.innerHeight - vitrina.offsetHeight / 2) <=
          0
      ) {
        GA4ViewItemList(
          this.vptCart.productos,
          `vitrina-bolsos-vpt`,
          `vitrina bolsos vpt`,
        )
        this.showVitrina = true
        document.removeEventListener('scroll', this.validateVitrinaVPT)
      }
    },
  },
}
</script>
<style lang="scss">
.alert-info-products-in-cart {
  background-color: #f2f2f2;
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 10px;
  text-align: center;
}
</style>
