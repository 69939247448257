<template>
  <div class="btn-add-product" @click="sendSoliAddProductFavorite()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
    >
      <g
        id="Grupo_7148"
        data-name="Grupo 7148"
        transform="translate(16734 6219)"
      >
        <rect
          id="Rectángulo_2959"
          data-name="Rectángulo 2959"
          width="50"
          height="50"
          rx="5"
          transform="translate(-16734 -6219)"
          fill="#e9e9e9"
        />
        <path
          id="Trazado_8068"
          :fill="validateProduct ? 'black' : 'none'"
          data-name="Trazado 8068"
          d="M232.886,108.964a4.415,4.415,0,0,0,1.188,3.022l0,0,.26.26,5.675,5.674.292.292.292-.293,6-6.016.02-.019a4.444,4.444,0,0,0-.088-5.908v0l-.121-.121a4.439,4.439,0,0,0-6.1-.147,4.438,4.438,0,0,0-6.122.13l-.121.121v0a4.414,4.414,0,0,0-1.17,3"
          transform="translate(-16949.611 -6305.044)"
          stroke="#000"
          stroke-width="1"
        />
      </g>
    </svg>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    color: {
      type: String,
      required: true,
      default: '',
    },
    productSku: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      selected: false,
    }
  },
  computed: {
    ...mapState('favorite', [
      'productsFavorite',
      'msgErrorAddProductToFavorite',
      'errorAddproductToFavorite',
    ]),
    validateProduct() {
      return (
        this.productsFavorite &&
        this.productsFavorite.find(
          e => e.producto_sku + e.color == this.productSku + this.color,
        )
      )
    },
  },
  methods: {
    ...mapActions('favorite', [
      'addProductToFavorite',
      'deleteProductToFavorite',
    ]),
    async sendSoliAddProductFavorite() {
      let data = {
        sku: this.productSku,
        color: this.color,
      }
      if (this.validateProduct) {
        await this.deleteProductToFavorite(data)
      } else {
        await this.addProductToFavorite(data)
        let tempProduct = this.productsFavorite.find(
          e => e.producto_sku + e.color == this.productSku + this.color,
        )
        // CL-WishList
        window.dataLayer.push({
          event: 'CL-WishList',
          item_list_name: this.$route.name,
          currency: 'COP',
          item: tempProduct,
          value: tempProduct.producto_precio,
        })
      }
    },
  },
}
</script>
<style lang="scss">
.btn-add-product {
  cursor: pointer;
}
</style>
