<template>
  <div
    v-if="
      arrayProducts && arrayProducts.items && arrayProducts.items.length > 0
    "
    class="content-carousel"
  >
    <div class="container-page">
      <div
        class="mb-4 d-flex flex-wrap align-items-center justify-content-center"
      >
        <h4 class="w-100 text-center">
          {{ arrayProducts.title }}
          {{ arrayProducts.slug ? arrayProducts.slug : '' }}
        </h4>
        <div v-if="arrayProducts.query" class="decorador-vitrina">
          <router-link
            :to="{
              name: 'StoreCollection',
              params: { collection: arrayProducts.query },
              query: {},
            }"
            class="link-products"
            >Ver productos</router-link
          >
        </div>
      </div>
      <carousel
        :id="idVitrina"
        :key="'update...' + arrayProducts.slug"
        class="content-carousel-products"
        :style="
          `background: linear-gradient(transparent 0%, transparent 50%, ${arrayProducts.background_color} 50%);`
        "
        :dots="false"
        :lazy-load="true"
        :nav="false"
        :responsive="{
          0: { items: 1, nav: false, stagePadding: 40 },
          768: { items: 3, nav: false, stagePadding: 0 },
          1500: { items: 4, nav: false, stagePadding: 30 },
          1900: { items: 5, nav: false, stagePadding: 15 },
        }"
      >
        <template slot="prev"
          ><span class="prev nav-button"
            ><img
              src="@/assets/icons/left-carousel.svg"
              alt="left arrow carousel"/></span
        ></template>
        <template slot="next"
          ><span class="next nav-button"
            ><img
              src="@/assets/icons/right-carousel.svg"
              alt="right arrow carousel"/></span
        ></template>
        <div
          v-for="(productCarousel, index) in arrayProducts.items"
          :key="index + 'carousel'"
        >
          <product-card
            class="w-100"
            :index="index"
            :data-product="productCarousel"
            :vitrinaid="`vitrina-${arrayProducts.query}`"
            :vitrinaname="
              `vitrina ${arrayProducts.title} ${arrayProducts.slug}`
            "
          />
        </div>
      </carousel>
    </div>
  </div>
</template>
<script>
import ProductCard from '@/components/Templates/ProductCard.vue'

import { GA4ViewItemList } from '@/GA4/events'
import carousel from 'v-owl-carousel'
export default {
  components: { carousel, ProductCard },
  props: {
    arrayProducts: {
      type: Object,
      required: true,
    },
    idVitrina: {
      type: String,
      required: true,
      default: 'vitrina-',
    },
    nameVitrina: {
      type: String,
      required: true,
      default: 'vitrina',
    },
    slugVitrina: {
      type: String,
      required: true,
      default: 'vitrina_',
    },
  },
  data() {
    return {
      viewVitrina: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.validateViewComponent()
    }, 500)
    window.addEventListener('scroll', this.validateViewComponent)
  },
  methods: {
    validateViewComponent() {
      let vitrina = document.getElementById(this.idVitrina)
      if (
        vitrina &&
        !this.viewvitrina &&
        vitrina.getBoundingClientRect().top -
          (window.innerHeight - vitrina.offsetHeight / 2) <=
          0
      ) {
        this.viewVitrina = true
        GA4ViewItemList(
          this.arrayProducts.items,
          `${this.slugVitrina}`,
          `${this.nameVitrina}`,
        )
        window.removeEventListener('scroll', this.validateViewComponent)
      }
    },
  },
}
</script>
<style lang="scss">
.content-carousel {
  position: relative;
  margin-bottom: 4rem;
  h4 {
    font-size: 32px;
  }
  .decorador-vitrina {
    position: relative;
    margin-bottom: auto;
    margin-left: 1rem;
    min-width: 110px;
    .link-products {
      text-decoration: none;
      color: black;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        bottom: -0.3rem;
        left: -4%;
        width: 108%;
        height: 1px;
        border-radius: 10px;
        background-color: black;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: calc(-0.3rem - 3.5px);
        left: -4%;
      }
    }
    &:hover .link-products::after {
      transition: left 0.6s linear;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-right: 4px solid transparent;
      border-left: 4px solid black;
      left: calc(104% - 2px) !important;
    }
  }
  .content-carousel-products {
    .prev {
      cursor: pointer;
      z-index: 2;
      position: absolute;
      left: calc(10% - 3rem);
      top: 50%;
      transform: translateY(-50%);
    }
    .next {
      cursor: pointer;
      z-index: 2;
      position: absolute;
      right: calc(10% - 3rem);
      top: 50%;
      transform: translateY(-50%);
    }
    @media (max-width: 768px) {
      .prev {
        left: calc(10% - 2rem);
      }
      .next {
        right: calc(10% - 2rem);
      }
    }
  }
}
</style>
