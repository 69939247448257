var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.arrayProducts && _vm.arrayProducts.items && _vm.arrayProducts.items.length > 0
  )?_c('div',{staticClass:"content-carousel"},[_c('div',{staticClass:"container-page"},[_c('div',{staticClass:"mb-4 d-flex flex-wrap align-items-center justify-content-center"},[_c('h4',{staticClass:"w-100 text-center"},[_vm._v(" "+_vm._s(_vm.arrayProducts.title)+" "+_vm._s(_vm.arrayProducts.slug ? _vm.arrayProducts.slug : '')+" ")]),(_vm.arrayProducts.query)?_c('div',{staticClass:"decorador-vitrina"},[_c('router-link',{staticClass:"link-products",attrs:{"to":{
            name: 'StoreCollection',
            params: { collection: _vm.arrayProducts.query },
            query: {},
          }}},[_vm._v("Ver productos")])],1):_vm._e()]),_c('carousel',{key:'update...' + _vm.arrayProducts.slug,staticClass:"content-carousel-products",style:(`background: linear-gradient(transparent 0%, transparent 50%, ${_vm.arrayProducts.background_color} 50%);`),attrs:{"id":_vm.idVitrina,"dots":false,"lazy-load":true,"nav":false,"responsive":{
        0: { items: 1, nav: false, stagePadding: 40 },
        768: { items: 3, nav: false, stagePadding: 0 },
        1500: { items: 4, nav: false, stagePadding: 30 },
        1900: { items: 5, nav: false, stagePadding: 15 },
      }}},[_c('template',{slot:"prev"},[_c('span',{staticClass:"prev nav-button"},[_c('img',{attrs:{"src":require("@/assets/icons/left-carousel.svg"),"alt":"left arrow carousel"}})])]),_c('template',{slot:"next"},[_c('span',{staticClass:"next nav-button"},[_c('img',{attrs:{"src":require("@/assets/icons/right-carousel.svg"),"alt":"right arrow carousel"}})])]),_vm._l((_vm.arrayProducts.items),function(productCarousel,index){return _c('div',{key:index + 'carousel'},[_c('product-card',{staticClass:"w-100",attrs:{"index":index,"data-product":productCarousel,"vitrinaid":`vitrina-${_vm.arrayProducts.query}`,"vitrinaname":`vitrina ${_vm.arrayProducts.title} ${_vm.arrayProducts.slug}`}})],1)})],2)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }