<template>
  <div class="container-raiting mb-5">
    <h3 class="w-100 mb-5 text-center">Valoraciones</h3>
    <p class="col-lg-8 mx-auto mb-4 text-gray">
      Los clientes escriben voluntariamente las valoraciones, se pueden leer
      mientras el producto está disponible. No se realiza ninguna compensación
      económica a cambio y revisamos todos los mensajes.
    </p>
    <div class="col-lg-7 mb-4 mx-auto">
      <div class="container-rating-val">
        <div class="col-4 d-flex align-items-center justify-content-center">
          <p class="cal-rating">{{ globalRating }}</p>
          <div>
            <b-form-rating
              v-model="globalRating"
              readonly
              show-clear
              no-border
              inline
              variant="danger"
              icon-empty="heart"
              icon-half="heart-half"
              icon-full="heart-fill"
              color="#F6BDC8"
              precision="1"
            ></b-form-rating>
            <p class="count-rating">
              {{ productDetail.valoraciones.length }}
              {{
                productDetail.valoraciones.length > 1
                  ? 'Valoraciones'
                  : 'Valoración'
              }}
            </p>
          </div>
        </div>
        <div class="btn-add-rating">
          <button
            class="bnt-outline"
            @click="
              $router.push({
                name: 'AddComment',
                params: { url: productDetail.url.replace('product-', '') },
              })
            "
          >
            <span class="pe-2">¿Compraste este producto?</span>
            <b>Escribe tu opinión</b>
          </button>
        </div>
      </div>
    </div>
    <div v-if="productDetail.valoraciones.length > 0" class="w-100 mb-3">
      <hr />
      <div
        v-for="(comment, index) in productDetail.valoraciones"
        :key="index"
        class="container-comment-rating"
      >
        <div class="col-lg-3 val-content">
          <h3>{{ comment.nombre_usuario }}</h3>
          <div>
            <b-form-rating
              v-model="comment.puntaje"
              readonly
              show-clear
              no-border
              inline
              variant="danger"
              icon-empty="heart"
              icon-half="heart-half"
              icon-full="heart-fill"
              color="#F6BDC8"
              precision="1"
            ></b-form-rating>
            <p class="text-small text-gray">
              {{ comment.fecha_registro.split(' ')[0] }}
            </p>
          </div>
        </div>
        <div class="col-lg-9 ps-3 val-comment">
          <p class="mb-0">{{ comment.comentario }}</p>
        </div>
        <hr class="w-100" />
      </div>
    </div>
    <div v-else class="w-100 mt-4">
      <h4 class="text-center mb-5">
        Este producto aún no cuenta con valoraciones.
        <br />
        !Sé el primero en valorar este producto!
      </h4>
      <hr />
    </div>
    <!-- TODO: se debe validar cada cuantas valoraciones se hace la paginación -->
    <!-- <div v-if="productDetail.valoraciones.length > 10" class="store-clover mx-auto py-0">
            <b-pagination
                class="pagination-comment my-0"
                v-model="currentPage"
                :total-rows="productGrid*2"
                :per-page="productGrid"
                aria-controls="products-table"
                align="center"
                :hide-goto-end-buttons="true"
            ></b-pagination>
        </div> -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      currentPage: 1,
      productGrid: 20,
      globalRating: 4.5,
    }
  },
  computed: {
    ...mapState('products', ['productDetail', 'valoracionesProducto']),
  },
}
</script>
