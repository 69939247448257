<template>
  <div class="container-product-not-available">
    <p class="fw-bold">PRODUCTO NO DISPONIBLE</p>
    <p class="text-gray fs-14">
      Este artículo se ha agotado, pero no te preocupes, regístrate y te
      avisaremos de nuevos lanzamientos, ofertas exclusivas y descuentos
      especiales.
    </p>
    <p>Fecha de cumpleaños</p>
    <div class="d-flex flex-wrap mb-3">
      <div class="col-6 col-md-3 pe-2 pe-md-3 mb-3 input-simple-clover">
        <select v-model="dayNewslatter" type="number" class="w-100">
          <option :value="null">Día</option>
          <option
            v-for="index in 31"
            :key="index + 'day'"
            :value="index > 10 ? index : `0${index}`"
          >
            {{ 10 > index ? `0${index}` : index }}
          </option>
        </select>
      </div>
      <div class="col-6 col-md-3 ps-2 ps-md-0 pe-md-3 mb-3 input-simple-clover">
        <select v-model="monthNewslatter" type="number" class="w-100">
          <option :value="null">Mes</option>
          <option
            v-for="index in 12"
            :key="index + 'month'"
            :value="index > 10 ? index : `0${index}`"
          >
            {{ 10 > index ? `0${index}` : index }}
          </option>
        </select>
      </div>
      <div class="col-12 col-md-6">
        <input
          id="email"
          v-model="emailNewslatter"
          :style="invalidEmailNewslatter ? 'border-color: #FF5151' : ''"
          placeholder="Correo electrónico*"
          type="email"
          autocomplete="email"
          @blur="validateBlur('email')"
          @focus="invalidEmailNewslatter = false"
        />
      </div>
    </div>
    <div class="d-flex flex-wrap">
      <div
        class="container-input-checkbox col-12 col-md-7 mb-4 text-gray fs-14 d-flex flex-wrap align-items-center"
      >
        <div class="d-flex align-items-center mt-3 mb-4">
          <input
            id="input-checkbox-tel"
            v-model="showTel"
            class="input-checkbox"
            type="checkbox"
            checkmark="checkmark"
          />
          <span class="checkmark"></span>
          <label for="input-checkbox-tel">
            Me gustaría recibir SMS.
          </label>
        </div>
        <div
          class="input-simple-clover d-flex"
          :style="
            showTel
              ? 'opacity: 1'
              : 'opacity: 0; height: 0; overflow: hidden; margin-bottom: 0'
          "
          style="transition: all 0.5s linear"
        >
          <label
            class="label-input"
            :style="
              showTel ? 'top: -14px; left: 60px;' : 'opacity: 0; z-index: -1;'
            "
            >Celular</label
          >
          <div class="decorador-tel" style="background-color: #F5F5F5">+57</div>
          <input
            v-model="telNumber"
            type="number"
            :style="invalidTelNumber ? 'border-color: #FF5151' : ''"
            placeholder="Celular"
            name="telNewslatter"
            autocomplete="tel"
            @blur="validateBlur('tel')"
            @focus="invalidTelNumber = false"
          />
        </div>
      </div>
      <div class="col-12 col-md-5">
        <button
          id="btn-addToCart-desktop"
          class="btn-primary-clover w-100 mb-4"
          :disabled="
            invalidEmailNewslatter ||
              !emailNewslatter ||
              (!monthNewslatter && !dayNewslatter) ||
              (invalidTelNumber && showTel) ||
              (showTel && !telNumber)
          "
          @click="sendRequestNesLatter()"
        >
          Suscribirme
        </button>
      </div>
    </div>
    <div v-if="errorSolicitud" class="w-100 mb-4">
      <span class="text-error">{{ msgErrorSolicitud }}</span>
    </div>
    <p class="mb-0 text-gray fs-14">
      Al suscribirte, aceptas Términos y condiciones y política de tratamientos
      de datos.
    </p>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { GA4SignUp } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'
export default {
  data() {
    return {
      emailNewslatter: '',
      invalidEmailNewslatter: false,
      dayNewslatter: null,
      monthNewslatter: null,
      showTel: false,
      telNumber: false,
      invalidTelNumber: false,
    }
  },
  computed: {
    ...mapState('newslatter', ['errorSolicitud', 'msgErrorSolicitud']),
    validateEmail() {
      /* eslint-disable */
      let re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.emailNewslatter)
    },
    validateDate() {
      return this.dayNewslatter != null && this.monthNewslatter != null
    },
    validatePhone() {
      const re = /^3[0-9]{9}/
      return re.test(this.telNumber) && this.telNumber.length < 11
    },
  },
  methods: {
    ...mapActions('newslatter', ['sendSoliNewsLatter']),
    validateBlur(blur) {
      if (blur == 'email') {
        this.invalidEmailNewslatter = !this.validateEmail
      } else if (blur == 'tel') {
        this.invalidTelNumber = !this.validatePhone
      }
    },
    async sendRequestNesLatter() {
      if (
        this.validateEmail &&
        this.validateDate &&
        !(this.showTel && !this.validatePhone)
      ) {
        this.$store.state.loaderClover = true
        let data = {
          email: this.emailNewslatter,
          day: this.dayNewslatter,
          month: this.monthNewslatter,
          sendSMS: this.showTel ? 1 : 0,
          tel: this.telNumber,
        }
        await this.sendSoliNewsLatter(data).then(() => {
          this.$store.state.loaderClover = false
          if (!this.errorSolicitud) {
            setTimeout(() => {
              this.activeStepNewslatter = 1
              this.emailNewslatter = ''
              this.dayNewslatter = null
              this.monthNewslatter = null
              this.telNumber = ''
              this.showNumber = false
            }, 15000)
            GA4SignUp('newslatter')
            sendFBQEvent(this, 'Subscribe', {})
          }
        })
      }
    },
  },
}
</script>
<style lang="scss">
.container-product-not-available {
  width: 100%;
  margin-bottom: 1.5rem;
  background-color: #f0f5f6;
  padding: 1rem 2rem;
  text-align: center;
  .text-gray {
    color: #818181;
  }
}
</style>
