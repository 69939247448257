<template>
  <div>
    <div class="content-products-store">
      <div
        v-for="index in 27"
        :key="index"
        class="mb-md-5 mb-4 item-product"
        :style="`width: ${parseInt(100 / gridStore)}%;`"
      >
        <div>
          <b-skeleton-img aspect="8:9"></b-skeleton-img>
        </div>
        <div class="mt-3 px-3 d-flex flex-wrap">
          <div class="col-12">
            <b-skeleton class=""></b-skeleton>
          </div>
          <div class="col-4">
            <b-skeleton></b-skeleton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    gridStore: {
      type: Number,
      required: false,
      default: 0,
    },
  },
}
</script>
