<template>
  <div class="container-breakin-cupo">
    <div class="breking-cupo flex-wrap flex-lg-nowrap">
      <div class="d-flex align-items-center mr-3">
        <img src="@/assets/icons/icon-quac-large_blanco.svg" />
      </div>
      <p class="breakin-title-cupo pt-4 pt-md-0">
        <b>
          Lo quiero y con Quac ¡Lo tengo!
        </b>
      </p>
      <p class="description-breakin-cupo py-3 py-lg-0">
        ¿Estás buscando una manera fácil y segura de financiar tus compras?
        ¡Entonces tienes que conocer a QUAC!
      </p>
      <div class="container-button-cupo d-flex align-items-center">
        <a
          class="btn-cupo-brekin"
          href="https://preaprobado.quac.co/?store_business=baguer&slug_store=clover"
          target="_blank"
        >
          <b>¡Quiero estrenar!</b>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BrakingCupo',
}
</script>
