<template>
  <div v-if="dataHome" id="container__banner" class="container-banner">
    <img
      v-if="dataHome.tipo == 1"
      :src="dataHome.image_desktop"
      class="img-banner"
    />
    <img
      v-if="dataHome.tipo == 1"
      :src="dataHome.image_movil"
      class="img-banner-movil"
    />
    <video
      v-if="dataHome.tipo == 2"
      payload="auto"
      autoplay
      loop
      playsinline
      muted
      type="video/mp4"
      class="img-banner"
      :src="dataHome.image_desktop"
    ></video>
    <video
      v-if="dataHome.tipo == 2"
      payload="auto"
      autoplay
      loop
      playsinline
      muted
      type="video/mp4"
      class="img-banner-movil"
      :src="dataHome.image_movil"
    ></video>
    <div v-if="dataHome.boton" class="content-banner content-banner-bottom">
      <div class="mb-4 content-banner-title" v-html="dataHome.titulo"></div>
      <div class="mb-4" v-html="dataHome.descripcion"></div>
      <button
        :style="
          `background-color: ${dataHome.boton.color_fondo}; color: ${dataHome.boton.color_texto};`
        "
        class="btn-primary-clover"
        @click="clickBanner()"
        v-html="dataHome.boton.texto"
      ></button>
    </div>
  </div>
</template>
<script>
import { GA4ViewPromotion, GA4SelectPromotion } from '@/GA4/events'
export default {
  props: {
    dataHome: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  mounted() {
    GA4ViewPromotion({
      nameCreative: `Banner home ${this.dataHome.nombre_promocion_ga}`,
      slot: `banner-principal-home`,
      id: `banner-home-${this.dataHome.id}`,
      name: `${this.dataHome.nombre_promocion_ga}`,
    })
  },
  methods: {
    clickBanner() {
      GA4SelectPromotion({
        nameCreative: `Banner home ${this.dataHome.nombre_promocion_ga}`,
        slot: `banner-principal-home`,
        id: `banner-home-${this.dataHome.id}`,
        name: `${this.dataHome.nombre_promocion_ga}`,
      })
      this.$router.push({
        path: this.dataHome.boton.url ? this.dataHome.boton.url : '/novedades',
      })
    },
  },
}
</script>
<style lang="scss">
.container-banner {
  position: relative;
  .img-banner {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: auto;
    // border-bottom: 10px solid #b6d6e8;
    // aspect-ratio: 1920/553;
  }
  .img-banner-movil {
    display: none;
    // aspect-ratio: 428/460;
  }
  .content-banner {
    h1 {
      font-size: 50px;
    }
    &-left {
      position: absolute;
      top: 50%;
      left: 5%;
      width: 40%;
      transform: translateY(-50%);
    }
    &-center {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 40%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
    &-bottom {
      position: absolute;
      bottom: 30px;
      text-align: center;
      width: 40%;
      left: 50%;
      transform: translateX(-50%);
    }
    &-right {
      position: absolute;
      top: 50%;
      right: 5%;
      width: 40%;
      transform: translateY(-50%);
    }
    @media (max-width: 1200px) {
      width: 45%;
    }
    /* @media (max-width: 991px) {
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0, 0);
      width: 80%;
      margin-right: auto;
      margin-left: auto;
      margin-top: 2rem;
    } */
    @media (max-width: 991px) {
      &-title {
        img {
          max-width: 240px;
          height: auto;
        }
      }
    }
    @media (max-width: 768px) {
      width: 90%;
      h1 {
        font-size: 40px;
      }
    }
  }
  @media (max-width: 768px) {
    .img-banner {
      display: none;
    }
    .img-banner-movil {
      display: flex;
      width: 100%;
      min-width: 100%;
      // border-bottom: 10px solid #b6d6e8;
    }
  }
}
</style>
