<template>
  <div
    v-if="productDetail && imgsProduct && imgsProduct.imagenes"
    class="container-product-detail-desktop"
  >
    <div class="col-6 px-2 pb-2 position-relative">
      <div class="go-back-product" @click="$router.go(-1)">
        <span>
          <svg
            id="Shopicons_Light_ArrowBack"
            xmlns="http://www.w3.org/2000/svg"
            width="20.686"
            height="20.685"
            viewBox="0 0 20.686 20.685"
          >
            <path
              id="Trazado_4823"
              data-name="Trazado 4823"
              d="M0,0H20.685V20.685H0Z"
              fill="none"
            />
            <g id="Shopicon" transform="translate(6.286 3.142)">
              <g id="Grupo_5514" data-name="Grupo 5514">
                <path
                  id="Trazado_4824"
                  data-name="Trazado 4824"
                  d="M21.786,7.292l-7.2,7.2,7.2,7.2.609-.609-6.59-6.59L22.4,7.9Z"
                  transform="translate(-14.586 -7.292)"
                  fill="#000000"
                />
              </g>
            </g>
          </svg>
          Volver
        </span>
      </div>
      <img
        :src="imgsProduct.imagenes[0]"
        :alt="productDetail.titulo + ' imagen principal'"
        class="w-100"
      />
    </div>
    <div class="col-6 pb-2 container-info-product">
      <div
        v-if="!imgsProduct.tags && productDetail.exclusivo_online == 1"
        class="tags-product"
      >
        <p>Exclusivo online</p>
      </div>
      <div v-if="imgsProduct.tags" class="tags-product">
        <p
          v-for="(tag, index) in imgsProduct.tags"
          :key="index"
          :style="
            `color: ${tag.color_texto}; background-color: ${tag.color_fondo};`
          "
          class="tag-container"
        >
          {{ tag.tag }}
        </p>
        <p v-if="productDetail.exclusivo_online == 1">Exclusivo online</p>
      </div>
      <h1>
        {{
          productDetail.titulo
            .toLowerCase()
            .replace(/^\w/, d => d.toUpperCase())
        }}
      </h1>
      <product-not-available v-if="!imgsProduct.habilitado" />
      <div v-if="imgsProduct.habilitado">
        <div class="container-price-product-detail">
          <del v-if="productDetail.precio_antes > 0"
            >${{
              new Intl.NumberFormat('de-DE').format(productDetail.precio_antes)
            }}</del
          >
          <p>
            ${{ new Intl.NumberFormat('de-DE').format(productDetail.precio) }}
          </p>
        </div>
        <div class="container-actions-product-detail">
          <div v-b-modal.modalcupo class="container-cuotas-quac">
            <div class="container-logo p-2">
              <img src="@/assets/icons/icon-quac.svg" alt="logo quac" />
            </div>
            <div class="col container-info-cuotas">
              <p>
                Págalo con Quac en hasta
                <b
                  >{{ productDetail.cant_cuotas }} cuotas quincenales de ${{
                    new Intl.NumberFormat('de-DE').format(
                      productDetail.cuota_cupo,
                    )
                  }}.
                </b>
                <span> Más información</span>
              </p>
            </div>
          </div>
          <div
            v-if="showMsgFewUnits"
            style="font-size: 13px;color: red;text-align: center;position: absolute;width: 100%;"
          >
            {{ msgFewUnits }}
          </div>
          <div v-if="imgsProduct.tallas.length > 1" class="box-size py-3">
            <div class="pe-4">
              <p class="mb-0">Selecciona una talla:</p>
              <span
                class="guia-de-tallas"
                @click="
                  $store.state.products.showSizeProduct = !$store.state.products
                    .showSizeProduct
                "
                >Guía de tallas</span
              >
            </div>
            <div class="col-md d-flex flex-wrap">
              <div
                v-for="(size, index) in imgsProduct.tallas"
                :key="index"
                :class="
                  size.stock
                    ? sizeSelect == size.id
                      ? 'size-select'
                      : ''
                    : 'size-disabled'
                "
                class="container-size"
                @click="size.stock > 0 ? $emit('changeSize', size.id) : ''"
              >
                {{ size.name }}
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap my-4">
            <div
              v-if="productDetail.referencias.length > 1"
              class="container-select-color-product mb-4 mb-lg-0 col-12 col-lg-4 col-xl-3"
            >
              <div
                :style="
                  productDetail.referencias.length > 1
                    ? 'cursor: pointer;'
                    : 'cursor: default;'
                "
                class="select-color-product"
                @click="clickColor = !clickColor"
              >
                <div
                  class="d-flex align-items-center"
                  :style="
                    productDetail.referencias.length > 1
                      ? 'width: calc(100% - 28px)'
                      : 'width: 100%;'
                  "
                >
                  <div
                    class="color-select"
                    :style="
                      `background-color: ${imgsProduct.color_hx}; background-image: url(${imgsProduct.color_textura});`
                    "
                  />
                  <p
                    class="mb-0 pe-2 d-flex align-items-center two-wrap-text"
                    style="width: calc(100% - 32px); white-space: pre"
                  >
                    {{
                      imgsProduct.color_nombre
                        .toLowerCase()
                        .replace(/^\w/, d => d.toUpperCase())
                    }}
                  </p>
                </div>
                <svg
                  v-if="productDetail.referencias.length > 1"
                  xmlns="http://www.w3.org/2000/svg"
                  style="width: 16px; min-width: 16px; height: 9px"
                  width="16.026"
                  height="9.425"
                  viewBox="0 0 16.026 9.425"
                >
                  <g
                    id="Icon_ionic-ios-arrow-dropdown"
                    data-name="Icon ionic-ios-arrow-dropdown"
                    transform="translate(16.026 9.425) rotate(180)"
                  >
                    <path
                      id="Trazado_6696"
                      data-name="Trazado 6696"
                      d="M.4,9.023A1.362,1.362,0,0,1,.4,7.1L7.124.4A1.356,1.356,0,0,1,8.995.354l6.63,6.609a1.355,1.355,0,1,1-1.912,1.92l-5.7-5.611L2.315,9.03A1.356,1.356,0,0,1,.4,9.023Z"
                      transform="translate(0)"
                    />
                  </g>
                </svg>
              </div>
              <div
                v-if="clickColor && productDetail.referencias.length > 1"
                class="container-options-select-color"
              >
                <div
                  v-for="(color, index) in productDetail.referencias"
                  :key="index"
                  class="container-select-options"
                  @click="color.habilitado ? changeColorInput(color) : ''"
                >
                  <div
                    class="color-select-option"
                    :style="
                      `
                        background-color: ${color.color_hx};
                        background-image: url(${color.color_textura});
                        opacity: ${color.habilitado ? '1' : '.3'};
                      `
                    "
                  />
                  <p
                    class="mb-0 pe-2"
                    :style="`opacity: ${color.habilitado ? '1' : '.3'};`"
                  >
                    {{
                      color.color_nombre
                        .toLowerCase()
                        .replace(/^\w/, d => d.toUpperCase())
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="d-flex col-12 col-lg-8 col-xl-9"
              :class="
                productDetail.referencias.length > 1
                  ? ''
                  : 'col-lg-12 col-xl-12'
              "
            >
              <div
                class="col pe-3 px-lg-3"
                :class="productDetail.referencias.length > 1 ? '' : 'ps-lg-0'"
              >
                <button
                  id="btn-addToCart-desktop"
                  class="btn-primary-clover w-100"
                  :disabled="!productDetail.publicado"
                  :title="productDetail.publicado ? '' : 'Producto agotado'"
                  @click="sendSoliAddProduct()"
                >
                  Agregar ${{
                    new Intl.NumberFormat('de-DE').format(productDetail.precio)
                  }}
                </button>
              </div>
              <div style="width: 50px">
                <btn-add-fav
                  :color="colorSelect"
                  :product-sku="productDetail.producto_sku"
                />
              </div>
            </div>
            <div v-if="errorAddToCart" class="text-center w-100 my-4">
              <span class="text-error">{{ msgErrorAddToCart }}</span>
            </div>
          </div>
          <!-- <div class="d-flex mb-4 justify-content-end">
            <div class="container-btn-gift" @click="$bvModal.show('modal-regalo');">
                <svg id="Shopicon" xmlns="http://www.w3.org/2000/svg" width="18.451" height="18.451" viewBox="0 0 18.451 18.451">
                    <path id="Trazado_5028" data-name="Trazado 5028" d="M20.571,8.058H18.565a2.768,2.768,0,1,0-5.213,0h-.322a2.768,2.768,0,1,0-5.213,0H5.81A1.851,1.851,0,0,0,3.965,9.9V20.974A1.851,1.851,0,0,0,5.81,22.819H20.571a1.851,1.851,0,0,0,1.845-1.845V9.9A1.851,1.851,0,0,0,20.571,8.058ZM15.958,5.291a1.845,1.845,0,1,1-1.845,1.845A1.847,1.847,0,0,1,15.958,5.291Zm-5.535,0A1.845,1.845,0,1,1,8.578,7.136,1.847,1.847,0,0,1,10.423,5.291ZM4.888,9.9a.923.923,0,0,1,.923-.923H8.364a2.758,2.758,0,0,0,4.117,0h.057l-3.69,3.69.652.652,3.229-3.229v5.344H4.888V9.9Zm0,11.071V16.361h7.842V21.9H5.81A.923.923,0,0,1,4.888,20.974Zm16.606,0a.923.923,0,0,1-.923.923H13.652V16.361h7.842v-.923H13.652V10.094l3.229,3.229.652-.652-3.69-3.69H13.9a2.758,2.758,0,0,0,4.117,0h2.554a.923.923,0,0,1,.923.923Z" transform="translate(-3.965 -4.368)"/>
                </svg>
                <p class="ps-2 mb-0">Ideal para regalo</p>
            </div>
          </div> -->
        </div>
      </div>
      <div class="pestanas-options-product-detail">
        <div class="container-opt-pestanas">
          <p
            class="opt-pestanas"
            :class="pestanas_index == 1 ? 'selected' : ''"
            @click="pestanas_index = 1"
          >
            Descripción
          </p>
          <p
            class="opt-pestanas"
            :class="pestanas_index == 2 ? 'selected' : ''"
            @click="pestanas_index = 2"
          >
            Materiales y cuidado
          </p>
          <p
            class="opt-pestanas"
            :class="pestanas_index == 3 ? 'selected' : ''"
            @click="pestanas_index = 3"
          >
            Disponibilidad - Envío
          </p>
        </div>
        <div class="content-pestanas">
          <div v-if="pestanas_index == 1" class="container-info-product-detail">
            <p>
              {{ productDetail.descripcion }}
            </p>
            <p>
              SKU {{ productDetail.producto_sku }} <br />
              <!-- Medidas: {{imgsProduct.modelo}} <br> -->
              País origen:
              {{
                productDetail.origen
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              }}
              - Fabricante / Importador:
              {{
                productDetail.importador
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              }}
              <br />
              Composición: {{ productDetail.composicion }}
            </p>
          </div>
          <care-product v-if="pestanas_index == 2" />
          <Shipping v-if="pestanas_index == 3" />
        </div>
      </div>
    </div>
    <div id="btn-fixed-addtocart" class="container-fixed-button-addtocard ps-4">
      <h4 class="col mb-0">
        {{
          productDetail.titulo
            .toLowerCase()
            .replace(/^\w/, d => d.toUpperCase())
        }}
      </h4>
      <div class="d-flex justify-content-end col">
        <div
          class="container-select-color-product mb-lg-0 my-auto col-md-4 col-xl-4 me-4"
          style="border-radius: 0; border: none"
        >
          <div
            v-if="productDetail.referencias.length > 1"
            class="select-color-product"
            :style="
              productDetail.referencias.length > 1
                ? 'cursor: pointer;'
                : 'cursor: default;'
            "
            @click="clickColor = !clickColor"
          >
            <div
              class="d-flex align-items-center"
              :style="
                productDetail.referencias.length > 1
                  ? 'width: calc(100% - 28px)'
                  : 'width: 100%;'
              "
            >
              <div
                class="color-select"
                :style="
                  `background-color: ${imgsProduct.color_hx}; background-image: url(${imgsProduct.color_textura});`
                "
              />
              <p
                class="mb-0 pe-2 d-flex align-items-center two-wrap-text"
                style="width: calc(100% - 32px); white-space: pre"
              >
                {{
                  imgsProduct.color_nombre
                    .toLowerCase()
                    .replace(/^\w/, d => d.toUpperCase())
                }}
              </p>
            </div>
            <svg
              v-if="productDetail.referencias.length > 1"
              xmlns="http://www.w3.org/2000/svg"
              style="width: 16px; min-width: 16px; height: 9px"
              width="16.026"
              height="9.425"
              viewBox="0 0 16.026 9.425"
            >
              <g
                id="Icon_ionic-ios-arrow-dropdown"
                data-name="Icon ionic-ios-arrow-dropdown"
                transform="translate(16.026 9.425) rotate(180)"
              >
                <path
                  id="Trazado_6696"
                  data-name="Trazado 6696"
                  d="M.4,9.023A1.362,1.362,0,0,1,.4,7.1L7.124.4A1.356,1.356,0,0,1,8.995.354l6.63,6.609a1.355,1.355,0,1,1-1.912,1.92l-5.7-5.611L2.315,9.03A1.356,1.356,0,0,1,.4,9.023Z"
                  transform="translate(0)"
                />
              </g>
            </svg>
          </div>
          <div class="box-size">
            <div class="d-flex">
              <div
                v-for="(size, index) in imgsProduct.tallas"
                :key="index"
                :class="
                  size.stock
                    ? sizeSelect == size.id
                      ? 'size-select'
                      : ''
                    : 'size-disabled'
                "
                class="container-size"
                @click="size.stock > 0 ? $emit('changeSize', size.id) : ''"
              >
                {{ size.name }}
              </div>
            </div>
          </div>
          <div
            v-if="clickColor && productDetail.referencias.length > 1"
            class="container-options-select-color container-options-select-color-top"
          >
            <div
              v-for="(color, index) in productDetail.referencias"
              :key="index"
              class="container-select-options"
              @click="color.habilitado ? changeColorInput(color) : ''"
            >
              <div
                class="color-select-option"
                :style="
                  `
                    background-color: ${color.color_hx};
                    background-image: url(${color.color_textura});
                    opacity: ${color.habilitado ? '1' : '.3'};
                  `
                "
              />
              <p
                class="mb-0 pe-2"
                :style="`opacity: ${color.habilitado ? '1' : '.3'};`"
              >
                {{
                  color.color_nombre
                    .toLowerCase()
                    .replace(/^\w/, d => d.toUpperCase())
                }}
              </p>
            </div>
          </div>
        </div>
        <button
          id="btn-addToCart-desktop ms-auto"
          class="btn-primary-clover my-auto ms-3 w-100"
          style="border-radius: 0; width: 300px !important; max-width: 300px"
          :disabled="productDetail.publicado"
          :title="productDetail.publicado ? '' : 'Producto agotado'"
          @click="sendSoliAddProduct()"
        >
          Agregar ${{
            new Intl.NumberFormat('de-DE').format(productDetail.precio)
          }}
        </button>
      </div>
    </div>
    <div v-if="imgsProduct.video" class="col-6 p-2 d-flex">
      <video
        class="w-100"
        autoplay
        muted
        loop
        height="100%"
        width="100%"
        style="background-color: #f0f5f6"
      >
        <source :src="imgsProduct.video" type="video/mp4" />
      </video>
    </div>
    <div
      v-for="index in imgsProduct.imagenes.length - 1"
      :key="index"
      class="col-6 p-2"
    >
      <img
        :src="imgsProduct.imagenes[index]"
        class="w-100"
        :alt="productDetail.titulo + ' index ' + index"
      />
    </div>
    <div v-if="imgProduct360 && imgProduct360.path_360" class="product-360">
      <vue-three-sixty-local
        :amount="imgProduct360.cantidad_360"
        :image-path="imgProduct360.path_360"
        :file-name="`{index}.jpg`"
        button-class="light"
        spin-reverse
        scroll-image
      />
    </div>
    <rating-product class="mt-5" />
    <div v-if="vitrinaRelacionados" class="position-relative col-12">
      <carousel-products
        :array-products="vitrinaRelacionados.productos"
        :id-vitrina="'vitrina-destacados-desktop'"
        :slug-vitrina="'productos-destacados'"
        :name-vitrina="'vitrina de productos destacados'"
      />
    </div>
    <size-product-detail />
  </div>
</template>
<script>
import BtnAddFav from '@/components/productDetail/productPage/BtnAddFav'
import CareProduct from '@/components/productDetail/productPage/CareProduct'
import CarouselProducts from '@/components/Templates/CarouselProducts'
import RatingProduct from '@/components/productDetail/RatingProduct.vue'
import Shipping from '@/components/productDetail/productPage/Shipping'
import VueThreeSixtyLocal from '@/components/productDetail/VueThreeSixty.vue'
import SizeProductDetail from '@/components/productDetail/productPage/SizeProductDetail.vue'
import ProductNotAvailable from '@/components/productDetail/productPage/ProductNotAvailable.vue'

import { mapState, mapActions } from 'vuex'
import { sendFBQEvent } from '@/fbq'
export default {
  components: {
    CareProduct,
    Shipping,
    VueThreeSixtyLocal,
    BtnAddFav,
    RatingProduct,
    CarouselProducts,
    SizeProductDetail,
    ProductNotAvailable,
  },
  props: {
    colorSelect: {
      type: String,
      required: false,
      default: '',
    },
    sizeSelect: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      clickColor: false,
      pestanas_index: 1,
      showMsgFewUnits: false,
      msgFewUnits: '',
    }
  },
  computed: {
    ...mapState('products', ['productDetail', 'vitrinaRelacionados']),
    ...mapState('cart', ['errorAddToCart', 'msgErrorAddToCart']),
    imgsProduct() {
      let imgsProductSelec = null
      if (this.productDetail && this.colorSelect) {
        imgsProductSelec = this.productDetail.referencias.find(
          e => e.color_id == this.colorSelect,
        )
      }
      return imgsProductSelec
    },
    imgProduct360() {
      if (
        this.productDetail &&
        this.productDetail.referencias.find(e => e.color_id == this.colorSelect)
      ) {
        return this.productDetail.referencias.find(
          e => e.color_id == this.colorSelect,
        )
      }
      return null
    },
  },
  watch: {
    colorSelect() {
      let colSelected = null
      colSelected = this.productDetail.referencias.find(
        e => e.color_id == this.colorSelect,
      )
      if (colSelected) {
        let sizeSelected = null
        sizeSelected = colSelected.tallas.find(e => e.id == this.sizeSelect)
        if (sizeSelected) {
          if (sizeSelected.alerta_stock == 1) {
            this.showMsgFewUnits = true
            if (sizeSelected.stock > 1) {
              this.msgFewUnits =
                '🔥 ¡Últimas ' + sizeSelected.stock + ' unidades disponibles!'
            } else {
              this.msgFewUnits = '🔥 ¡Última unidad disponible!'
            }
          } else {
            this.showMsgFewUnits = false
          }
        }
      }
    },
  },
  methods: {
    ...mapActions('cart', ['addProductTocart']),
    async sendSoliAddProduct() {
      if (this.colorSelect && this.sizeSelect) {
        let data = {
          sku: this.productDetail.producto_sku,
          color: this.colorSelect,
          tallaId: this.sizeSelect,
        }
        await this.addProductTocart(data).then(() => {
          if (!this.errorAddToCart) {
            this.$bvModal.show('modal-add-to-cart')
            sendFBQEvent(this, 'AddToCart', {
              product_id: this.productDetail.producto_sku,
              value: this.productDetail.precio,
              content_type: 'product',
              content_name:
                this.productDetail.categoria_slug.toUpperCase() + ' CLOVER',
              content_category: this.productDetail.categoria_slug.toUpperCase(),
              content_ids:
                this.productDetail.producto_sku +
                this.colorSelect +
                this.imgsProduct.tallas[0].id,
              currency: 'COP',
            })
          } else {
            window.scrollTo(0, 0)
          }
        })
      }
    },
    async dataLayerPush() {},
    changeColorInput(color) {
      this.$emit('changeColor', color.color_id)
      this.clickColor = !this.clickColor
    },
    getDataAnalytics(productos) {
      let arrayTemp = []
      productos.forEach(product => {
        arrayTemp.push({
          name: product.id + '-' + product.name,
          brand: product.brand,
          SKU: product.id,
          price: product.price,
          quantity: 1,
        })
      })
      return arrayTemp
    },
  },
}
</script>
<style lang="scss">
.container-actions-product-detail {
  position: relative;
}
.container-product-detail-desktop {
  display: flex;
  flex-wrap: wrap;
  .container-info-product {
    padding-right: 3rem;
    padding-left: 3rem;
    padding-top: 1rem;
    .tags-product {
      display: flex;
      p {
        margin-bottom: 0;
        color: #818181;
        margin-right: 0.7rem;
      }
      .tag-container {
        font-size: 13px;
        padding: 0.1rem 0.4rem;
        border-radius: 4px;
      }
    }
    h1 {
      font-size: 32px;
      margin-bottom: 20px;
    }
    .container-price-product-detail {
      display: flex;
      font-size: 24px;
      margin-bottom: 20px;
      del {
        color: #818181;
        margin-right: 1rem;
      }
      p {
        margin-bottom: 0;
      }
    }
    .pestanas-options-product-detail {
      .container-opt-pestanas {
        display: flex;
        margin-bottom: 1rem;
        margin-bottom: 2rem;
        .opt-pestanas {
          position: relative;
          margin-right: 2rem;
          margin-bottom: auto;
          text-align: center;
          cursor: pointer;
          &.selected::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            left: 0;
            bottom: -7px;
            background-color: black;
          }
          &.selected::after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
            border: 3px solid transparent;
            border-bottom: 3px solid black;
          }
        }
      }
    }
    @media (max-width: 991px) {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
}
</style>
