<template>
  <div>
    <product-detail-desktop
      v-if="innerWidthPage > 768"
      :color-select="colorSelect"
      :size-select="sizeSelect"
      @changeColor="colorSelect = $event"
      @changeSize="sizeSelect = $event"
    />
    <product-detail-movil
      v-else
      :color-select="colorSelect"
      :size-select="sizeSelect"
      @changeColor="colorSelect = $event"
      @changeSize="sizeSelect = $event"
    />
  </div>
</template>
<script>
import ProductDetailDesktop from '@/components/productDetail/productPage/ProductDetailDesktop'
import ProductDetailMovil from '@/components/productDetail/productPage/ProductDetailMovil'

import { mapActions, mapState } from 'vuex'
export default {
  components: { ProductDetailDesktop, ProductDetailMovil },
  data() {
    return {
      colorSelect: null,
      sizeSelect: null,
    }
  },
  computed: {
    ...mapState('products', ['productDetail']),
    ...mapState('user', ['departamentos']),
    ...mapState(['loaderClover', 'innerWidthPage']),
  },
  watch: {
    async productDetail() {
      if (this.productDetail) {
        let productAnalitycs = this.productDetail.productos_analytics[0]
        if (this.$route.params.event === 'CL-ProductoRelacionado') {
          // CL-ProductoRelacionado
          window.dataLayer.push({
            event: 'CL-ProductoRelacionado',
            productoPadre: this.$route.params.referenciaGA4.slice(9),
            productoPadreSKU: this.$route.params.referenciaGA4.split('-')[1],
            item: productAnalitycs,
          })
        } else {
          // CL-ProductClick
          window.dataLayer.push({
            event: this.$route.params.event,
            name: productAnalitycs.name,
            id: productAnalitycs.id,
            price: productAnalitycs.price,
            brand: productAnalitycs.brand,
            category: productAnalitycs.category,
            variant: productAnalitycs.variant,
            position: productAnalitycs.position,
          })
        }
        if (this.$route.query.color) {
          this.colorSelect = this.$route.query.color
        } else {
          this.colorSelect = this.productDetail.referencias.find(
            obj => obj.habilitado,
          )?.color_id
          if (!this.colorSelect) {
            this.colorSelect = this.productDetail.referencias[0].color_id
          }
        }
        let refSelect = this.productDetail.referencias.find(
          e => e.color_id == this.colorSelect,
        )
        if (!refSelect) {
          refSelect = this.productDetail.referencias.find(
            e => e.tallas[0].stock > 0,
          )
          this.colorSelect = refSelect
            ? refSelect.color_id
            : this.productDetail.referencias[0].color_id
        }
        refSelect.tallas.forEach(e => {
          if (e.stock > 0) {
            this.sizeSelect = e.id
            return
          }
        })
        await this.getDisponibilidad({
          sku: this.productDetail.producto_sku,
          color: this.colorSelect,
          talla: this.sizeSelect,
        })
        // await this.getValoraciones({sku: this.productDetail.producto_sku});
      }
    },
    async colorSelect() {
      if (this.colorSelect) {
        let query = Object.assign({}, this.$route.query)
        query.color = this.colorSelect
        window.scrollTo(0, 0)
        await this.$router.replace({ query: query }).catch(() => {})
      }
    },
    $route() {
      this.$store.state.cart.errorAddToCart = false
      this.$store.state.cart.msgErrorAddToCart = ''
      this.$store.state.products.showSizeProduct = false
    },
  },
  mounted() {
    this.$store.state.cart.errorAddToCart = false
    this.$store.state.cart.msgErrorAddToCart = ''
    if (this.departamentos.length == 0) {
      this.getCiudades()
    }
    // // CL-ImpresionProductoRelacionado
    // window.dataLayer.push({
    //   event: 'CL-ImpresionProductoRelacionado',
    //   productoPadre: this.productDetail.titulo,
    //   productoPadreSKU: this.productDetail.producto_sku,
    //   productos: this.getDataAnalytics(
    //     this.vitrinaRelacionados.productos_analytics,
    //   ),
    // })
  },
  methods: {
    ...mapActions('products', ['getDisponibilidad', 'getValoraciones']),
    ...mapActions('user', ['getCiudades']),
  },
}
</script>
<style lang="scss"></style>
