<template>
  <div>
    <div v-if="dataHome">
      <banner-home :data-home="dataHome.banner" />
      <categorys :data-categorys="dataHome.categorias" />
      <carousel-products
        :array-products="dataHome.vitrina1.vitrina"
        :id-vitrina="'vitrina-1-home'"
        :slug-vitrina="`vitrina-${dataHome.vitrina1.vitrina.query}`"
        :name-vitrina="
          `vitrina ${dataHome.vitrina1.vitrina.title} ${dataHome.vitrina1.vitrina.slug}`
        "
      />
      <breakin-cupo class="mb-5" />
      <carousel-products
        :array-products="dataHome.vitrina2.vitrina"
        :id-vitrina="'vitrina-2-home'"
        :slug-vitrina="`vitrina-${dataHome.vitrina2.vitrina.query}`"
        :name-vitrina="
          `vitrina ${dataHome.vitrina2.vitrina.title} ${dataHome.vitrina2.vitrina.slug}`
        "
      />
      <product-outstanding
        v-if="dataHome.producto_destacado"
        :data-product="dataHome.producto_destacado"
      />
      <bolsos-home :data="dataHome.tipos_productos" />
      <info-clover />
    </div>
    <div v-else>
      <skeleton-home />
    </div>
  </div>
</template>
<script>
import BannerHome from '@/components/home/BannerHome'
import Categorys from '@/components/home/Categorys'
import CarouselProducts from '@/components/Templates/CarouselProducts'
import BreakinCupo from '@/components/Templates/BreakinCupo.vue'
import ProductOutstanding from '@/components/home/ProductOutstanding'
import BolsosHome from '@/components/home/BolsosHome'
import SkeletonHome from '@/components/home/SkeletonHome'
import InfoClover from '@/components/homeV1/InfoClover'

import { mapActions, mapState } from 'vuex'
export default {
  components: {
    BannerHome,
    Categorys,
    CarouselProducts,
    BreakinCupo,
    ProductOutstanding,
    BolsosHome,
    InfoClover,
    SkeletonHome,
  },
  computed: {
    ...mapState('layouts', ['dataHome']),
  },
  async mounted() {
    await this.getHome()
    this.getTopbar()
  },
  methods: {
    ...mapActions('layouts', ['getHome', 'getTopbar']),
  },
}
</script>
