<template>
  <div class="skeleton-home">
    <div class="d-none d-md-block mb-4">
      <b-skeleton-img aspect="1920:543"></b-skeleton-img>
    </div>
    <div class="d-block d-md-none mb-4">
      <b-skeleton-img aspect="430:645"></b-skeleton-img>
    </div>
    <b-skeleton class="mt-5 mb-4 title-category"></b-skeleton>
    <div class="content-skeleton-category mb-5">
      <b-skeleton class="category-skeleton category-left"></b-skeleton>
      <div style="aspect-ratio: 28/31" class="category-skeleton">
        <b-skeleton class="mb-auto category-middle"></b-skeleton>
        <b-skeleton class="mt-auto category-middle"></b-skeleton>
      </div>
      <b-skeleton class="category-skeleton category-right"></b-skeleton>
    </div>
    <skeleton-carousel />
    <skeleton-rompe-nav />
    <skeleton-carousel />
    <div class="skeleton-product-outstanding">
      <div class="col-12 col-lg-6 mb-4 mb-lg-0">
        <b-skeleton-img aspect="961:1088"></b-skeleton-img>
      </div>
      <div class="col-12 col-lg-6 ps-lg-5">
        <b-skeleton class="title-product-outstanding"></b-skeleton>
        <b-skeleton class="mb-4 w-75 title-product-outstanding"></b-skeleton>
        <b-skeleton class="mb-4 price-product-outstanding"></b-skeleton>
        <b-skeleton class=""></b-skeleton>
        <b-skeleton class=""></b-skeleton>
        <b-skeleton class="mb-4"></b-skeleton>
        <b-skeleton width="200px" height="50px"></b-skeleton>
      </div>
    </div>
    <div class="container-carousel-collections-skeleton">
      <b-skeleton class="mb-5 title-category"></b-skeleton>
      <carousel
        class="content-carousel-collections-skeleton"
        :dots="false"
        :nav="false"
        :responsive="{
          0: { items: 1, nav: false, stagePadding: 0 },
          500: { items: 2, nav: false, stagePadding: 0 },
          768: { items: 2, nav: false, stagePadding: 0 },
          991: { items: 4, nav: false, stagePadding: 0 },
          1500: { items: 5, nav: false, stagePadding: 0 },
        }"
      >
        <template slot="prev"
          ><span class="prev nav-button"
            ><img
              src="@/assets/icons/left-carousel.svg"
              alt="left arrow carousel"/></span
        ></template>
        <template slot="next"
          ><span class="next nav-button"
            ><img
              src="@/assets/icons/right-carousel.svg"
              alt="right arrow carousel"/></span
        ></template>
        <div
          v-for="index in 12"
          :key="index"
          class="item-collections-carousel-skeleton"
        >
          <b-skeleton-img aspect="260:330"></b-skeleton-img>
        </div>
      </carousel>
    </div>
    <div class="content-category-bolsos">
      <b-skeleton class="mb-4 title-category"></b-skeleton>
      <div class="category-bolsos-carousels">
        <carousel :dots="false" :nav="false" :auto-width="true" class="mb-5">
          <div
            v-for="index in 12"
            :key="index"
            class="item-collections-carousel-skeleton"
          >
            <b-skeleton class="mx-3" width="150px" height="50px"></b-skeleton>
          </div>
        </carousel>
        <carousel
          :dots="false"
          :nav="false"
          :responsive="{
            0: { items: 1, nav: false, stagePadding: 0 },
            500: { items: 2, nav: false, stagePadding: 0 },
            768: { items: 2, nav: false, stagePadding: 0 },
            991: { items: 4, nav: false, stagePadding: 0 },
            1500: { items: 5, nav: false, stagePadding: 0 },
          }"
          class="content-carousel-bolsos-skeleton"
        >
          <template slot="prev"
            ><span class="prev nav-button"
              ><img
                src="@/assets/icons/left-carousel.svg"
                alt="left arrow carousel"/></span
          ></template>
          <template slot="next"
            ><span class="next nav-button"
              ><img
                src="@/assets/icons/right-carousel.svg"
                alt="right arrow carousel"/></span
          ></template>
          <skeleton-card-product v-for="index in 12" :key="index" />
        </carousel>
      </div>
    </div>
  </div>
</template>
<script>
import SkeletonCarousel from '@/components/Templates/SkeletonCarousel'
import SkeletonRompeNav from '@/components/Templates/SkeletonRompeNav'
import SkeletonCardProduct from '@/components/Templates/SkeletonCardProduct'

import carousel from 'v-owl-carousel'
export default {
  components: {
    SkeletonCarousel,
    SkeletonRompeNav,
    carousel,
    SkeletonCardProduct,
  },
}
</script>
<style lang="scss">
.skeleton-home {
  margin-top: 120px;
  padding-bottom: 2rem;
  .title-category {
    width: 250px;
    height: 30px;
    margin-right: auto;
    margin-left: auto;
  }
  .content-skeleton-category {
    max-width: 85%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
  }
  .category-skeleton {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
    width: 30%;
    aspect-ratio: 28/31;
    display: flex;
    flex-wrap: wrap;
  }
  .category-left,
  .category-right {
    height: 100%;
  }
  .category-middle {
    width: 100%;
    height: calc(50% - 0.6rem);
  }
  .skeleton-product-outstanding {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 3rem;
    background: linear-gradient(0deg, #fff 20%, #f2f2f2 0, #f2f2f2 80%, #fff 0);
    .title-product-outstanding {
      height: 40px;
    }
    .price-product-outstanding {
      width: 100px;
      height: 30px;
    }
  }
  .container-carousel-collections-skeleton {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 3rem;
    .content-carousel-collections-skeleton {
      position: relative;
      .item-collections-carousel-skeleton {
        padding: 1rem;
        .b-aspect {
          border-radius: 140px;
          overflow: hidden;
        }
      }
    }
  }
  .content-category-bolsos {
    background-color: #f5f5f5;
    max-width: 1920px;
    background-image: url('/assets/bg-bolsos-home.svg');
    background-repeat: no-repeat;
    background-position: bottom left;
    position: relative;
    padding-top: 2rem;
    padding-bottom: 1rem;
    .category-bolsos-carousels {
      width: 80%;
      margin-right: auto;
      margin-left: auto;
      .content-carousel-bolsos-skeleton {
        position: relative;
      }
    }
  }
  .prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -2rem;
    cursor: pointer;
    img {
      filter: opacity(0.4);
    }
  }
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -2rem;
    cursor: pointer;
    img {
      filter: opacity(0.4);
    }
  }
  @media (max-width: 991px) {
    .category-skeleton {
      width: 47%;
    }
    .skeleton-product-outstanding {
      background: #e6e6e8;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
  @media (max-width: 768px) {
    .category-skeleton {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
    .content-skeleton-category {
      width: 90%;
    }
  }
}
</style>
