<template>
  <div class="wash-and-care">
    <p class="text-gray mb-4">
      {{
        productDetail.lavado.toLowerCase().replace(/^\w/, d => d.toUpperCase())
      }}
    </p>
    <div class="w-100" v-html="productDetail.lavado_cuidado"></div>
    <!-- <div class="container-item-care">
            <img src="@/assets/icons/care-1.svg" alt="">
            <p>Cueros con grano tipo floatter, combinados en detalles con cueros lisos y texturados.</p>
        </div>
        <div class="container-item-care">
            <img src="@/assets/icons/care-2.svg" alt="">
            <p>Productos amigables con las mascotas, procesos sin in real life and definitely in typography.</p>
        </div>
        <div class="container-item-care">
            <img src="@/assets/icons/care-3.svg" alt="">
            <p>Procesos cuidadosamente detallados y manuales e and definitely in typography.</p>
        </div> -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('products', ['productDetail']),
  },
}
</script>
<style lang="scss">
.wash-and-care {
  .container-item-care {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    p {
      margin-bottom: 0;
      padding-left: 1rem;
    }
  }
}
</style>
