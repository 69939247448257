<template>
  <div class="conatiner-card">
    <div class="tag-product-spetial" />
    <img :src="getRefProductByColor.imagenes" :alt="dataProduct.titulo" />
    <div class="container-data">
      <p>
        {{
          dataProduct.titulo.toLowerCase().replace(/^\w/, d => d.toUpperCase())
        }}
      </p>
      <div class="d-flex flex-wrap">
        <div class="d-flex mb-2">
          <p class="fw-bold">
            ${{ Intl.NumberFormat('de-DE').format(dataProduct.precio) }}
          </p>
          <p v-if="parseInt(dataProduct.precio_antes) > 0" class="px-2">-</p>
          <del
            v-if="parseInt(dataProduct.precio_antes) > 0"
            class="fw-bold"
            style="color: #818181"
            >${{
              Intl.NumberFormat('de-DE').format(dataProduct.precio_antes)
            }}</del
          >
        </div>
        <div class="d-flex w-100">
          <button
            class="btn-primary-clover me-3 w-100"
            @click="sendSoliAddProduct()"
          >
            Agregar ${{ Intl.NumberFormat('de-DE').format(dataProduct.precio) }}
          </button>
          <div style="width: 50px">
            <btn-add-fav
              :color="getRefProductByColor.color_id"
              :product-sku="dataProduct.producto_sku"
            />
          </div>
        </div>
        <div v-if="errorAddToCart" class="my-2">
          <span class="text-error">{{ msgErrorAddToCart }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BtnAddFav from '@/components/productDetail/productPage/BtnAddFav'
import { mapActions, mapState } from 'vuex'
import { GA4ViewPromotion } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'
export default {
  components: { BtnAddFav },
  props: {
    dataProduct: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      selectColor: 0,
    }
  },
  computed: {
    ...mapState('cart', ['errorAddToCart', 'msgErrorAddToCart']),
    getRefProductByColor() {
      return this.selectColor
        ? this.dataProduct.referencias.find(
            e => (e.color_id = this.selectColor),
          )
        : this.dataProduct.referencias[0]
    },
  },
  mounted() {
    this.selectColor = this.dataProduct.referencias[0].color_id
    GA4ViewPromotion({
      nameCreative: `Card estrategia ${this.dataProduct.titulo}`,
      slot: `card-estrategia-product`,
      id: `card-estrategia`,
      name: `${this.dataProduct.titulo}`,
    })
  },
  methods: {
    ...mapActions('cart', ['addProductTocart']),
    async sendSoliAddProduct() {
      let data = {
        sku: this.dataProduct.producto_sku,
        color: this.getRefProductByColor.color_id,
        tallaId: this.getRefProductByColor.tallas[0].id,
      }
      await this.addProductTocart(data).then(() => {
        if (!this.errorAddToCart) {
          sendFBQEvent(this, 'AddToCart', {
            product_id: this.dataProduct.producto_sku,
            value: this.dataProduct.precio,
            content_type: 'product',
            content_name:
              this.dataProduct.categoria_slug.toUpperCase() + ' CLOVER',
            content_category: this.dataProduct.categoria_slug.toUpperCase(),
            content_ids:
              this.dataProduct.producto_sku +
              this.getRefProductByColor.color_id +
              this.getRefProductByColor.tallas[0].id,
            currency: 'COP',
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
.conatiner-card {
  display: flex;
  align-content: center;
  background-color: #fff2dc;
  padding: 1rem;
  position: relative;
  .tag-product-spetial {
    display: flex;
    background-color: #fff8f2;
    border-radius: 5px;
    min-width: 30px;
    margin-right: 1rem;
    &::after {
      position: absolute;
      bottom: 50%;
      left: -1.6rem;
      content: 'Oferta especial';
      transform: translateY(50%) rotate(-90deg);
    }
  }
  img {
    max-width: 150px;
    width: 100%;
  }
  .container-data {
    padding-left: 1rem;
  }
  @media (max-width: 500px) {
    flex-wrap: wrap;
    img {
      margin-bottom: 1rem;
      margin-right: auto;
      margin-left: auto;
    }
    .container-data {
      padding-left: 0;
    }
    .tag-product-spetial {
      position: relative;
      width: 100%;
      height: 30px;
      margin-bottom: 1rem;
      margin-right: 0;
      &::after {
        left: 50%;
        top: 25%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
