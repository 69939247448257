<template>
  <div
    class="item-product d-flex flex-wrap"
    :style="`width: ${parseInt(100 / gridStore)}%;`"
  >
    <router-link
      :to="{
        name: 'ProductDetail',
        params: { url: dataProduct.url.replace('product-', '') },
        query: { color: dataProduct.referencia.color_id },
      }"
      class="w-100"
    >
      <div class="container-imgs-product">
        <img
          class="img-product"
          :src="dataProduct.referencia.imagenes[0]"
          :alt="dataProduct.producto_titulo + ' imagen 1'"
        />
        <img
          class="img-product-hover"
          :src="
            dataProduct.referencia.imagenes.length > 1
              ? dataProduct.referencia.imagenes[1]
              : dataProduct.referencia.imagenes[0]
          "
          :alt="dataProduct.producto_titulo + ' imagen 1'"
        />
      </div>
    </router-link>
    <div class="tags-product">
      <span
        v-for="(tag, index2) in dataProduct.tags"
        :key="index2 + dataProduct.producto_titulo"
        class="tag-product"
        :style="
          `background-color: ${tag.color_fondo}; color: ${tag.color_texto}`
        "
        >{{ tag.tag }}</span
      >
    </div>
    <div class="btn-fav">
      <button-fav
        :color="dataProduct.referencia.color_id"
        :product-sku="dataProduct.producto_sku"
      />
    </div>
    <div class="p-3 w-100">
      <router-link
        :to="{
          name: 'ProductDetail',
          params: { url: dataProduct.url.replace('product-', '') },
          query: { color: dataProduct.referencia.color_id },
        }"
      >
        <p class="mb-3 text-start two-wrap-text">
          {{ dataProduct.producto_titulo }}
        </p>
      </router-link>
      <div class="d-flex flex-wrap">
        <p class="mb-0 fw-bold pe-2">
          ${{ new Intl.NumberFormat('de-DE').format(dataProduct.precio) }}
          {{ parseInt(dataProduct.precio_antes) > 0 ? '-' : '' }}
        </p>
        <del v-if="parseInt(dataProduct.precio_antes) > 0"
          >${{
            new Intl.NumberFormat('de-DE').format(dataProduct.precio_antes)
          }}</del
        >
      </div>
    </div>
  </div>
</template>
<script>
import ButtonFav from '@/components/Templates/ButtonFav.vue'
export default {
  components: { ButtonFav },
  props: {
    dataProduct: {
      type: Object,
      required: false,
      default: () => {},
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
    gridStore: {
      type: Number,
      required: false,
      default: 0,
    },
  },
}
</script>
