<template>
  <b-modal
    id="modal-regalo"
    v-model="modalRegalo"
    centered
    hide-header
    hide-footer
  >
    <svg
      id="Shopicons_Light_Close"
      xmlns="http://www.w3.org/2000/svg"
      width="16.325"
      height="16.325"
      viewBox="0 0 16.325 16.325"
      @click="$bvModal.hide('modal-regalo')"
    >
      <path
        id="Trazado_4945"
        data-name="Trazado 4945"
        d="M0,0H16.325V16.325H0Z"
        fill="none"
      />
      <g id="Shopicon" transform="translate(2.24 2.24)">
        <path
          id="Trazado_4946"
          data-name="Trazado 4946"
          d="M7.067,18.431l5.442-5.442,5.442,5.442.481-.481-5.442-5.442,5.442-5.442-.481-.481-5.442,5.442L7.067,6.586l-.481.481,5.442,5.442L6.586,17.95Z"
          transform="translate(-6.586 -6.586)"
        />
      </g>
    </svg>
    <div v-if="step == 1" class="step-1">
      <h3>Elige tu opción de regalo</h3>
      <p class="text-gray">
        Queremos acompañarte en sorprender a esa persona especial. Si eliges una
        opción de regalo no enviaremos factura física. Algunos artículos pueden
        no ser elegibles para algunas opciones de regalo.
        <br />
        <br />
        Selecciona una opción:
      </p>
      <div class="container-option" @click="step = 2">
        <svg
          id="Shopicons_Light_Letter"
          xmlns="http://www.w3.org/2000/svg"
          width="20.579"
          height="20.579"
          viewBox="0 0 20.579 20.579"
        >
          <path
            id="Trazado_5065"
            data-name="Trazado 5065"
            d="M0,0H20.579V20.579H0Z"
            fill="none"
          />
          <g id="Shopicon" transform="translate(1.715 3.43)">
            <path
              id="Trazado_5066"
              data-name="Trazado 5066"
              d="M19.434,8H5.715A1.72,1.72,0,0,0,4,9.715V20A1.72,1.72,0,0,0,5.715,21.72h13.72A1.72,1.72,0,0,0,21.149,20V9.715A1.72,1.72,0,0,0,19.434,8Zm-13.72.857h13.72a.856.856,0,0,1,.718.391l-7.577,7.577L5,9.248A.856.856,0,0,1,5.715,8.857Zm13.72,12H5.715A.858.858,0,0,1,4.857,20V10.321l7.717,7.717,7.717-7.717V20A.858.858,0,0,1,19.434,20.862Z"
              transform="translate(-4 -8)"
            />
          </g>
        </svg>
        Enviar correo electrónico <b>(gratis)</b>
      </div>

      <div class="container-option" @click="step = 2">
        <svg
          id="Shopicons_Light_Print"
          xmlns="http://www.w3.org/2000/svg"
          width="20.579"
          height="20.579"
          viewBox="0 0 20.579 20.579"
        >
          <path
            id="Trazado_5161"
            data-name="Trazado 5161"
            d="M0,0H20.579V20.579H0Z"
            fill="none"
          />
          <g id="Shopicon" transform="translate(1.715 1.715)">
            <path
              id="Trazado_5162"
              data-name="Trazado 5162"
              d="M19.434,9.145H17.72V4H7.43V9.145H5.715A1.72,1.72,0,0,0,4,10.86V16A1.72,1.72,0,0,0,5.715,17.72H7.43v3.43H17.72V17.72h1.715A1.72,1.72,0,0,0,21.149,16V10.86A1.72,1.72,0,0,0,19.434,9.145ZM8.287,4.857h8.575V9.145H8.287Zm0,15.434v-6.86h8.575v6.86Zm12-4.287a.858.858,0,0,1-.857.857H17.72V12.575H7.43v4.287H5.715A.858.858,0,0,1,4.857,16V10.86A.858.858,0,0,1,5.715,10h13.72a.858.858,0,0,1,.857.857Z"
              transform="translate(-4 -4)"
            />
          </g>
        </svg>
        Imprimir tarjeta de felicitación <b>(gratis)</b>
      </div>

      <div class="container-option" @click="step = 2">
        <svg
          id="Shopicons_Light_Box"
          xmlns="http://www.w3.org/2000/svg"
          width="20.579"
          height="20.579"
          viewBox="0 0 20.579 20.579"
        >
          <path
            id="Trazado_4870"
            data-name="Trazado 4870"
            d="M0,0H20.579V20.579H0Z"
            fill="none"
          />
          <g id="Shopicon" transform="translate(2.572 3.43)">
            <path
              id="Trazado_4871"
              data-name="Trazado 4871"
              d="M7.072,20.862H20.363V11.43h1.072V8H6v3.43H7.072Zm-.214-12h13.72v1.715H6.857ZM19.505,20H7.929V11.43H19.505Z"
              transform="translate(-6 -8)"
            />
            <rect
              id="Rectángulo_2473"
              data-name="Rectángulo 2473"
              width="4"
              height="1"
              transform="translate(5.783 6.087)"
            />
          </g>
        </svg>
        Empaque exclusivo <b>($45.000)</b>
      </div>

      <div class="container-option" @click="step = 2">
        <svg
          id="Shopicons_Light_Gift"
          xmlns="http://www.w3.org/2000/svg"
          width="20.579"
          height="20.579"
          viewBox="0 0 20.579 20.579"
        >
          <path
            id="Trazado_5027"
            data-name="Trazado 5027"
            d="M0,0H20.579V20.579H0Z"
            fill="none"
          />
          <g id="Shopicon" transform="translate(1.7 1.873)">
            <path
              id="Trazado_5028"
              data-name="Trazado 5028"
              d="M19.4,7.8H17.535a2.572,2.572,0,1,0-4.846,0h-.3a2.572,2.572,0,1,0-4.846,0H5.68A1.72,1.72,0,0,0,3.965,9.513V19.8A1.72,1.72,0,0,0,5.68,21.517H19.4A1.72,1.72,0,0,0,21.114,19.8V9.513A1.72,1.72,0,0,0,19.4,7.8ZM15.112,5.225A1.715,1.715,0,1,1,13.4,6.94,1.717,1.717,0,0,1,15.112,5.225Zm-5.145,0A1.715,1.715,0,1,1,8.252,6.94,1.717,1.717,0,0,1,9.967,5.225ZM4.822,9.513a.858.858,0,0,1,.857-.857H8.054a2.564,2.564,0,0,0,3.827,0h.053L8.5,12.085l.606.606,3-3v4.967H4.822V9.513Zm0,10.29V15.515h7.288V20.66H5.68A.858.858,0,0,1,4.822,19.8Zm15.434,0a.858.858,0,0,1-.857.857H12.968V15.515h7.288v-.857H12.968V9.69l3,3,.606-.606-3.43-3.43H13.2a2.564,2.564,0,0,0,3.827,0H19.4a.858.858,0,0,1,.857.857Z"
              transform="translate(-3.965 -4.368)"
            />
          </g>
        </svg>
        Empaque Premium Gift Box <b>($85.000)</b>
      </div>

      <div class="footer-step-1">
        <span class="pb-2 pb-md-0 text-center"
          >¿Aún no sabes qué regalar? Conoce nuestra</span
        >
        <button class="bnt-outline m-auto" style="min-height: auto">
          Guía de regalos Clover
        </button>
      </div>
    </div>
    <div v-if="step == 2" class="step-2">
      <div class="d-flex flex-wrap">
        <div
          class="d-flex mb-4 flex-wrap align-items-center"
          style="cursor: pointer"
          @click="step = 1"
        >
          <svg
            id="Shopicons_Light_ArrowLeft"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              id="Trazado_4829"
              data-name="Trazado 4829"
              d="M0,0H18V18H0Z"
              fill="none"
            />
            <g id="Shopicon" transform="translate(3.22 2.47)">
              <path
                id="Trazado_4830"
                data-name="Trazado 4830"
                d="M15.116,6.586l-6.53,6.53,6.53,6.53.53-.53-5.625-5.625h9.97v-.75h-9.97l5.625-5.625Z"
                transform="translate(-8.586 -6.586)"
              />
            </g>
          </svg>
          <span class="ps-2">Volver</span>
        </div>
        <h3 class="mb-4 ps-4 text-center">
          Enviar correo electrónico (gratis)
        </h3>
      </div>
      <p class="text-gray">
        Enviaremos un correo electrónico notificando tu regalo especial, el
        producto llegará en un bonito empaque y no enviaremos factura física.
      </p>
      <div class="col-12 col-lg-6 mb-4 pe-md-2">
        <div class="input-simple-clover">
          <label
            :class="validateName ? 'invalid' : ''"
            class="label-input"
            :style="nameGift ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Nombre de quien recibe</label
          >
          <input
            v-model="nameGift"
            :class="validateName ? 'invalid-error' : ''"
            placeholder="Nombre de quien recibe"
            type="text"
            autocomplete="address-line1"
            @blur="validateBlur('validateName')"
            @focus="validateName = false"
          />
          <span v-if="validateName" class="inavalida-input">{{
            nameGift ? 'Nombre invalido' : 'Campo requerido'
          }}</span>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-4 ps-md-2">
        <div class="input-simple-clover">
          <label
            :class="validateEmail ? 'invalid' : ''"
            class="label-input"
            :style="email ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Correo electrónico</label
          >
          <input
            v-model="email"
            :class="validateEmail ? 'invalid-error' : ''"
            placeholder="Correo electrónico"
            type="email"
            autocomplete="address-line1"
            @blur="validateBlur('validateEmail')"
            @focus="validateEmail = false"
          />
          <span v-if="validateEmail" class="inavalida-input">{{
            email ? 'Correo invalido' : 'Campo requerido'
          }}</span>
        </div>
      </div>
      <div class="col-12 mb-4">
        <div class="input-simple-clover">
          <textarea
            v-model="messageGift"
            style="min-height: 132px"
            placeholder="Mensaje especial (200 caracteres)"
            type="textarea"
            maxlength="200"
            autocomplete="address-line1"
            :class="validateMessage ? 'invalid-error' : ''"
            class="mb-0 pb-0"
            @blur="validateBlur('validateMessage')"
            @focus="validateMessage = false"
          />
          <span v-if="validateMessage" class="inavalida-input">{{
            messageGift ? 'Correo invalido' : 'Campo requerido'
          }}</span>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-4 pe-md-2">
        <div class="input-simple-clover">
          <label
            :class="validateName ? 'invalid' : ''"
            class="label-input"
            :style="nameGift ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Nombre de quien recibe</label
          >
          <input
            v-model="nameGift"
            :class="validateName ? 'invalid-error' : ''"
            placeholder="Nombre de quien recibe"
            type="text"
            autocomplete="address-line1"
            @blur="validateBlur('validateName')"
            @focus="validateName = false"
          />
          <span v-if="validateName" class="inavalida-input">{{
            nameGift ? 'Nombre invalido' : 'Campo requerido'
          }}</span>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-4 ps-md-2">
        <button class="btn-primary-clover w-100">Agregar nota de regalo</button>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  data() {
    return {
      step: 1,
      modalRegalo: false,
      nameGift: '',
      validateName: false,
      email: '',
      validateEmail: false,
      messageGift: '',
      validateMessage: false,
      name: '',
    }
  },
}
</script>
<style lang="scss">
.modal-dialog {
  max-width: 600px;
}
#Shopicons_Light_Close {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  cursor: pointer;
  &:hover {
    #Trazado_4946 {
      stroke: #000000;
    }
  }
}
.step-1 {
  .container-option {
    background-color: #f4f6f8;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0.4rem;
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #e9e9e9;
    }
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 1rem;
      padding: 0.7rem 0.5rem;
    }
  }
  .footer-step-1 {
    background-color: #f4f6f8;
    padding: 2rem 1.5rem;
    margin-top: 2rem;
    margin-right: -1rem;
    margin-left: -1rem;
    margin-bottom: -1rem;
    display: flex;
    flex-wrap: wrap;
  }
}
.step-2 {
  display: flex;
  flex-wrap: wrap;
}
</style>
