<template>
  <div class="container-skeleton d-none d-md-flex">
    <!-- <div class="d-flex align-items-center w-100 py-2">
            <b-skeleton class="mx-1 mb-0" width="100px" height="16px"></b-skeleton>
            <span style="color: #e6e6e6">></span>
            <b-skeleton class="mx-1 mb-0" width="100px" height="16px"></b-skeleton>
            <span style="color: #e6e6e6">></span>
            <b-skeleton class="mx-1 mb-0" width="100px" height="16px"></b-skeleton>
        </div> -->
    <div class="col-12 col-md-6 px-2">
      <b-skeleton-img aspect="961:1088"></b-skeleton-img>
    </div>
    <div class="col-12 col-md-6 px-md-4 px-lg-5 pt-3">
      <b-skeleton class="mb-2" width="70px" height="16px"></b-skeleton>
      <b-skeleton class="mb-3 w-100" height="40px"></b-skeleton>
      <b-skeleton class="mb-4" width="150px" height="30px"></b-skeleton>
      <b-skeleton class="mb-3 w-100" height="80px"></b-skeleton>
      <div class="d-flex mb-3">
        <b-skeleton class="mb-2 col-3" height="50px"></b-skeleton>
        <b-skeleton class="mb-2 mx-3 col" height="50px"></b-skeleton>
        <b-skeleton class="mb-2" width="50px" height="50px"></b-skeleton>
      </div>
      <b-skeleton class="mb-2 ms-auto col-4 mb-4" height="50px"></b-skeleton>
      <div class="d-flex mb-4">
        <b-skeleton class="mb-2 me-2 col-3 col-lg-2" height="15px"></b-skeleton>
        <b-skeleton class="mb-2 me-2 col-4 col-lg-3" height="15px"></b-skeleton>
        <b-skeleton class="mb-2 me-2 col-4 col-lg-3" height="15px"></b-skeleton>
      </div>
      <b-skeleton class="mb-2 w-100" height="15px"></b-skeleton>
      <b-skeleton class="mb-2 w-100" height="15px"></b-skeleton>
      <b-skeleton class="mb-4 w-50" height="15px"></b-skeleton>

      <b-skeleton class="mb-2" width="120px" height="16px"></b-skeleton>
      <b-skeleton class="mb-2" width="120px" height="16px"></b-skeleton>
      <b-skeleton class="mb-2 w-75" height="16px"></b-skeleton>
      <b-skeleton class="mb-2 w-75" height="16px"></b-skeleton>
    </div>
    <div class="col-12 col-md-6 mt-3 px-2">
      <b-skeleton-img aspect="961:1088"></b-skeleton-img>
    </div>
    <div class="col-12 col-md-6 mt-3 px-2">
      <b-skeleton-img aspect="961:1088"></b-skeleton-img>
    </div>
    <div class="col-12 col-md-6 mt-3 px-2">
      <b-skeleton-img aspect="961:1088"></b-skeleton-img>
    </div>
    <div class="col-12 col-md-6 mt-3 px-2">
      <b-skeleton-img aspect="961:1088"></b-skeleton-img>
    </div>
    <div class="px-2 col-10 mx-auto mt-4 mb-5">
      <b-skeleton class="mb-4 mx-auto" width="300px" height="30px"></b-skeleton>
      <b-skeleton class="mb-2 mx-auto" width="600px" height="16px"></b-skeleton>
      <b-skeleton class="mb-2 mx-auto" width="600px" height="16px"></b-skeleton>
      <b-skeleton class="mb-5 mx-auto" width="600px" height="16px"></b-skeleton>
      <b-skeleton
        class="mb-5 mx-auto"
        width="550px"
        height="120px"
      ></b-skeleton>
      <div class="w-100" style="border-top: 1px solid #e6e6e6">
        <div
          v-for="index in 4"
          :key="index"
          class="d-flex w-100 p-4"
          style="border-bottom: 1px solid #e6e6e6"
        >
          <div class="col-3">
            <b-skeleton class="" width="150px" height="30px"></b-skeleton>
            <b-form-rating
              v-model="val"
              readonly
              show-clear
              no-border
              inline
              variant="danger"
              icon-empty="heart"
              icon-half="heart-half"
              icon-full="heart-fill"
              class="px-0 py-2"
              color="#e6e6e6"
              precision="1"
            ></b-form-rating>
            <b-skeleton class="" width="100px" height="16px"></b-skeleton>
          </div>
          <div class="col-8 ms-auto">
            <b-skeleton class="w-100" height="16px"></b-skeleton>
            <b-skeleton class="w-100" height="16px"></b-skeleton>
            <b-skeleton class="w-100" height="16px"></b-skeleton>
            <b-skeleton class="w-75" height="16px"></b-skeleton>
          </div>
        </div>
      </div>
      <div class="d-flex w-100 mt-4 justify-content-center">
        <div class="d-flex mt-1">
          <div>
            <b-skeleton
              width="20px"
              height="30px"
              class="mx-1 mb-1"
            ></b-skeleton>
            <b-skeleton width="20px" height="2px" class="mx-1"></b-skeleton>
          </div>
          <b-skeleton width="20px" height="30px" class="mx-1"></b-skeleton>
        </div>
        <b-skeleton
          width="40px"
          height="40px"
          class="mx-2"
          style="border-radius: 50%"
        ></b-skeleton>
      </div>
    </div>
    <skeleton-carousel />
  </div>
</template>
<script>
import SkeletonCarousel from '@/components/Templates/SkeletonCarousel'
export default {
  components: { SkeletonCarousel },
  data() {
    return {
      val: 5,
    }
  },
}
</script>
<style lang="scss">
.container-skeleton {
  display: flex;
  flex-wrap: wrap;
}
</style>
