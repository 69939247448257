<template>
  <div class="container-filter">
    <div class="header-filter">
      <span v-b-toggle.sidebar-price class="title">Precios</span>
    </div>
    <b-sidebar
      id="sidebar-price"
      backdrop-variant="dark"
      backdrop
      no-header
      right
      shadow
    >
      <template v-slot:default="{ hide }">
        <div class="container-sidebar">
          <header
            class="b-sidebar-header d-flex w-100 justify-content-between py-3"
          >
            <h4 class="mb-0">Precio</h4>
            <div class="d-flex align-items-center" style="min-width: 136px">
              <span class="borrar-filtro pe-2" @click="tempRangeSelected = []"
                >Borrar filtro</span
              >
              <svg
                style="cursor: pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 48 48"
                @click="hide"
              >
                <path
                  id="Trazado_4945"
                  data-name="Trazado 4945"
                  d="M0,0H48V48H0Z"
                  fill="none"
                />
                <g id="Shopicon">
                  <path
                    id="Trazado_4946"
                    data-name="Trazado 4946"
                    d="M8,41.414l16-16,16,16L41.414,40l-16-16,16-16L40,6.586l-16,16-16-16L6.586,8l16,16-16,16Z"
                  />
                </g>
              </svg>
            </div>
          </header>
          <div class="body-filter">
            <div class="price-filter">
              <vue-slider
                v-model="rangeSelected"
                :enable-cross="false"
                :max="parseInt(filtros[0].max)"
                :min="parseInt(filtros[0].min)"
                :min-range="50000"
                :dot-size="20"
                :interval="interval"
                :process-style="{ backgroundColor: '#F6BDC8' }"
                :tooltip-style="{
                  backgroundColor: '#F6BDC8',
                  borderColor: '#F6BDC8',
                }"
              >
                <template v-slot:dot="{ focus }">
                  <div :class="['custom-dot', { focus }]"></div>
                </template>
              </vue-slider>
            </div>
            <p class="text-center">
              Desde
              <span
                ><b
                  >${{
                    new Intl.NumberFormat('de-DE').format(rangeSelected[0])
                  }}</b
                ></span
              >
              hasta
              <span
                ><b
                  >${{
                    new Intl.NumberFormat('de-DE').format(rangeSelected[1])
                  }}</b
                ></span
              >
            </p>
          </div>
          <div class="btn-filter">
            <button
              class="w-100 bnt-primary"
              @click="
                hide
                addFilter()
              "
            >
              Aplicar filtros
            </button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>
<script>
import VueSlider from 'vue-slider-component'
export default {
  components: { VueSlider },
  props: {
    filtros: {
      type: Array,
      required: false,
      default: () => [0, 10000],
    },
    updateSearch: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      rangeSelected: [0, 50001],
      tempRangeSelected: [],
      interval: 1,
      showOptions: false,
      clickInside: false,
      keyFilter: 0,
    }
  },
  watch: {
    updateSearch() {
      if (this.updateSearch > 0) {
        this.addFilter()
      }
    },
  },
  mounted() {
    if (this.$route.query.precios) {
      this.tempRangeSelected = [
        this.$route.query.precios.split(',')[0],
        this.$route.query.precios.split(',')[1],
      ]
      this.rangeSelected = [
        this.$route.query.precios.split(',')[0],
        this.$route.query.precios.split(',')[1],
      ]
    } else {
      this.tempRangeSelected = [this.filtros[0].min, this.filtros[0].max]
      this.rangeSelected = [this.filtros[0].min, this.filtros[0].max]
    }
  },
  methods: {
    addFilter() {
      let query = Object.assign({}, this.$route.query)
      if (this.tempRangeSelected.toString() != this.rangeSelected.toString()) {
        query.precios = this.rangeSelected.toString()
      }
      if (
        (this.tempRangeSelected.toString() == this.rangeSelected.toString() &&
          query.precios) ||
        this.rangeSelected.toString() ==
          [this.filtros[0].min, this.filtros[0].max]
      ) {
        delete query.precios
      }
      this.$router.push({ query: query })
    },
  },
}
</script>
