<template>
  <div class="filters-store">
    <div class="filters-movil">
      <p>{{ productsCategory.to }} de {{ productsCategory.total }} productos</p>
      <div class="d-flex align-items-center">
        <svg
          class="ms-3 d-none d-md-flex"
          style="cursor: pointer"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          @click="$emit('changeGrid', 2)"
        >
          <g
            id="Grupo_7025"
            data-name="Grupo 7025"
            transform="translate(-1140 -385)"
          >
            <rect
              id="Rectángulo_2935"
              data-name="Rectángulo 2935"
              width="10"
              height="10"
              transform="translate(1155 385)"
              :fill="gridStore == 2 ? '#818181' : '#e9e9e9'"
            />
            <rect
              id="Rectángulo_2936"
              data-name="Rectángulo 2936"
              width="10"
              height="10"
              transform="translate(1155 400)"
              :fill="gridStore == 2 ? '#818181' : '#e9e9e9'"
            />
            <rect
              id="Rectángulo_2933"
              data-name="Rectángulo 2933"
              width="10"
              height="10"
              transform="translate(1140 385)"
              :fill="gridStore == 2 ? '#818181' : '#e9e9e9'"
            />
            <rect
              id="Rectángulo_2934"
              data-name="Rectángulo 2934"
              width="10"
              height="10"
              transform="translate(1140 400)"
              :fill="gridStore == 2 ? '#818181' : '#e9e9e9'"
            />
          </g>
        </svg>
        <svg
          class="mx-3 d-none d-md-flex"
          style="cursor: pointer"
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="25"
          viewBox="0 0 40 25"
          @click="$emit('changeGrid', 3)"
        >
          <g
            id="Grupo_7013"
            data-name="Grupo 7013"
            transform="translate(-1125 -385)"
          >
            <rect
              id="Rectángulo_2935"
              data-name="Rectángulo 2935"
              width="10"
              height="10"
              transform="translate(1155 385)"
              :fill="gridStore == 3 ? '#818181' : '#e9e9e9'"
            />
            <rect
              id="Rectángulo_2936"
              data-name="Rectángulo 2936"
              width="10"
              height="10"
              transform="translate(1155 400)"
              :fill="gridStore == 3 ? '#818181' : '#e9e9e9'"
            />
            <rect
              id="Rectángulo_2933"
              data-name="Rectángulo 2933"
              width="10"
              height="10"
              transform="translate(1140 385)"
              :fill="gridStore == 3 ? '#818181' : '#e9e9e9'"
            />
            <rect
              id="Rectángulo_2941"
              data-name="Rectángulo 2941"
              width="10"
              height="10"
              transform="translate(1125 385)"
              :fill="gridStore == 3 ? '#818181' : '#e9e9e9'"
            />
            <rect
              id="Rectángulo_2934"
              data-name="Rectángulo 2934"
              width="10"
              height="10"
              transform="translate(1140 400)"
              :fill="gridStore == 3 ? '#818181' : '#e9e9e9'"
            />
            <rect
              id="Rectángulo_2940"
              data-name="Rectángulo 2940"
              width="10"
              height="10"
              transform="translate(1125 400)"
              :fill="gridStore == 3 ? '#818181' : '#e9e9e9'"
            />
          </g>
        </svg>
        <button v-b-toggle.sidebar-filters class="btn-filters-movil">
          <svg
            style="margin-right: 0.8rem"
            xmlns="http://www.w3.org/2000/svg"
            width="20.96"
            height="20.547"
            viewBox="0 0 20.96 20.547"
          >
            <g id="setting-lines" transform="translate(0 -4.503)">
              <path
                id="Trazado_8051"
                data-name="Trazado 8051"
                d="M4.479,53.82H.616a.616.616,0,1,1,0-1.233H4.479a.616.616,0,1,1,0,1.233Z"
                transform="translate(0 -45.877)"
              />
              <path
                id="Trazado_8052"
                data-name="Trazado 8052"
                d="M86.96,10.15a2.824,2.824,0,1,1,2.824-2.824A2.827,2.827,0,0,1,86.96,10.15Zm0-4.414A1.591,1.591,0,1,0,88.55,7.327,1.593,1.593,0,0,0,86.96,5.736Z"
                transform="translate(-80.273)"
              />
              <path
                id="Trazado_8053"
                data-name="Trazado 8053"
                d="M238.835,53.82h-9.214a.616.616,0,0,1,0-1.233h9.214a.616.616,0,0,1,0,1.233Z"
                transform="translate(-218.492 -45.877)"
              />
              <path
                id="Trazado_8054"
                data-name="Trazado 8054"
                d="M267.253,172.421a2.824,2.824,0,1,1,2.824-2.824A2.827,2.827,0,0,1,267.253,172.421Zm0-4.414a1.591,1.591,0,1,0,1.591,1.591A1.592,1.592,0,0,0,267.253,168.007Z"
                transform="translate(-252.289 -154.821)"
              />
              <path
                id="Trazado_8055"
                data-name="Trazado 8055"
                d="M10.52,216.083H.616a.616.616,0,1,1,0-1.233h9.9a.616.616,0,1,1,0,1.233Z"
                transform="translate(0 -200.69)"
              />
              <path
                id="Trazado_8056"
                data-name="Trazado 8056"
                d="M364.376,216.083H361.2a.616.616,0,0,1,0-1.233h3.173a.616.616,0,0,1,0,1.233Z"
                transform="translate(-344.033 -200.69)"
              />
              <path
                id="Trazado_8057"
                data-name="Trazado 8057"
                d="M71.937,334.684a2.824,2.824,0,1,1,2.824-2.824A2.827,2.827,0,0,1,71.937,334.684Zm0-4.414a1.591,1.591,0,1,0,1.591,1.591A1.592,1.592,0,0,0,71.937,330.27Z"
                transform="translate(-65.94 -309.635)"
              />
              <path
                id="Trazado_8058"
                data-name="Trazado 8058"
                d="M224.5,378.347h-9.9a.616.616,0,0,1,0-1.233h9.9a.616.616,0,0,1,0,1.233Z"
                transform="translate(-204.156 -355.505)"
              />
              <path
                id="Trazado_8059"
                data-name="Trazado 8059"
                d="M3.789,378.346H.616a.616.616,0,0,1,0-1.233H3.789a.616.616,0,0,1,0,1.233Z"
                transform="translate(0 -355.504)"
              />
            </g>
          </svg>
          <b>Filtrar y ordenar</b>
        </button>
      </div>
    </div>
    <b-sidebar
      id="sidebar-filters"
      v-model="openMenu"
      aria-labelledby="sidebar-no-header-title"
      backdrop-variant="dark"
      backdrop
      no-header
      right
      shadow
    >
      <template v-slot:default="{ hide }">
        <div class="container-sidebar">
          <header
            class="b-sidebar-header d-flex w-100 justify-content-between py-3"
          >
            <h4 class="mb-0">Filtros</h4>
            <div class="d-flex align-items-center" style="min-width: 136px">
              <span
                class="borrar-filtro pe-2"
                @click="$router.push({ query: {} })"
                >Borrar filtros</span
              >
              <svg
                style="cursor: pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 48 48"
                @click="hide"
              >
                <path
                  id="Trazado_4945"
                  data-name="Trazado 4945"
                  d="M0,0H48V48H0Z"
                  fill="none"
                />
                <g id="Shopicon">
                  <path
                    id="Trazado_4946"
                    data-name="Trazado 4946"
                    d="M8,41.414l16-16,16,16L41.414,40l-16-16,16-16L40,6.586l-16,16-16-16L6.586,8l16,16-16,16Z"
                  />
                </g>
              </svg>
            </div>
          </header>
          <div v-if="filters" class="body-filter">
            <filtro-simple
              :update-search="updateSearch"
              :filter-id="'ordenar'"
              :title="'Ordenar'"
              :filtros="ordenamientoClover"
            />
            <filtro-simple
              :update-search="updateSearch"
              :filter-id="'destacado'"
              :title="'Destacados'"
              :filtros="filters.destacados"
            />
            <color-filter
              :update-search="updateSearch"
              :filtros="filters.colores"
            />
            <filter-multi-select
              v-if="filters.colecciones.length > 0"
              :update-search="updateSearch"
              :filter-id="filters.colecciones[0].query_name"
              :title="filters.colecciones[0].title"
              :filtros="filters.colecciones[0].items"
            />
            <filter-one-level
              :update-search="updateSearch"
              :filter-id="'categorias'"
              :title="'Categorías'"
              :filtros="filters.subcategorias"
            />
            <price-filter
              :update-search="updateSearch"
              :filtros="filters.precios"
            />
            <filter-multi-select
              v-for="(filtro, index) in filters.filtros_simples"
              :key="index + 'aupdate-search'"
              :update-search="updateSearch"
              :filter-id="filtro.query_name"
              :title="filtro.title"
              :filtros="filtro.items"
            />
          </div>
          <div class="btn-filter">
            <button class="w-100 bnt-primary" @click="hide">
              Aplicar filtros
            </button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>
<script>
import ColorFilter from '@/components/store/filters/ColorFilter.vue'
import FiltroSimple from '@/components/store/filters/FiltroSimple.vue'
import FilterMultiSelect from '@/components/store/filters/FilterMultiSelect.vue'
import FilterOneLevel from '@/components/store/filters/FilterOneLevel.vue'
import PriceFilter from '@/components/store/filters/PriceFilter.vue'

import { mapState } from 'vuex'
export default {
  components: {
    ColorFilter,
    FiltroSimple,
    PriceFilter,
    FilterMultiSelect,
    FilterOneLevel,
  },
  props: {
    gridStore: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      openMenu: false,
      updateSearch: 0,
      ordenamientoClover: [
        {
          query_name: 'ordenar',
          slug: 'date',
          text: 'Novedades',
        },
        {
          query_name: 'ordenar',
          slug: 'price_asc',
          text: 'Precio más bajo',
        },
        {
          query_name: 'ordenar',
          slug: 'price_desc',
          text: 'Precio más alto',
        },
        {
          query_name: 'ordenar',
          slug: 'best_seller',
          text: 'Los más vendidos',
        },
        {
          query_name: 'ordenar',
          slug: 'best_visit',
          text: 'Lo más visto',
        },
        {
          query_name: 'ordenar',
          slug: 'sale',
          text: 'Descuentos',
        },
      ],
    }
  },
  computed: {
    ...mapState('products', ['productsCategory', 'filters']),
  },
  watch: {
    openMenu() {
      if (!this.openMenu) {
        this.updateSearch += 1
      }
    },
  },
}
</script>
