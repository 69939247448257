var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-product d-flex flex-wrap",style:(`width: ${parseInt(100 / _vm.gridStore)}%;`)},[_c('router-link',{staticClass:"w-100",attrs:{"to":{
      name: 'ProductDetail',
      params: { url: _vm.dataProduct.url.replace('product-', '') },
      query: { color: _vm.dataProduct.referencia.color_id },
    }}},[_c('div',{staticClass:"container-imgs-product"},[_c('img',{staticClass:"img-product",attrs:{"src":_vm.dataProduct.referencia.imagenes[0],"alt":_vm.dataProduct.producto_titulo + ' imagen 1'}}),_c('img',{staticClass:"img-product-hover",attrs:{"src":_vm.dataProduct.referencia.imagenes.length > 1
            ? _vm.dataProduct.referencia.imagenes[1]
            : _vm.dataProduct.referencia.imagenes[0],"alt":_vm.dataProduct.producto_titulo + ' imagen 1'}})])]),_c('div',{staticClass:"tags-product"},_vm._l((_vm.dataProduct.tags),function(tag,index2){return _c('span',{key:index2 + _vm.dataProduct.producto_titulo,staticClass:"tag-product",style:(`background-color: ${tag.color_fondo}; color: ${tag.color_texto}`)},[_vm._v(_vm._s(tag.tag))])}),0),_c('div',{staticClass:"btn-fav"},[_c('button-fav',{attrs:{"color":_vm.dataProduct.referencia.color_id,"product-sku":_vm.dataProduct.producto_sku}})],1),_c('div',{staticClass:"p-3 w-100"},[_c('router-link',{attrs:{"to":{
        name: 'ProductDetail',
        params: { url: _vm.dataProduct.url.replace('product-', '') },
        query: { color: _vm.dataProduct.referencia.color_id },
      }}},[_c('p',{staticClass:"mb-3 text-start two-wrap-text"},[_vm._v(" "+_vm._s(_vm.dataProduct.producto_titulo)+" ")])]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('p',{staticClass:"mb-0 fw-bold pe-2"},[_vm._v(" $"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.dataProduct.precio))+" "+_vm._s(parseInt(_vm.dataProduct.precio_antes) > 0 ? '-' : '')+" ")]),(parseInt(_vm.dataProduct.precio_antes) > 0)?_c('del',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.dataProduct.precio_antes)))]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }