<template>
  <div class="store-clover">
    <div v-if="infoCatalogo" class="header-store">
      <h1>
        {{
          infoCatalogo.titulo
            ?.toLowerCase()
            .replace(/^\w/, d => d.toUpperCase()) ?? ''
        }}
      </h1>
      <p>{{ infoCatalogo.descripcion }}</p>
    </div>
    <skeleton-title-store v-else class="header-store" />
    <filters-store
      v-if="productsCategory"
      :key="gridStore + 'grid-store'"
      :grid-store="gridStore"
      @changeGrid="gridStore = $event"
    />
    <skeleton-filter v-else />
    <div v-if="estrategiaCatlogo" :key="gridStore + 'grid-store-w'">
      <div class="content-products-store">
        <product-card
          v-for="(product, index) in productsCategory.data.slice(
            0,
            gridStore - 1,
          )"
          :id="`product-${product.producto_sku}-${product.color}`"
          :key="index + gridStore + 'item_card_1'"
          :grid-store="gridStore"
          :index="index"
          :data-product="product"
          :vitrinaid="`store-clover`"
          :vitrinaname="`store clover ${infoCatalogo.titulo}`"
        />
        <card-estrategia-card id="estrategia-store" :grid-store="gridStore" />
        <product-card
          v-for="(product, index) in productsCategory.data.slice(
            gridStore - 1,
            productGrid - 1,
          )"
          :id="`product-${product.producto_sku}-${product.color}`"
          :key="index + gridStore + 'item_card_2'"
          :grid-store="gridStore"
          :index="index"
          :data-product="product"
          :vitrinaid="`store-clover`"
          :vitrinaname="`store clover ${infoCatalogo.titulo}`"
        />
      </div>
      <!-- <rompe-nav /> -->
      <breakin-cupo class="mb-5" />
      <div class="content-products-store">
        <product-card
          v-for="(product, index) in productsCategory.data.slice(
            productGrid - 1,
            productsCategory.length,
          )"
          :id="`product-${product.producto_sku}-${product.color}`"
          :key="index + gridStore + 'item_card_3'"
          :grid-store="gridStore"
          :index="index"
          :data-product="product"
          :vitrinaid="`store-clover`"
          :vitrinaname="`store clover ${infoCatalogo.titulo}`"
        />
      </div>
    </div>
    <div
      v-if="productsCategory && !estrategiaCatlogo"
      :key="gridStore + '_estrategia'"
    >
      <div class="content-products-store">
        <product-card
          v-for="(product, index) in productsCategory.data.slice(
            0,
            productGrid,
          )"
          :id="`product-${product.producto_sku}-${product.color}`"
          :key="index + gridStore + 'item_card_4'"
          :grid-store="gridStore"
          :index="index"
          :data-product="product"
          :vitrinaid="`store-clover`"
          :vitrinaname="`store clover ${infoCatalogo.titulo}`"
        />
      </div>
      <!-- <rompe-nav /> -->
      <breakin-cupo class="mb-5" />
      <div class="content-products-store">
        <product-card
          v-for="(product, index) in productsCategory.data.slice(
            productGrid,
            productsCategory.length,
          )"
          :id="`product-${product.producto_sku}-${product.color}`"
          :key="index + gridStore + 'item_card_5'"
          :grid-store="gridStore"
          :index="index"
          :data-product="product"
          :vitrinaid="`store-clover`"
          :vitrinaname="`store clover ${infoCatalogo.titulo}`"
        />
      </div>
    </div>
    <div v-if="!productsCategory" class="w-100">
      <skeleton-store :grid-store="gridStore" />
    </div>
    <b-pagination
      v-if="productsCategory && productsCategory.last_page > 1"
      v-model="currentPage"
      :total-rows="productsCategory.total"
      :per-page="productsCategory.per_page"
      aria-controls="products-table"
      align="center"
      :hide-goto-end-buttons="true"
      class="pagination-comment"
    ></b-pagination>
    <info-clover />
    <modal-news-latter />
  </div>
</template>
<script>
import { BPagination } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import {
  GA4SelectPromotion,
  GA4ViewPromotion,
  GA4ViewItemList,
} from '@/GA4/events'

import SkeletonStore from '@/components/store/SkeletonStore.vue'
import SkeletonFilter from '@/components/store/SkeletonFilter.vue'
import SkeletonTitleStore from '@/components/store/SkeletonTitleStore.vue'
import FiltersStore from '@/components/store/FiltersStore.vue'
import ProductCard from '@/components/Templates/ProductCard.vue'
import BreakinCupo from '@/components/Templates/BreakinCupo.vue'
import InfoClover from '@/components/homeV1/InfoClover'
import ModalNewsLatter from '@/components/Templates/ModalNewsLatter.vue'
import CardEstrategiaCard from '@/components/Templates/CardEstrategiaCard.vue'
export default {
  components: {
    FiltersStore,
    ProductCard,
    BPagination,
    SkeletonStore,
    BreakinCupo,
    InfoClover,
    SkeletonFilter,
    SkeletonTitleStore,
    ModalNewsLatter,
    CardEstrategiaCard,
  },
  data() {
    return {
      gridStore: 3,
      productGrid: 6,
      currentPage: 1,
      arraySendGA4: [],
      arrayGA4: [],
      viewEstrategia: false,
    }
  },
  computed: {
    ...mapState('products', [
      'productsCategory',
      'infoCatalogo',
      'filters',
      'dataCatalogoAnalytics',
      'estrategiaCatlogo',
    ]),
  },
  watch: {
    currentPage() {
      let query = Object.assign({}, this.$route.query)
      if (query.page != this.currentPage) {
        if (this.currentPage == 1) {
          delete query.page
          this.$router.push({ query: query }).catch(() => {
            location.reload()
          })
        } else {
          query.page = this.currentPage
          this.$router.push({ query: query }).catch(() => {
            location.reload()
          })
        }
        window.scrollTo(0, 0)
      }
    },
    async $route(to, from) {
      if (this.arraySendGA4.length > 0) {
        GA4ViewItemList(this.arraySendGA4, 'store-page', document.title)
        this.arrayGA4 = []
        this.viewEstrategia = false
        this.arraySendGA4 = []
      }
      let title =
        (this.$route.params.collection
          ? this.$route.params.collection
              .toLowerCase()
              .replace(/^\w/, d => d.toUpperCase()) + ', '
          : '') +
        (this.$route.params.collection
          ? this.$route.params.collection
              .toLowerCase()
              .replace(/^\w/, d => d.toUpperCase())
          : 'Todos los productos') +
        ' | Clover Tienda Online'
      document.title = title
      window.scrollTo(0, 0)
      if (to.fullPath != from.fullPath) {
        this.fetchDataProducts()
      }
      if (to.query.page) {
        this.currentPage = to.query.page
      } else {
        this.currentPage = 1
      }
    },
    productsCategory() {
      if (this.productsCategory) {
        if (this.$route.params.nameCreative) {
          let productsGA4 = []
          this.productsCategory.data.forEach(product => {
            productsGA4.push({
              item_name: product.producto_titulo,
              item_id: product.producto_sku,
              price: parseInt(product.producto_precio),
              affiliation: 'Derek',
              item_brand: product.marca,
              item_category: product.categoria,
              item_category2: product.subcategoria,
              item_variant: product.color,
              discount:
                parseInt(product.producto_precio_antes) > 0
                  ? parseInt(product.producto_precio_antes) -
                    parseInt(product.producto_precio)
                  : 0,
              promotion_name: product.descuento ? product.descuento : '',
              quantity: 1,
            })
          })
          GA4SelectPromotion({
            nameCreative: this.$route.params.nameCreative,
            slot: this.$route.params.slot,
            id: this.$route.params.id,
            name: this.$route.params.name,
            items: productsGA4,
          })
        }
      }
      setTimeout(() => {
        this.validateScrollGA4()
      }, 500)
    },
  },
  async mounted() {
    let title = this.$route.params.collection
      ? this.$route.params.collection
          .toLowerCase()
          .replace(/^\w/, d => d.toUpperCase()) + ' | Clover'
      : ''
    document.title = title
    this.displayGrid()
    window.onresize = () => {
      this.displayGrid()
    }
    this.fetchDataProducts()
    if (this.$route.query.page) {
      this.currentPage = this.$route.query.page
    }
    if (window.innerWidth < 768) {
      this.gridStore = 2
    }
    window.addEventListener('scroll', this.validateScrollGA4)
  },
  beforeDestroy() {
    GA4ViewItemList(this.arraySendGA4, 'store-page', document.title)
  },
  methods: {
    ...mapActions('products', ['getProductsCategory', 'getFiltersCategory']),
    displayGrid() {
      if (this.productsCategory) {
        if (window.innerWidth < 768) {
          this.productGrid = 2
        } else if (window.innerWidth < 1000) {
          this.productGrid = 6
        } else {
          this.productGrid = 12
        }
      }
    },
    fetchDataProducts() {
      let query = this.$route.fullPath.split('?')[1]
        ? '?' + this.$route.fullPath.split('?')[1]
        : ''
      this.getProductsCategory({
        slug: this.$route.params.collection,
        query: query,
      })
      this.getFiltersCategory({
        slug: this.$route.params.collection,
        query: query,
      })
    },
    validateScrollGA4() {
      this.productsCategory?.data.forEach(product => {
        let cardProduct = document.getElementById(
          `product-${product.producto_sku}-${product.color}`,
        )
        if (
          cardProduct &&
          !this.arrayGA4.includes(`${product.producto_sku}-${product.color}`) &&
          cardProduct.getBoundingClientRect().top -
            (window.innerHeight - cardProduct.offsetHeight / 2) <=
            0
        ) {
          this.arraySendGA4.push(product)
          this.arrayGA4.push(`${product.producto_sku}-${product.color}`)
        }
      })
      if (this.estrategiaCatlogo) {
        let cardEstrategia = document.getElementById('estrategia-store')
        if (
          cardEstrategia &&
          !this.viewEstrategia &&
          cardEstrategia.getBoundingClientRect().top -
            (window.innerHeight - cardEstrategia.offsetHeight / 2) <=
            0
        ) {
          this.viewEstrategia = true
          GA4ViewPromotion({
            nameCreative: `card estrategia ${this.estrategiaCatlogo.nombre}`,
            slot: 'card-estrategia-store',
            id: `card-estrategia-store-${this.estrategiaCatlogo.nombre}`,
            name: this.estrategiaCatlogo.nombre,
            items: [],
          })
        }
      }
    },
  },
}
</script>
