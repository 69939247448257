<template>
  <div class="conatiner-shipping">
    <p class="text-gray mb-4">
      Revisa la disponibilidad del producto en nuestras tiendas a nivel
      nacional.
    </p>
    <div v-if="false" class="d-flex mb-4 align-items-center">
      <div
        v-b-toggle.sidebar-disponibilidad
        class="bnt-outline d-flex align-items-center me-3"
        style="border-radius: 5px"
      >
        Consultar disponibilidad en tiendas
      </div>
      <svg
        v-b-popover.hover="
          'Nota: Esta información es orientativa y corresponde a cálculos  realizados en la madrugada de hoy, no representa  compromiso de  separación de productos. Los precios y promociones marcadas como exclusivo Internet no aplican en tiendas.'
        "
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
      >
        <g
          id="Icon_feather-info"
          data-name="Icon feather-info"
          transform="translate(-2 -2)"
        >
          <path
            id="Trazado_8184"
            data-name="Trazado 8184"
            d="M23,13A10,10,0,1,1,13,3,10,10,0,0,1,23,13Z"
            transform="translate(0 0)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Trazado_8185"
            data-name="Trazado 8185"
            d="M18,24V18"
            transform="translate(-5 -6.25)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Trazado_8186"
            data-name="Trazado 8186"
            d="M18,12h0"
            transform="translate(-5 -3)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </svg>
    </div>
    <p class="text-gray w-100">Cotiza el valor de envío de este producto:</p>
    <div class="d-flex flex-wrap">
      <div class="col-12 col-lg-6 pe-lg-3 mb-4">
        <div class="input-simple-clover">
          <select v-model="selectDep" class="w-100">
            <option :value="null">Selecciona departamento</option>
            <option
              v-for="(item, index) in departamentos"
              :key="index"
              :value="item.codigo"
            >
              {{
                item.descripcion
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-lg-6 ps-lg-3 mb-4">
        <div class="input-simple-clover">
          <select v-model="selectMun" class="w-100">
            <option :value="null">Selecciona ciudad</option>
            <option
              v-for="(item, index2) in arrayMun"
              :key="index2"
              :value="item.codigo"
            >
              {{
                item.descripcion
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              }}
            </option>
          </select>
        </div>
      </div>
      <div class="shipping">
        <div v-if="selectDep && selectMun && infoEnvio" class="result-shipping">
          <img
            src="@/assets/icons/icon-envio.svg"
            alt="imagen envio clover"
            class="img-envio"
          />
          <p class="mb-0 px-4">
            El envío a
            <b>{{
              arrayMun
                .find(e => e.codigo == selectMun)
                .descripcion.toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}</b>
            tendrá un valor de
            <b
              >${{
                new Intl.NumberFormat('de-DE').format(infoEnvio.envio.total)
              }}*</b
            >
            y si compras hoy llegará entre el
            <b
              >{{ infoEnvio.envio.fechasEnvio.fechaInicio }} y
              {{ infoEnvio.envio.fechasEnvio.fechaFinal }}**</b
            >
          </p>
          <img
            src="@/assets/icons/alert-envio.svg"
            alt="imagen alert clover"
            class="d-none d-md-flex pe-2 ps-3"
          />
        </div>
      </div>
    </div>
    <b-sidebar
      id="sidebar-disponibilidad"
      aria-labelledby="sidebar-no-header-title"
      backdrop-variant="dark"
      backdrop
      no-header
      right
      shadow
    >
      <template v-slot:default="{ hide }">
        <svg
          id="Shopicons_Light_Close"
          xmlns="http://www.w3.org/2000/svg"
          width="16.325"
          height="16.325"
          viewBox="0 0 16.325 16.325"
          @click="hide"
        >
          <path
            id="Trazado_4945"
            data-name="Trazado 4945"
            d="M0,0H16.325V16.325H0Z"
            fill="none"
          />
          <g id="Shopicon" transform="translate(2.24 2.24)">
            <path
              id="Trazado_4946"
              data-name="Trazado 4946"
              d="M7.067,18.431l5.442-5.442,5.442,5.442.481-.481-5.442-5.442,5.442-5.442-.481-.481-5.442,5.442L7.067,6.586l-.481.481,5.442,5.442L6.586,17.95Z"
              transform="translate(-6.586 -6.586)"
            />
          </g>
        </svg>
        <div class="container-sidebar-disponibilidad">
          <h5 class="mb-4">Disponibilidad en tienda</h5>
          <p>Faltan tiendas de clover</p>
          <div
            v-for="(item, index) in infoShipping"
            :key="index"
            class="container-city"
          >
            <h6>{{ item.city }}</h6>
            <div
              v-for="(info, index2) in item.tiendas"
              :key="index2"
              class="container-info-city"
            >
              <p>
                <b>{{ info.tiendaNombre }}</b> <br />
                {{ info.dir }} <br />
                {{ info.tel }}
              </p>
              <a href="" target="_blank">
                <img src="@/assets/icons/maps_click.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </template>
    </b-sidebar>
    <div class="mt-4">
      <h4 class="mb-3">Imágenes de referencia</h4>
      <p>
        <b>CLOVER</b> ha realizado su mayor esfuerzo para que los colores y
        texturas de los productos que se ven en las fotos sean lo más cercanos
        posible a la realidad. Sin embargo, estos pueden variar por factores
        como la luz, el contraste, la calidad del monitor del computador en el
        que se visualizan, entre otros. CLOVER no puede garantizar que los
        colores que se visualizan en las pantallas correspondan exactamente a
        los de la realidad de los Productos.
      </p>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      selectDep: null,
      invalidSelectDep: false,
      selectMun: null,
      invalidSelectMun: false,
      arrayMun: [],
      infoShipping: [
        {
          city: 'Bucaramanga',
          tiendas: [
            {
              tiendaNombre: 'Clover Cacique',
              dir: 'C.C.Cacique Loc. 219',
              tel: 'Cel. 3003870181',
            },
            {
              tiendaNombre: 'Clover Aeropuerto',
              dir: 'Aeropuerto Palonegro Loc. 219',
              tel: 'Cel. 3003870181',
            },
            {
              tiendaNombre: 'Clover Aeropuerto',
              dir: 'C.C.Cacique Loc. 219',
              tel: 'Cel. 3003870181',
            },
            {
              tiendaNombre: 'Clover Cacique',
              dir: 'C.C.Cacique Loc. 219',
              tel: 'Cel. 3003870181',
            },
          ],
        },
        {
          city: 'Bogota',
          tiendas: [
            {
              tiendaNombre: 'Clover Cacique',
              dir: 'C.C.Cacique Loc. 219',
              tel: 'Cel. 3003870181',
            },
            {
              tiendaNombre: 'Clover Aeropuerto',
              dir: 'Aeropuerto Palonegro Loc. 219',
              tel: 'Cel. 3003870181',
            },
            {
              tiendaNombre: 'Clover Aeropuerto',
              dir: 'C.C.Cacique Loc. 219',
              tel: 'Cel. 3003870181',
            },
            {
              tiendaNombre: 'Clover Cacique',
              dir: 'C.C.Cacique Loc. 219',
              tel: 'Cel. 3003870181',
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapState('user', ['departamentos']),
    ...mapState('products', ['infoEnvio']),
  },
  watch: {
    selectDep() {
      if (this.selectDep) {
        this.selectMun = null
        this.arrayMun = this.departamentos.find(
          e => e.codigo == this.selectDep,
        )?.ciudades
      } else {
        this.arrayMun = []
      }
    },
    departamentos() {
      if (this.selectDep) {
        this.arrayMun = this.departamentos.find(
          e => e.codigo == this.selectDep,
        )?.ciudades
      } else {
        this.arrayMun = []
      }
    },
    async selectMun() {
      if (this.selectMun) {
        await this.calculateShipping({
          dep: this.selectDep,
          ciu: this.selectMun,
        })
      }
    },
  },
  methods: {
    ...mapActions('products', ['calculateShipping']),
  },
}
</script>
<style lang="scss">
#sidebar-disponibilidad {
  width: 400px;
  #Shopicons_Light_Close {
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
    cursor: pointer;
    z-index: 1;
    &:hover {
      #Trazado_4946 {
        stroke: #000000;
      }
    }
  }
}
.container-sidebar-disponibilidad {
  position: relative;
  padding: 1rem;
  .container-city {
    margin-bottom: 2rem;
    h6 {
      padding-bottom: 0.6rem;
      border-bottom: 1px solid #d5d5d5;
      margin-bottom: 0;
    }
    .container-info-city {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #d5d5d5;
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
      img {
        min-width: 40px;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}
</style>
