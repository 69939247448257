<template>
  <div class="content-carousel-skeleton">
    <b-skeleton class="ms-2" width="200px" height="40px"></b-skeleton>
    <carousel
      class="content-carousel-products-skeleton"
      :dots="false"
      :lazy-load="true"
      :nav="false"
      :responsive="{
        0: { items: 1, nav: false, stagePadding: 40 },
        768: { items: 3, nav: false, stagePadding: 0 },
        1500: { items: 4, nav: false, stagePadding: 30 },
        1900: { items: 5, nav: false, stagePadding: 15 },
      }"
    >
      <template slot="prev"
        ><span class="prev nav-button"
          ><img
            src="@/assets/icons/left-carousel.svg"
            alt="left arrow carousel"/></span
      ></template>
      <template slot="next"
        ><span class="next nav-button"
          ><img
            src="@/assets/icons/right-carousel.svg"
            alt="right arrow carousel"/></span
      ></template>
      <skeleton-card-product v-for="index in 12" :key="index" />
    </carousel>
  </div>
</template>
<script>
import SkeletonCardProduct from '@/components/Templates/SkeletonCardProduct'

import carousel from 'v-owl-carousel'
export default {
  components: { carousel, SkeletonCardProduct },
}
</script>
<style lang="scss">
.content-carousel-skeleton {
  max-width: 80%;
  margin-right: auto;
  margin: auto;
  width: 100%;
  margin-bottom: 4rem;
  .content-carousel-products-skeleton {
    margin-top: 1.5rem;
    position: relative;
    .prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -2rem;
      cursor: pointer;
      img {
        filter: opacity(0.4);
      }
    }
    .next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -2rem;
      cursor: pointer;
      img {
        filter: opacity(0.4);
      }
    }
  }
  @media (max-width: 768px) {
    max-width: 90%;
    .content-carousel-products-skeleton {
      .prev {
        left: -1rem;
      }
      .next {
        right: -1rem;
      }
    }
  }
}
</style>
