<template>
  <b-modal
    v-if="!hideModalNewsletter"
    id="modal-newsletter"
    style="z-index: 999999999999"
    size="lg"
    body-bg-variant="rgba(0, 13, 80, .5)"
    centered
    hide-footer
    hide-header
    @hide="hideModalTemporarily(3)"
  >
    <div class="d-flex flex-wrap">
      <div class="col-md-6 d-none d-md-flex position-relativer">
        <h3
          class="col-md-5"
          style="position: absolute; bottom: 2rem; left: 2rem"
          v-html="tituloNewslatter"
        ></h3>
        <img class="w-100 mb-auto" :src="getImgBanner" alt="" />
      </div>
      <div class="col-12 col-md-6 ps-0 ps-lg-4 container-form-newslatter-modal">
        <div class="mb-auto p-2 pt-4">
          <h3 class="d-flex d-md-none pe-3" v-html="tituloNewslatter"></h3>
          <p v-html="descripcionNewslatter"></p>
          <hr class="pb-3" style="border-color: #818181" />
          <div class="input-simple-clover mb-4">
            <label
              :class="validateEmailNewslatter ? 'invalid' : ''"
              class="label-input"
              :style="correo ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >
              Correo electrónico
            </label>
            <input
              id="email"
              v-model="correo"
              :class="validateEmailNewslatter ? 'invalid-error' : ''"
              placeholder="Correo electrónico"
              type="email"
              name="correo"
              @focus="validateEmailNewslatter = false"
            />
            <span v-if="validateEmailNewslatter" class="inavalida-input">
              {{ correo ? 'Correo inválido' : 'Campo requerido' }}
            </span>
          </div>
          <div class="mb-4 w-100 position-relative input-simple-clover">
            <label
              class="label-input"
              :style="name ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >
              Nombre completo
            </label>
            <input
              id="name"
              v-model="name"
              type="name"
              style="min-height: 50px"
              class="payment-input w-100"
              :style="validateNameNewslatter ? 'border-color: #FF5151' : ''"
              placeholder="Nombre completo"
              @focus="validateNameNewslatter = false"
            />
            <span v-if="validateNameNewslatter" class="inavalida-input">
              {{ name ? 'Nombre inválido' : 'Campo requerido' }}
            </span>
          </div>
          <div class="mb-4 w-100 position-relative input-simple-clover">
            <label
              class="label-input"
              :style="tel ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >
              celular
            </label>
            <input
              v-model="tel"
              class="payment-input w-100"
              placeholder="Celular"
              type="tel"
              :style="validateTelNewslatter ? 'border-color: #FF5151' : ''"
              name="celular"
              @focus="validateTelNewslatter = false"
            />
            <span v-if="validateTelNewslatter" class="inavalida-input">
              {{ tel ? 'Celular inválido' : 'Campo requerido' }}
            </span>
          </div>
          <div class="date-newslatter-modal">
            <label
              class="mb-4 d-flex align-items-center justify-content-center"
              style="color: #949494"
            >
              Fecha de cumpleaños
            </label>
            <div class="d-flex flex-wrap mb-4">
              <div class="col-6 pe-2">
                <div class="input-simple-clover w-100">
                  <label
                    class="label-input"
                    :style="day ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
                    >Día</label
                  >
                  <select
                    v-model="day"
                    :class="!day && validateDateNewslatter ? 'input-error' : ''"
                    class="payment-input"
                    :style="
                      !day && validateDateNewslatter
                        ? 'border-color: #FF5151'
                        : ''
                    "
                    @focus="validateDateNewslatter"
                  >
                    <option :value="null">Día</option>
                    <option :value="'01'">1</option>
                    <option :value="'02'">2</option>
                    <option :value="'03'">3</option>
                    <option :value="'04'">4</option>
                    <option :value="'05'">5</option>
                    <option :value="'06'">6</option>
                    <option :value="'07'">7</option>
                    <option :value="'08'">8</option>
                    <option :value="'09'">9</option>
                    <option :value="'10'">10</option>
                    <option :value="'11'">11</option>
                    <option :value="'12'">12</option>
                    <option :value="'13'">13</option>
                    <option :value="'14'">14</option>
                    <option :value="'15'">15</option>
                    <option :value="'16'">16</option>
                    <option :value="'17'">17</option>
                    <option :value="'18'">18</option>
                    <option :value="'19'">19</option>
                    <option :value="'20'">20</option>
                    <option :value="'21'">21</option>
                    <option :value="'22'">22</option>
                    <option :value="'23'">23</option>
                    <option :value="'24'">24</option>
                    <option :value="'25'">25</option>
                    <option :value="'26'">26</option>
                    <option :value="'27'">27</option>
                    <option :value="'28'">28</option>
                    <option :value="'29'">29</option>
                    <option :value="'30'">30</option>
                    <option :value="'31'">31</option>
                  </select>
                  <span
                    v-if="!day && validateDateNewslatter"
                    class="inavalida-input"
                  >
                    {{ day ? 'Día inválido' : 'Campo requerido' }}
                  </span>
                </div>
              </div>
              <div class="col-6 ps-2">
                <div class="input-simple-clover w-100">
                  <label
                    class="label-input"
                    :style="month ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
                    >Mes</label
                  >
                  <select
                    v-model="month"
                    :class="
                      !month && validateDateNewslatter ? 'input-error' : ''
                    "
                    :style="
                      !month && validateDateNewslatter
                        ? 'border-color: #FF5151'
                        : ''
                    "
                    class="payment-input"
                    @focus="validateDateNewslatter = false"
                  >
                    <option :value="null">Mes</option>
                    <option :value="'01'">Enero</option>
                    <option :value="'02'">Febrero</option>
                    <option :value="'03'">Marzo</option>
                    <option :value="'04'">Abril</option>
                    <option :value="'05'">Mayo</option>
                    <option :value="'06'">Junio</option>
                    <option :value="'07'">Julio</option>
                    <option :value="'08'">Agosto</option>
                    <option :value="'09'">Septiembre</option>
                    <option :value="'10'">Octubre</option>
                    <option :value="'11'">Noviembre</option>
                    <option :value="'12'">Diciembre</option>
                  </select>
                  <span
                    v-if="!month && validateDateNewslatter"
                    class="inavalida-input"
                  >
                    {{ month ? 'Mes inválido' : 'Campo requerido' }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <div class="container-input-checkbox align-items-center">
            <input
              id="input-checkbox-tel"
              v-model="tyc"
              class="input-checkbox"
              type="checkbox"
              checkmark="checkmark"
            />
            <span class="checkmark"></span>
            <label
              for="input-checkbox-tel"
              style="font-size: 12px; color: #818181;"
            >
              He leído y acepto la
              <router-link
                style="color: black; font-weight: bold;"
                :to="{
                  name: 'HabeasData',
                  params: {
                    politica: 'politica-de-tratamiento-de-datos',
                  },
                }"
              >
                Política de Privacidad y Tratamiento de datos personales.
              </router-link>
              Acepto recibir novedades y promociones. Aplican
              <router-link
                style="color: black; font-weight: bold;"
                :to="{
                  name: 'HabeasData',
                  params: {
                    politica: 'descuento-suscripcion',
                  },
                }"
              >
                Términos y Condiciones.
              </router-link>
            </label>
          </div>
        </div>
        <div v-if="errorSolicitud" class="text-center w-100">
          <span class="text-error">{{ msgErrorSolicitud }}</span>
        </div>
        <div class="mt-auto w-100">
          <div class="col-6 mx-auto pt-2 pb-3">
            <button
              :disabled="!tyc"
              :style="tyc ? '' : 'opacity: 0.7; cursor: default'"
              class="bnt-primary w-100"
              @click="sendNewsLetter()"
            >
              Suscribirme
            </button>
          </div>
        </div>
      </div>
    </div>
    <img
      class="btn-close-modal-product-detail"
      src="@/assets/icons/close-modal.svg"
      alt="boton cerrar"
      @click="$bvModal.hide('modal-newsletter')"
    />
  </b-modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { GA4SignUp } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'
export default {
  data() {
    return {
      correo: '',
      name: '',
      tel: '',
      day: null,
      month: null,
      tyc: false,
      validateEmailNewslatter: false,
      validateDateNewslatter: false,
      validateNameNewslatter: false,
      validateTelNewslatter: false,
      hideModalNewsletter: false,
      getImgBanner: '',
    }
  },
  computed: {
    ...mapState('newslatter', [
      'errorSolicitud',
      'msgErrorSolicitud',
      'tituloNewslatter',
      'descripcionNewslatter',
    ]),
    /* eslint-disable */
    validateEmail() {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.correo)
    },
    validatePhone() {
      const re = /^3[0-9]{9}/
      return re.test(this.tel) && this.tel.length < 11
    },
  },
  created() {
    const itemStr = localStorage.getItem('hideModalNewsletter')
    if (itemStr) {
      const item = JSON.parse(itemStr)
      const now = new Date()
      if (now.getTime() > item.expiry) {
        localStorage.removeItem('hideModalNewsletter')
      } else {
        this.hideModalNewsletter = true
      }
    }
  },
  async mounted() {
    await setTimeout(() => {
      this.$bvModal.show('modal-newsletter')
    }, 4000)
    await this.axios({
      method: 'GET',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: '/layout/get_newsletter',
    }).then(response => {
      this.getImgBanner = response.data.newsletter.imagen
    })
  },
  methods: {
    ...mapActions('newslatter', ['sendSoliNewsLatter', 'getInfoNewsLatter']),
    hideModalTemporarily(days) {
      const now = new Date()
      const item = {
        expiry: now.getTime() + days * 24 * 60 * 60 * 1000,
      }
      localStorage.setItem('hideModalNewsletter', JSON.stringify(item))
    },
    async sendNewsLetter() {
      if (
        this.validateEmail &&
        this.validatePhone &&
        this.name.length > 2 &&
        this.day && this.month &&
        this.tyc
      ) {
        this.$store.state.loaderClover = true
        let data = {
          email: this.correo,
          name: this.name,
          day: this.day,
          month: this.month,
          sendSMS: 1,
          tel: this.tel,
        }
        await this.sendSoliNewsLatter(data).then(() => {
          this.$store.state.loaderClover = false
          if (!this.errorSolicitud) {
            GA4SignUp('newslatter')
            this.activeStepNewslatter = 4
            sendFBQEvent(this, 'Subscribe', {})
            this.$bvModal.hide('modal-newsletter')
            this.$bvModal.show('modal-done-newsletter')
            document.cookie = 'email=' + this.correo
            this.correo = ''
            this.name = ''
            this.tel = ''
            this.day = null
            this.month = null
            this.sended = true
            this.focusInput = false
          }
        })
      } else {
        this.validateEmailNewslatter = !this.validateEmail
        this.validateNameNewslatter = !(this.name.length > 2)
        this.validateDateNewslatter = !(this.day && this.month)
        this.validateTelNewslatter = !this.validatePhone
      }
    },
  },
}
</script>
<style lang="scss">
#modal-newsletter {
  .modal-dialog {
    max-width: 800px;
  }
  .btn-close-modal-product-detail {
    position: absolute;
    top: 1rem;
    right: 1rem;
    @media (max-width: 768px) {
      width: 26px;
      height: 26px;
    }
  }
}
</style>
