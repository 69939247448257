<template>
  <div class="container-filter">
    <div class="header-filter">
      <span v-b-toggle.sidebar-color class="title">Colores</span>
    </div>
    <b-sidebar
      id="sidebar-color"
      backdrop-variant="dark"
      backdrop
      no-header
      right
      shadow
    >
      <template v-slot:default="{ hide }">
        <div class="container-sidebar">
          <header
            class="b-sidebar-header d-flex w-100 justify-content-between py-3"
          >
            <h4 class="mb-0">Colores</h4>
            <div class="d-flex align-items-center" style="min-width: 136px">
              <span class="borrar-filtro pe-2" @click="colorsSelected = []"
                >Borrar filtro</span
              >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style="cursor: pointer"
                width="20"
                height="20"
                viewBox="0 0 48 48"
                @click="hide"
              >
                <path
                  id="Trazado_4945"
                  data-name="Trazado 4945"
                  d="M0,0H48V48H0Z"
                  fill="none"
                />
                <g id="Shopicon">
                  <path
                    id="Trazado_4946"
                    data-name="Trazado 4946"
                    d="M8,41.414l16-16,16,16L41.414,40l-16-16,16-16L40,6.586l-16,16-16-16L6.586,8l16,16-16,16Z"
                  />
                </g>
              </svg>
            </div>
          </header>
          <div class="body-filter">
            <div
              v-for="(color, index) in filtros"
              :key="index"
              class="container-filter-color"
              :class="colorsSelected.includes(color.id) ? 'colorSelect' : ''"
              @click="addFilter(color.id, false)"
            >
              <div
                class="color"
                :style="`background-color: ${color.color_hx}`"
              ></div>
              <p class="text-color">
                {{
                  color.descripcion
                    .toLowerCase()
                    .replace(/^\w/, c => c.toUpperCase())
                }}
              </p>
            </div>
          </div>
          <div class="btn-filter">
            <button
              class="w-100 bnt-primary"
              @click="
                hide
                addFilter('', true)
              "
            >
              Aplicar filtros
            </button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>
<script>
export default {
  props: {
    filtros: {
      type: Array,
      required: false,
      default: () => [],
    },
    updateSearch: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      colorsSelected: [],
    }
  },
  watch: {
    updateSearch() {
      if (this.updateSearch > 0) {
        this.addFilter('', true)
      }
    },
  },
  mounted() {
    if (this.$route.query.colores) {
      this.colorsSelected = this.$route.query.colores.split(',')
    }
  },
  methods: {
    addFilter(color, action) {
      if (action) {
        let query = Object.assign({}, this.$route.query)
        if (this.colorsSelected.length > 0) {
          query.colores = this.colorsSelected.toString()
        }
        if (this.colorsSelected.length == 0 && query.colores) {
          delete query.colores
        }
        this.$router.push({ query: query })
      } else {
        if (this.colorsSelected.includes(color)) {
          this.colorsSelected = this.colorsSelected.filter(
            item => item !== color,
          )
        } else {
          this.colorsSelected.push(color)
        }
      }
    },
  },
}
</script>
