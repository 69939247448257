var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"__content-product-outstanding"},[_c('div',{staticClass:"__product-outstanding"},[_c('div',{staticClass:"col-12 col-lg-6 d-flex justify-content-end mb-4 mb-lg-0"},[_c('router-link',{staticClass:"ms-auto",attrs:{"to":{
          name: 'ProductDetail',
          params: {
            url: _vm.dataProduct.url.replace('product-', ''),
            vitrinaId: 'producto-destacado',
            vitrinaName: 'Producto destadaco home',
          },
          query: { color: _vm.dataProduct.color },
        }}},[_c('img',{staticStyle:{"width":"100%","max-width":"610px"},attrs:{"src":_vm.dataProduct.imagen}})])],1),_c('div',{staticClass:"col-12 col-lg-6 d-flex flex-wrap align-content-center px-lg-5"},[_c('h2',{staticClass:"title-outstanding",domProps:{"innerHTML":_vm._s(_vm.dataProduct.producto_titulo)}}),_c('p',{staticClass:"price-outstanding"},[_vm._v(" $"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.dataProduct.producto_precio))+" ")]),_c('p',{staticClass:"description-outstanding",domProps:{"innerHTML":_vm._s(_vm.dataProduct.descripcion)}}),_c('div',{staticClass:"d-flex"},[_c('router-link',{staticClass:"w-100",attrs:{"to":{
            name: 'ProductDetail',
            params: {
              url: _vm.dataProduct.url.replace('product-', ''),
              vitrinaId: 'producto-destacado',
              vitrinaName: 'Producto destadaco home',
            },
            query: { color: _vm.dataProduct.color },
          }}},[_c('button',{staticClass:"btn-primary-clover px-5"},[_vm._v("Ver detalles")])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }