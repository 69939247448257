<template>
  <div class="container-filter">
    <div class="header-filter">
      <span v-b-toggle="'sidebar-' + filterId" class="title">{{ title }}</span>
    </div>
    <b-sidebar :id="'sidebar-' + filterId" no-header right shadow>
      <template v-slot:default="{ hide }">
        <div class="container-sidebar">
          <header
            class="b-sidebar-header d-flex w-100 justify-content-between py-3"
          >
            <h4 class="mb-0">{{ title }}</h4>
            <div class="d-flex align-items-center" style="min-width: 136px">
              <span class="borrar-filtro pe-2" @click="filterSelect = null"
                >Borrar filtro</span
              >
              <svg
                style="cursor: pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 48 48"
                @click="hide"
              >
                <path
                  id="Trazado_4945"
                  data-name="Trazado 4945"
                  d="M0,0H48V48H0Z"
                  fill="none"
                />
                <g id="Shopicon">
                  <path
                    id="Trazado_4946"
                    data-name="Trazado 4946"
                    d="M8,41.414l16-16,16,16L41.414,40l-16-16,16-16L40,6.586l-16,16-16-16L6.586,8l16,16-16,16Z"
                  />
                </g>
              </svg>
            </div>
          </header>
          <div class="body-filter">
            <div
              v-for="(filter, index) in filtros"
              :key="index"
              :class="
                filterSelect && filterSelect.slug == filter.slug
                  ? 'selected'
                  : ''
              "
              class="container-filter-simple"
              @click="addFilter(filter, false)"
            >
              <p class="text-filter">{{ filter.text }}</p>
            </div>
          </div>
          <div class="btn-filter">
            <button
              class="w-100 bnt-primary"
              @click="
                addFilter('', true)
                hide
              "
            >
              Aplicar filtros
            </button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>
<script>
export default {
  props: {
    filterId: {
      type: String,
      required: true,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    filtros: {
      type: Array,
      required: false,
      default: () => [],
    },
    updateSearch: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      filterSelect: null,
      queryId: '',
    }
  },
  watch: {
    updateSearch() {
      if (this.updateSearch > 0) {
        this.addFilter('', true)
      }
    },
  },
  mounted() {
    let query = Object.assign({}, this.$route.query)
    this.queryId = this.filtros[0].query_name
    if (query[this.filtros[0].query_name]) {
      this.filterSelect = this.filtros.find(
        e => e.slug == query[this.filtros[0].query_name],
      )
    }
    if (
      this.filterId == 'destacado' &&
      this.filtros.find(e => e.slug == this.$route.params.collection)
    ) {
      this.filterSelect = this.filtros.find(
        e => e.slug == this.$route.params.collection,
      )
    }
  },
  methods: {
    addFilter(filter, action) {
      if (action) {
        let query = Object.assign({}, this.$route.query)
        if (this.filterSelect) {
          query[this.filterSelect.query_name] = this.filterSelect.slug
        }
        if (!this.filterSelect && query[this.queryId]) {
          delete query[this.queryId]
        }
        this.$router.push({ query: query })
      } else {
        if (this.filterSelect && this.filterSelect.slug == filter.slug) {
          this.filterSelect = null
        } else {
          this.filterSelect = filter
        }
      }
    },
  },
}
</script>
