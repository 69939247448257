<template>
  <div>
    <!-- Tomado de: https://github.com/rajeevgade/vue-360 -->
    <!-- 360 Viewer Container -->
    <div :id="identifier" ref="viewerContainer" class="v360-viewer-container">
      <!-- 360 Viewer Header -->
      <slot name="header"></slot>
      <!--/ 360 Viewer Header -->

      <!-- Percentage Loader -->
      <div v-if="!imagesLoaded" class="v360-viewport v360-viewport-loader">
        <img src="@/assets/loader.gif" style="max-width: 50px" />
        <p ref="viewPercentage" class="mb-0 v360-percentage-text"></p>
      </div>
      <!--/ Percentage Loader -->

      <!-- 360 viewport -->
      <div ref="viewport" class="v360-viewport" @mouseover="clearView360()">
        <canvas
          ref="imageContainer"
          v-hammer:pinch="onPinch"
          v-hammer:pinchend="onPinch"
          v-hammer:pinchout="onPinchOut"
          v-hammer:pinchin="onPinchIn"
          class="v360-image-container"
        ></canvas>
        <div
          v-if="boxShadow"
          v-hammer:pinch="onPinch"
          v-hammer:pinchend="onPinch"
          v-hammer:pinchout="onPinchOut"
          v-hammer:pinchin="onPinchIn"
          class="v360-product-box-shadow"
        ></div>
        <svg
          v-if="showView360"
          style="position: absolute"
          xmlns="http://www.w3.org/2000/svg"
          width="140"
          height="140"
          viewBox="0 0 140 140"
        >
          <g
            id="Grupo_7334"
            data-name="Grupo 7334"
            transform="translate(16588 -4026)"
          >
            <circle
              id="Elipse_685"
              data-name="Elipse 685"
              cx="70"
              cy="70"
              r="70"
              transform="translate(-16588 4026)"
              fill="#fff"
              opacity="0.6"
            />
            <g
              id="_360-degrees"
              data-name="360-degrees"
              transform="translate(-16549 3961.097)"
            >
              <g
                id="Grupo_7319"
                data-name="Grupo 7319"
                transform="translate(0 101.903)"
              >
                <g
                  id="Grupo_7318"
                  data-name="Grupo 7318"
                  transform="translate(0 0)"
                >
                  <path
                    id="Trazado_8190"
                    data-name="Trazado 8190"
                    d="M333.391,108.953a3.525,3.525,0,1,0-3.525-3.525A3.525,3.525,0,0,0,333.391,108.953Zm0-4.574a1.047,1.047,0,1,1-1.047,1.047A1.047,1.047,0,0,1,333.391,104.379Z"
                    transform="translate(-283.756 -101.903)"
                  />
                  <path
                    id="Trazado_8191"
                    data-name="Trazado 8191"
                    d="M53.143,162.116a1.239,1.239,0,1,0-1.112,2.214l.036.017c4.68,2.26,7.267,5.092,7.267,7.983,0,3-2.9,6.028-7.958,8.3-5.446,2.45-12.716,3.8-20.469,3.8s-15.021-1.349-20.469-3.8c-5.058-2.274-7.958-5.3-7.958-8.3,0-2.89,2.581-5.72,7.267-7.983a1.239,1.239,0,0,0-1.04-2.248l-.036.017C3.08,164.811,0,168.437,0,172.327,0,180.5,13.575,186.9,30.9,186.9s30.9-6.4,30.9-14.578C61.812,168.437,58.734,164.811,53.143,162.116Z"
                    transform="translate(0 -153.585)"
                  />
                  <path
                    id="Trazado_8192"
                    data-name="Trazado 8192"
                    d="M86.9,171.349a6.837,6.837,0,0,0,8.284.043,4.534,4.534,0,0,0,1.566-3.578,3.878,3.878,0,0,0-.786-2.5,3.492,3.492,0,0,0-2.2-1.239,3.428,3.428,0,0,0,1.778-1.163,3.124,3.124,0,0,0,.61-1.949,3.438,3.438,0,0,0-1.329-2.849,5.68,5.68,0,0,0-3.645-1.038,6.233,6.233,0,0,0-4.038,1.174,4.113,4.113,0,0,0-1.484,3.288h3.661a1.522,1.522,0,0,1,1.676-1.624,1.707,1.707,0,0,1,1.2.435,1.464,1.464,0,0,1,.471,1.127,1.609,1.609,0,0,1-.559,1.324,2.48,2.48,0,0,1-1.6.456h-.212v2.431h.465a2.484,2.484,0,0,1,1.7.491A1.968,1.968,0,0,1,93,167.715a1.891,1.891,0,0,1-.506,1.422,2.01,2.01,0,0,1-1.458.491,1.769,1.769,0,0,1-1.376-.512,2.191,2.191,0,0,1-.469-1.515v-.248H85.356v.145A4.975,4.975,0,0,0,86.9,171.349Z"
                    transform="translate(-73.425 -149.365)"
                  />
                  <path
                    id="Trazado_8193"
                    data-name="Trazado 8193"
                    d="M186.238,174.6a5.866,5.866,0,0,0,4.224-1.5,5.272,5.272,0,0,0,1.577-4,4.823,4.823,0,0,0-1.262-3.428,4.173,4.173,0,0,0-3.185-1.35,5.809,5.809,0,0,0-.826.056,4.823,4.823,0,0,0-.756.171l3.537-5.336h-4.22l-2.121,3.281a24.758,24.758,0,0,0-2.187,3.971,7.414,7.414,0,0,0-.522,2.658,5.289,5.289,0,0,0,1.551,4A5.766,5.766,0,0,0,186.238,174.6Zm-1.713-7.019a2.544,2.544,0,0,1,3.343,0,2.322,2.322,0,0,1,.615,1.7,2.27,2.27,0,0,1-.615,1.686,2.57,2.57,0,0,1-3.343.006,2.29,2.29,0,0,1-.615-1.686A2.362,2.362,0,0,1,184.525,167.581Z"
                    transform="translate(-155.268 -151.197)"
                  />
                  <path
                    id="Trazado_8194"
                    data-name="Trazado 8194"
                    d="M280.644,172.771a4.928,4.928,0,0,0,4.194-2.064,9.312,9.312,0,0,0,1.525-5.643,9.968,9.968,0,0,0-1.5-5.889,5.281,5.281,0,0,0-8.418,0,11.56,11.56,0,0,0,0,11.516A4.913,4.913,0,0,0,280.644,172.771Zm-1.469-11.5a1.5,1.5,0,0,1,2.911.026,14.436,14.436,0,0,1,0,7.229,1.494,1.494,0,0,1-2.905.006,14.631,14.631,0,0,1-.006-7.256Z"
                    transform="translate(-236.478 -149.368)"
                  />
                </g>
              </g>
            </g>
            <g id="drag" transform="translate(-16535.305 4104.778)">
              <path
                id="Trazado_8195"
                data-name="Trazado 8195"
                d="M21.981,72.488a3.362,3.362,0,0,0-3.262,3.448L18.712,91.2l-.779-.95a4.441,4.441,0,0,0-3.139-1.65,4.375,4.375,0,0,0-3.276,1.159l-.757.628a.724.724,0,0,0-.18.892l7.372,14.182a7.661,7.661,0,0,0,6.737,4.244h8.269a7.989,7.989,0,0,0,7.7-8.209c0-2,0-3.5.005-4.713,0-3.251.006-4.481,0-8.062a3.381,3.381,0,0,0-3.27-3.456h-.057a3.159,3.159,0,0,0-2.061.782A3.282,3.282,0,0,0,32.24,83.89a3.161,3.161,0,0,0-2.222.914A3.259,3.259,0,0,0,27.148,83h-.06a3.3,3.3,0,0,0-1.852.6V75.94a3.356,3.356,0,0,0-3.255-3.452Zm5.107,11.958h.06a1.921,1.921,0,0,1,1.8,2.022v4.076a.724.724,0,1,0,1.448,0v-3.2a1.861,1.861,0,1,1,3.711,0v3.017a.724.724,0,1,0,1.448,0V88.73a1.908,1.908,0,0,1,1.784-2.021H37.4a1.93,1.93,0,0,1,1.822,2.006c.008,3.578.007,4.8,0,8.048,0,1.211,0,2.724-.005,4.724a6.543,6.543,0,0,1-6.254,6.764H24.692a6.22,6.22,0,0,1-5.453-3.464l-7.1-13.654.321-.27.028-.027a2.943,2.943,0,0,1,2.209-.794,3.01,3.01,0,0,1,2.123,1.123l2.063,2.511a.724.724,0,0,0,1.284-.46l.012-17.279a1.816,1.816,0,1,1,3.613,0V86.357c0,.036,0,.074,0,.11,0,.017,0,.033,0,.05V90.97a.724.724,0,1,0,1.448,0V86.382a1.993,1.993,0,0,1,1.852-1.936Zm0,0"
                transform="translate(-9.279 -65.931)"
              />
              <path
                id="Trazado_8196"
                data-name="Trazado 8196"
                d="M.7,5.314,4.184,8.8A.724.724,0,0,0,5.209,7.775L2.824,5.391h22.05A4.741,4.741,0,1,0,24.9,3.942H2.966L5.208,1.7A.724.724,0,1,0,4.184.675L.908,3.952A.723.723,0,0,0,.7,5.314Zm28.87-3.87a3.293,3.293,0,1,1-3.293,3.293,3.293,3.293,0,0,1,3.293-3.293Zm0,0"
                transform="translate(0 0)"
              />
            </g>
          </g>
        </svg>
      </div>
      <!--/ 360 viewport -->

      <!-- Fullscreen Button -->
      <abbr title="Fullscreen Toggle">
        <div
          class="v360-fullscreen-toggle text-center"
          @click="toggleFullScreen"
        >
          <div
            class="v360-fullscreen-toggle-btn"
            :class="buttonClass == 'dark' ? 'text-light' : 'text-dark'"
          >
            <i
              :class="
                !isFullScreen
                  ? 'fas fa-expand text-lg'
                  : 'fas fa-compress text-lg'
              "
            ></i>
          </div>
        </div>
      </abbr>
      <!--/ Fullscreen Button -->

      <!-- Buttons Container -->
      <div id="v360-menu-btns" :class="buttonClass">
        <div class="v360-navigate-btns">
          <div
            class="v360-menu-btns"
            :class="playing ? 'v360-btn-active' : ''"
            @click="togglePlay"
          >
            <svg
              v-if="!playing"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
            >
              <g
                id="Grupo_7325"
                data-name="Grupo 7325"
                transform="translate(16716 -4578)"
              >
                <rect
                  id="Rectángulo_3070"
                  data-name="Rectángulo 3070"
                  width="40"
                  height="40"
                  rx="5"
                  transform="translate(-16716 4578)"
                  fill="#f4f6f8"
                />
                <path
                  id="Icon_feather-play"
                  data-name="Icon feather-play"
                  d="M7.5,4.5,28.5,18,7.5,31.5Z"
                  transform="translate(-16714 4580)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </g>
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
            >
              <g
                id="Grupo_7325"
                data-name="Grupo 7325"
                transform="translate(16716 -4578)"
              >
                <g id="Grupo_7326" data-name="Grupo 7326">
                  <rect
                    id="Rectángulo_3070"
                    data-name="Rectángulo 3070"
                    width="40"
                    height="40"
                    rx="5"
                    transform="translate(-16716 4578)"
                    fill="#f4f6f8"
                  />
                  <g
                    id="Rectángulo_3078"
                    data-name="Rectángulo 3078"
                    transform="translate(-16709 4586)"
                    fill="rgba(255,255,255,0)"
                    stroke="#000"
                    stroke-width="1.5"
                  >
                    <rect width="8" height="27" stroke="none" />
                    <rect
                      x="0.75"
                      y="0.75"
                      width="6.5"
                      height="25.5"
                      fill="none"
                    />
                  </g>
                  <g
                    id="Rectángulo_3079"
                    data-name="Rectángulo 3079"
                    transform="translate(-16693 4586)"
                    fill="rgba(255,255,255,0)"
                    stroke="#000"
                    stroke-width="1.5"
                  >
                    <rect width="8" height="27" stroke="none" />
                    <rect
                      x="0.75"
                      y="0.75"
                      width="6.5"
                      height="25.5"
                      fill="none"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div v-if="!disableZoom" class="v360-menu-btns" @click="zoomIn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
            >
              <g
                id="Grupo_7327"
                data-name="Grupo 7327"
                transform="translate(16661 -4578)"
              >
                <rect
                  id="Rectángulo_3071"
                  data-name="Rectángulo 3071"
                  width="40"
                  height="40"
                  rx="5"
                  transform="translate(-16661 4578)"
                  fill="#f4f6f8"
                />
                <g id="zoom-in" transform="translate(-16655 4583)">
                  <path
                    id="Trazado_8197"
                    data-name="Trazado 8197"
                    d="M22.468,16.96H19.074V13.566a.566.566,0,0,0-1.131,0V16.96H14.549a.566.566,0,1,0,0,1.131h3.394v3.394a.566.566,0,0,0,1.131,0V18.091h3.394a.566.566,0,1,0,0-1.131Z"
                    transform="translate(-6.629 -5.646)"
                    stroke="#000"
                    stroke-width="0.5"
                  />
                  <path
                    id="Trazado_8198"
                    data-name="Trazado 8198"
                    d="M29.675,29l-8.405-8.741a11.889,11.889,0,1,0-.836.763l8.426,8.763A.566.566,0,1,0,29.675,29ZM2.114,11.879A10.748,10.748,0,1,1,12.862,22.627,10.76,10.76,0,0,1,2.114,11.879Z"
                    transform="translate(-0.983)"
                    stroke="#000"
                    stroke-width="0.5"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div v-if="!disableZoom" class="v360-menu-btns" @click="zoomOut">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
            >
              <g
                id="Grupo_7328"
                data-name="Grupo 7328"
                transform="translate(16606 -4578)"
              >
                <rect
                  id="Rectángulo_3073"
                  data-name="Rectángulo 3073"
                  width="40"
                  height="40"
                  rx="5"
                  transform="translate(-16606 4578)"
                  fill="#f4f6f8"
                />
                <path
                  id="Trazado_8198"
                  data-name="Trazado 8198"
                  d="M29.675,29l-8.405-8.741a11.889,11.889,0,1,0-.836.763l8.426,8.763A.566.566,0,1,0,29.675,29ZM2.114,11.879A10.748,10.748,0,1,1,12.862,22.627,10.76,10.76,0,0,1,2.114,11.879Z"
                  transform="translate(-16600.982 4583)"
                  stroke="#000"
                  stroke-width="0.5"
                />
                <g
                  id="Rectángulo_3068"
                  data-name="Rectángulo 3068"
                  transform="translate(-16592.646 4594.196)"
                  stroke="#000"
                  stroke-width="0.5"
                >
                  <rect width="9.051" height="1.138" rx="0.569" stroke="none" />
                  <rect
                    x="-0.25"
                    y="-0.25"
                    width="9.551"
                    height="1.638"
                    rx="0.819"
                    fill="none"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div
            class="v360-menu-btns"
            :class="panmode ? 'v360-btn-active' : ''"
            @click="togglePanMode"
          >
            <svg
              v-if="!panmode"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
            >
              <g
                id="Grupo_7330"
                data-name="Grupo 7330"
                transform="translate(16551 -4578)"
              >
                <rect
                  id="Rectángulo_3072"
                  data-name="Rectángulo 3072"
                  width="40"
                  height="40"
                  rx="5"
                  transform="translate(-16551 4578)"
                  fill="#f4f6f8"
                />
                <g
                  id="drag_1_"
                  data-name="drag(1)"
                  transform="translate(-16564.332 4585)"
                >
                  <g
                    id="Grupo_7324"
                    data-name="Grupo 7324"
                    transform="translate(21.333)"
                  >
                    <path
                      id="Trazado_8199"
                      data-name="Trazado 8199"
                      d="M41.535,3.108a3.1,3.1,0,0,0-1.728.525,3.108,3.108,0,0,0-4.662-1.554,3.11,3.11,0,0,0-5.868,0,3.107,3.107,0,0,0-4.836,2.583V8.225L22.926,9.739a5.406,5.406,0,0,0-1.593,3.846v2.354a5.409,5.409,0,0,0,1.593,3.848l3.11,3.108a11.948,11.948,0,0,0,8.507,3.523,10.113,10.113,0,0,0,10.1-10.1V6.216A3.111,3.111,0,0,0,41.535,3.108Zm1.554,13.209a8.557,8.557,0,0,1-8.549,8.547A10.4,10.4,0,0,1,27.134,21.8l-3.11-3.108a3.866,3.866,0,0,1-1.138-2.747V13.587a3.86,3.86,0,0,1,1.138-2.747l.417-.416v1.232a.777.777,0,1,0,1.554,0V4.662a1.554,1.554,0,0,1,3.108,0V6.993a.777.777,0,0,0,1.554,0V3.108a1.554,1.554,0,0,1,3.108,0V6.993a.777.777,0,1,0,1.554,0V4.662a1.554,1.554,0,0,1,3.108,0V6.993a.777.777,0,1,0,1.554,0V6.216a1.554,1.554,0,0,1,3.108,0Z"
                      transform="translate(-21.333)"
                    />
                  </g>
                </g>
              </g>
            </svg>
            <span v-else>360&deg;</span>
          </div>
          <div class="v360-menu-btns" @click="prev">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
            >
              <g
                id="Grupo_7331"
                data-name="Grupo 7331"
                transform="translate(16496 -4578)"
              >
                <rect
                  id="Rectángulo_3077"
                  data-name="Rectángulo 3077"
                  width="40"
                  height="40"
                  rx="5"
                  transform="translate(-16496 4578)"
                  fill="#f4f6f8"
                />
                <g id="Shopicon" transform="translate(-16485 4581)">
                  <g id="Grupo_5516" data-name="Grupo 5516">
                    <path
                      id="Trazado_4828"
                      data-name="Trazado 4828"
                      d="M33.414,8.706,18.121,24,33.414,39.292,32,40.706,15.293,24,32,7.292Z"
                      transform="translate(-15.293 -7.292)"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="v360-menu-btns" @click="next">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
            >
              <g
                id="Grupo_7332"
                data-name="Grupo 7332"
                transform="translate(16441 -4578)"
              >
                <rect
                  id="Rectángulo_3076"
                  data-name="Rectángulo 3076"
                  width="40"
                  height="40"
                  rx="5"
                  transform="translate(-16441 4578)"
                  fill="#f4f6f8"
                />
                <g id="Shopicon" transform="translate(-16430 4581)">
                  <g id="Grupo_5516" data-name="Grupo 5516">
                    <path
                      id="Trazado_4828"
                      data-name="Trazado 4828"
                      d="M15.293,8.706,30.586,24,15.293,39.292l1.414,1.414L33.414,24,16.707,7.292Z"
                      transform="translate(-15.293 -7.292)"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="v360-menu-btns" @click="resetPosition">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
            >
              <g
                id="Grupo_7333"
                data-name="Grupo 7333"
                transform="translate(16386 -4578)"
              >
                <rect
                  id="Rectángulo_3075"
                  data-name="Rectángulo 3075"
                  width="40"
                  height="40"
                  rx="5"
                  transform="translate(-16386 4578)"
                  fill="#f4f6f8"
                />
                <g id="Shopicon" transform="translate(-16390 4577.406)">
                  <path
                    id="Trazado_5185"
                    data-name="Trazado 5185"
                    d="M11,23v1A13,13,0,1,0,24,11H15.244l4.162-5.149L17.85,4.594,11.863,12l5.987,7.406,1.556-1.257L15.244,13H24A11,11,0,1,1,13,24V23Z"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <!--/ Buttons Container -->
    </div>
    <!--/ 360 Viewer Container -->
  </div>
</template>

<script>
/* eslint-disable */
const uuidv1 = require('uuid/v1')
export default {
  name: 'I360Viewer',
  props: {
    imagePath: {
      type: String,
      require: true,
      default: '',
    },
    fileName: {
      type: String,
      require: true,
      default: '',
    },
    spinReverse: {
      type: Boolean,
      require: true,
      default: false,
    },
    amount: {
      type: Number,
      require: true,
      default: 24,
    },
    autoplay: {
      type: Boolean,
      require: false,
      default: false,
    },
    loop: {
      type: Number,
      require: false,
      default: 1,
    },
    boxShadow: {
      type: Boolean,
      require: false,
      default: false,
    },
    buttonClass: {
      type: String,
      require: false,
      default: 'light',
    },
    hotspots: {
      type: Array,
      require: true,
      default: () => [],
    },
    identifier: {
      type: String,
      require: true,
      default: () => uuidv1(),
    },
    paddingIndex: {
      type: Boolean,
      require: false,
      default: false,
    },
    disableZoom: {
      type: Boolean,
      require: false,
      default: false,
    },
    scrollImage: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      minScale: 0.5,
      maxScale: 4,
      scale: 0.2,
      customOffset: 10,
      currentScale: 1.0,
      currentTopPosition: 0,
      currentLeftPosition: 0,
      selectMenuOption: 1,
      currentImage: null,
      dragging: false,
      canvas: null,
      ctx: null,
      dragStart: null,
      lastX: 0,
      lastY: 0,
      currentCanvasImage: null,
      isFullScreen: false,
      viewPortElementWidth: null,
      movementStart: 0,
      movement: false,
      dragSpeed: 150,
      speedFactor: 13,
      activeImage: 1,
      stopAtEdges: false,
      imagesLoaded: false,
      loadedImages: 0,
      centerX: 0,
      centerY: 0,
      panmode: false,
      isMobile: false,
      currentLoop: 0,
      loopTimeoutId: 0,
      images: [],
      imageData: [],
      playing: false,
      showView360: true,
    }
  },
  watch: {
    currentLeftPosition(value) {
      this.redraw()
    },
    currentTopPosition(value) {
      this.redraw()
    },
    viewPortElementWidth(value) {
      this.update()
    },
    panmode(value) {
      this.attachEvents()
    },
    isFullScreen(value) {
      if (!value) {
        //exit full screen
        this.$refs.viewerContainer.classList.remove('v360-main')
        this.$refs.viewerContainer.classList.remove('v360-fullscreen')
        /* this.$refs.enterFullScreenIcon.style.display = 'block'
                this.$refs.leaveFullScreenIcon.style.display = 'none' */
      } else {
        //enter full screen
        this.$refs.viewerContainer.classList.add('v360-main')
        this.$refs.viewerContainer.classList.add('v360-fullscreen')
        /* this.$refs.enterFullScreenIcon.style.display = 'none'
                this.$refs.leaveFullScreenIcon.style.display = 'block' */
      }
      this.setImage()
    },
    playing(value) {
      if (value) {
        this.play()
      } else {
        this.stop()
      }
    },
  },
  mounted() {
    //this.toggleFullScreen()
    this.fetchData()
    document.addEventListener('fullscreenchange', this.exitHandler)
    document.addEventListener('webkitfullscreenchange', this.exitHandler)
    document.addEventListener('mozfullscreenchange', this.exitHandler)
    document.addEventListener('MSFullscreenChange', this.exitHandler)
  },
  methods: {
    async clearView360() {
      await setTimeout(() => {
        this.showView360 = false
      }, 1000)
    },
    initData() {
      this.checkMobile()
      this.loadInitialImage()

      this.canvas = this.$refs.imageContainer
      this.ctx = this.canvas.getContext('2d')
      this.attachEvents()
      window.addEventListener('resize', this.resizeWindow)
      this.resizeWindow()
      this.playing = this.autoplay
    },
    fetchData() {
      for (let i = 1; i <= this.amount; i++) {
        const imageIndex = this.paddingIndex ? this.lpad(i, '0', 2) : i
        const fileName = this.fileName.replace('{index}', imageIndex)
        const filePath = `${this.imagePath}/${fileName}`
        this.imageData.push(filePath)
      }
      this.preloadImages()
    },
    lpad(str, padString, length) {
      str = str.toString()
      while (str.length < length) str = padString + str
      return str
    },
    preloadImages() {
      if (this.imageData.length) {
        try {
          this.amount = this.imageData.length
          this.imageData.forEach((src) => {
            this.addImage(src)
          })
        } catch (error) {
          console.error(
            `Something went wrong while loading images: ${error.message}`,
          )
        }
      } else {
        console.log('No Images Found')
      }
    },
    addImage(resultSrc) {
      const image = new Image()
      image.src = resultSrc
      // image.crossOrigin='anonymous'
      image.onload = this.onImageLoad.bind(this)
      image.onerror = this.onImageLoad.bind(this)
      this.images.push(image)
    },
    onImageLoad(event) {
      const percentage = Math.round((this.loadedImages / this.amount) * 100)
      this.loadedImages += 1
      this.updatePercentageInLoader(percentage)
      if (this.loadedImages === this.amount) {
        this.onAllImagesLoaded(event)
      } else if (this.loadedImages === 1) {
        //this.onFirstImageLoaded(event);
        console.log('load first image')
      }
    },
    updatePercentageInLoader(percentage) {
      /* if (this.loader) {
                this.loader.style.width = percentage + '%';
            }
            if (this.view360Icon) {
                this.view360Icon.innerText = percentage + '%';
            } */
      this.$refs.viewPercentage.innerHTML =
        (percentage ? percentage : '100') + '%'
      //console.log(percentage + '%')
    },
    onAllImagesLoaded(e) {
      this.imagesLoaded = true
      this.initData()
    },
    togglePlay() {
      this.playing = !this.playing
    },
    play() {
      this.loopTimeoutId = window.setInterval(() => this.loopImages(), 100)
    },
    onSpin() {
      if (this.playing || this.loopTimeoutId) {
        this.stop()
      }
    },
    stop() {
      if (this.activeImage == 1) {
        this.currentLoop = 0
      }
      this.playing = false
      window.clearTimeout(this.loopTimeoutId)
    },
    loopImages() {
      if (this.activeImage == 1) {
        if (this.currentLoop == this.loop) {
          this.stop()
        } else {
          this.currentLoop++

          this.next()
        }
      } else {
        this.next()
      }
    },
    next() {
      this.spinReverse ? this.turnLeft() : this.turnRight()
    },
    prev() {
      this.spinReverse ? this.turnRight() : this.turnLeft()
    },
    turnLeft() {
      this.moveActiveIndexDown(1)
    },
    turnRight() {
      this.moveActiveIndexUp(1)
    },
    loadImages() {
      console.log('load image')
    },
    checkMobile() {
      this.isMobile = !!('ontouchstart' in window || navigator.msMaxTouchPoints)
    },
    loadInitialImage() {
      this.currentImage = this.imageData[0]
      this.setImage()
    },
    resizeWindow() {
      this.setImage()
    },
    onPinch(evt) {
      console.log('on tap')
    },
    onPinchEnd(evt) {
      this.tempScale = 0
    },
    onPinchIn(evt) {
      //alert('pinchin:' + evt.scale)
      this.zoomOut()
    },
    onPinchOut(evt) {
      this.zoomIn()
    },
    attachEvents() {
      if (this.panmode) {
        this.bindPanModeEvents()
      } else {
        this.bind360ModeEvents()
      }
    },
    bindPanModeEvents() {
      this.$refs.viewport.removeEventListener('touchend', this.touchEnd)
      this.$refs.viewport.removeEventListener('touchstart', this.touchStart)
      this.$refs.viewport.removeEventListener('touchmove', this.touchMove)
      this.$refs.viewport.addEventListener('touchend', this.stopDragging)
      this.$refs.viewport.addEventListener('touchstart', this.startDragging)
      this.$refs.viewport.addEventListener('touchmove', this.doDragging)
      this.$refs.viewport.removeEventListener('mouseup', this.stopMoving)
      this.$refs.viewport.removeEventListener('mousedown', this.startMoving)
      this.$refs.viewport.removeEventListener('mousemove', this.doMoving)
      this.$refs.viewport.addEventListener('mouseup', this.stopDragging)
      this.$refs.viewport.addEventListener('mousedown', this.startDragging)
      this.$refs.viewport.addEventListener('mousemove', this.doDragging)
      this.$refs.viewport.addEventListener('wheel', this.onScroll)
    },
    bind360ModeEvents() {
      this.$refs.viewport.removeEventListener('touchend', this.stopDragging)
      this.$refs.viewport.removeEventListener('touchstart', this.startDragging)
      this.$refs.viewport.removeEventListener('touchmove', this.doDragging)
      this.$refs.viewport.addEventListener('touchend', this.touchEnd)
      this.$refs.viewport.addEventListener('touchstart', this.touchStart)
      this.$refs.viewport.addEventListener('touchmove', this.touchMove)
      this.$refs.viewport.removeEventListener('mouseup', this.stopDragging)
      this.$refs.viewport.removeEventListener('mousedown', this.startDragging)
      this.$refs.viewport.removeEventListener('mousemove', this.doDragging)

      this.$refs.viewport.addEventListener('mouseup', this.stopMoving)
      this.$refs.viewport.addEventListener('mousedown', this.startMoving)
      this.$refs.viewport.addEventListener('mousemove', this.doMoving)
      this.$refs.viewport.addEventListener('wheel', this.onScroll)
    },
    togglePanMode() {
      this.panmode = !this.panmode
    },
    zoomIn(evt) {
      if (this.disableZoom) return
      this.lastX = this.centerX
      this.lastY = this.centerY
      this.zoom(2)
    },
    zoomOut(evt) {
      if (this.disableZoom) return

      this.lastX = this.centerX
      this.lastY = this.centerY
      this.zoom(-2)
    },
    moveLeft() {
      this.currentLeftPosition += this.customOffset
    },
    moveRight() {
      this.currentLeftPosition -= this.customOffset
    },
    moveUp() {
      this.currentTopPosition += this.customOffset
    },
    moveDown() {
      this.currentTopPosition -= this.customOffset
    },
    resetPosition() {
      this.currentScale = 1
      this.activeImage = 1
      this.setImage(true)
    },
    setImage(cached = false) {
      this.currentLeftPosition = this.currentTopPosition = 0

      if (!cached) {
        this.currentCanvasImage = new Image()
        // this.currentCanvasImage.crossOrigin='anonymous'
        this.currentCanvasImage.src = this.currentImage
        this.currentCanvasImage.onload = () => {
          let viewportElement = this.$refs.viewport.getBoundingClientRect()
          this.canvas.width = this.isFullScreen
            ? viewportElement.width
            : this.currentCanvasImage.width
          this.canvas.height = this.isFullScreen
            ? viewportElement.height
            : this.currentCanvasImage.height
          this.trackTransforms(this.ctx)
          this.redraw()
        }
        this.currentCanvasImage.onerror = () => {
          console.log('cannot load this image')
        }
      } else {
        this.currentCanvasImage = this.images[0]
        let viewportElement = this.$refs.viewport.getBoundingClientRect()
        this.canvas.width = this.isFullScreen
          ? viewportElement.width
          : this.currentCanvasImage.width
        this.canvas.height = this.isFullScreen
          ? viewportElement.height
          : this.currentCanvasImage.height
        this.trackTransforms(this.ctx)
        this.redraw()
      }
    },
    redraw() {
      try {
        let p1 = this.ctx.transformedPoint(0, 0)
        let p2 = this.ctx.transformedPoint(
          this.canvas.width,
          this.canvas.height,
        )
        let hRatio = this.canvas.width / this.currentCanvasImage.width
        let vRatio = this.canvas.height / this.currentCanvasImage.height
        let ratio = Math.min(hRatio, vRatio)
        let centerShift_x =
          (this.canvas.width - this.currentCanvasImage.width * ratio) / 2
        let centerShift_y =
          (this.canvas.height - this.currentCanvasImage.height * ratio) / 2
        this.ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y)
        this.centerX = (this.currentCanvasImage.width * ratio) / 2
        this.centerY = (this.currentCanvasImage.height * ratio) / 2

        //center image
        this.ctx.drawImage(
          this.currentCanvasImage,
          this.currentLeftPosition,
          this.currentTopPosition,
          this.currentCanvasImage.width,
          this.currentCanvasImage.height,
          centerShift_x,
          centerShift_y,
          this.currentCanvasImage.width * ratio,
          this.currentCanvasImage.height * ratio,
        )
        this.addHotspots()
      } catch (e) {
        this.trackTransforms(this.ctx)
      }
    },
    addHotspots() {
      this.clearHotspots()
      let currentImageHotspots = this.hotspots.filter(
        (h) => h.frame == this.activeImage,
      )
      for (let c in currentImageHotspots) {
        let hotspotElement = currentImageHotspots[c]

        let hotspotPositionX, hotspotPositionY

        if (this.canvas.width > this.$refs.viewport.clientWidth) {
          /* hotspotPositionX = hotspotElement.x * this.$refs.viewport.clientWidth * this.currentScale
                    hotspotPositionY = hotspotElement.y * this.$refs.viewport.clientHeight * this.currentScale */
          hotspotPositionX = hotspotElement.x * this.$refs.viewport.clientWidth
          hotspotPositionY = hotspotElement.y * this.$refs.viewport.clientHeight
        } else {
          hotspotPositionX = hotspotElement.x * this.canvas.width
          hotspotPositionY = hotspotElement.y * this.canvas.height
        }

        let divElement = document.createElement('div')
        let spanElement = document.createElement('span')
        let imgElement = document.createElement('img')

        imgElement.className = 'hotspot-icon'
        imgElement.src = hotspotElement.icon
        spanElement.className = 'tooltiptext'
        spanElement.innerHTML = hotspotElement.text
        divElement.className = 'tooltip'
        divElement.style.left = hotspotPositionX + 'px'
        divElement.style.top = hotspotPositionY + 'px'
        divElement.appendChild(imgElement)
        divElement.appendChild(spanElement)
        imgElement.addEventListener('click', (e) => {
          e.preventDefault()
          console.log('show edit hotspot form')
          this.selectedHotspot = hotspotElement
          this.openHotspotForm(true)
        })
        if (hotspotElement.action) {
          console.log('add this function: ' + hotspotElement.action)
        }

        this.$refs.viewport.appendChild(divElement)
        //console.log('draw')
        //this.ctx.drawImage(this.currentCanvasImage, hotspotElement.x*this.canvas.width, hotspotElement.y*this.canvas.height, 10, 10)
      }
    },
    clearHotspots() {
      let hotspotButtons = document
        .getElementById(this.identifier)
        .querySelectorAll('.tooltip')

      if (hotspotButtons.length)
        hotspotButtons.forEach((element) => element.remove())
    },
    onMove(pageX) {
      if (pageX - this.movementStart >= this.speedFactor) {
        let itemsSkippedRight =
          Math.floor((pageX - this.movementStart) / this.speedFactor) || 1

        this.movementStart = pageX
        if (this.spinReverse) {
          this.moveActiveIndexDown(itemsSkippedRight)
        } else {
          this.moveActiveIndexUp(itemsSkippedRight)
        }
        this.redraw()
      } else if (this.movementStart - pageX >= this.speedFactor) {
        let itemsSkippedLeft =
          Math.floor((this.movementStart - pageX) / this.speedFactor) || 1

        this.movementStart = pageX
        if (this.spinReverse) {
          this.moveActiveIndexUp(itemsSkippedLeft)
        } else {
          this.moveActiveIndexDown(itemsSkippedLeft)
        }
        this.redraw()
      }
    },
    startMoving(evt) {
      this.movement = true
      this.movementStart = evt.pageX
      this.$refs.viewport.style.cursor = 'grabbing'
    },
    doMoving(evt) {
      if (this.movement) {
        this.onMove(evt.clientX)
      }
    },
    onScroll(evt) {
      evt.preventDefault()
      if (this.disableZoom || this.scrollImage) {
        if (evt.deltaY < 0) {
          this.moveActiveIndexDown(1)
        } else {
          this.moveActiveIndexUp(1)
        }
        this.onMove(evt.scrollTop)
      } else {
        this.zoomImage(evt)
      }
    },
    moveActiveIndexUp(itemsSkipped) {
      if (this.stopAtEdges) {
        if (this.activeImage + itemsSkipped >= this.amount) {
          this.activeImage = this.amount
        } else {
          this.activeImage += itemsSkipped
        }
      } else {
        this.activeImage =
          (this.activeImage + itemsSkipped) % this.amount || this.amount
      }

      this.update()
    },
    moveActiveIndexDown(itemsSkipped) {
      if (this.stopAtEdges) {
        if (this.activeImage - itemsSkipped <= 1) {
          this.activeImage = 1
        } else {
          this.activeImage -= itemsSkipped
        }
      } else {
        if (this.activeImage - itemsSkipped < 1) {
          this.activeImage = this.amount + (this.activeImage - itemsSkipped)
        } else {
          this.activeImage -= itemsSkipped
        }
      }

      this.update()
    },
    update() {
      const image = this.images[this.activeImage - 1]
      this.currentCanvasImage = image
      this.redraw()
    },
    stopMoving(evt) {
      this.movement = false
      this.movementStart = 0
      this.$refs.viewport.style.cursor = 'grab'
    },
    touchStart(evt) {
      this.movementStart = evt.touches[0].clientX
    },
    touchMove(evt) {
      this.onMove(evt.touches[0].clientX)
    },
    touchEnd() {
      this.movementStart = 0
    },
    startDragging(evt) {
      this.dragging = true
      document.body.style.mozUserSelect =
        document.body.style.webkitUserSelect =
        document.body.style.userSelect =
          'none'
      if (this.isMobile) {
        this.lastX =
          evt.touches[0].offsetX ||
          evt.touches[0].pageX - this.canvas.offsetLeft
        this.lastY =
          evt.touches[0].offsetY || evt.touches[0].pageY - this.canvas.offsetTop
      } else {
        this.lastX = evt.offsetX || evt.pageX - this.canvas.offsetLeft
        this.lastY = evt.offsetY || evt.pageY - this.canvas.offsetTop
      }

      this.dragStart = this.ctx.transformedPoint(this.lastX, this.lastY)
    },
    doDragging(evt) {
      if (this.isMobile) {
        this.lastX =
          evt.touches[0].offsetX ||
          evt.touches[0].pageX - this.canvas.offsetLeft
        this.lastY =
          evt.touches[0].offsetY || evt.touches[0].pageY - this.canvas.offsetTop
      } else {
        this.lastX = evt.offsetX || evt.pageX - this.canvas.offsetLeft
        this.lastY = evt.offsetY || evt.pageY - this.canvas.offsetTop
      }

      if (this.dragStart) {
        let pt = this.ctx.transformedPoint(this.lastX, this.lastY)
        this.ctx.translate(pt.x - this.dragStart.x, pt.y - this.dragStart.y)
        //redraw();
        this.redraw()
      }
    },
    stopDragging(evt) {
      this.dragging = false
      this.dragStart = null
    },
    restrictScale() {
      let scale = this.currentScale
      if (scale < this.minScale) {
        scale = this.minScale
      } else if (scale > this.maxScale) {
        scale = this.maxScale
      }
      return scale
    },
    zoom(clicks) {
      //console.log(this.lastX + ' - ' + this.lastY)
      let factor = Math.pow(1.01, clicks)
      //console.log(factor)
      if (factor > 1) {
        this.currentScale += factor
      } else {
        if (this.currentScale - factor > 1) this.currentScale -= factor
        else this.currentScale = 1
      }

      if (this.currentScale > 1) {
        let pt = this.ctx.transformedPoint(this.lastX, this.lastY)
        this.ctx.translate(pt.x, pt.y)

        //console.log(this.currentScale)
        this.ctx.scale(factor, factor)
        this.ctx.translate(-pt.x, -pt.y)
        this.redraw()
      }
    },
    zoomImage(evt) {
      if (this.disableZoom) return
      this.lastX = evt.offsetX || evt.pageX - this.canvas.offsetLeft
      this.lastY = evt.offsetY || evt.pageY - this.canvas.offsetTop

      var delta = evt.wheelDelta
        ? evt.wheelDelta / 40
        : evt.deltaY
        ? -evt.deltaY
        : 0

      if (delta) this.zoom(delta)
      return evt.preventDefault() && false
    },
    trackTransforms(ctx) {
      return new Promise((resolve) => {
        var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
        var xform = svg.createSVGMatrix()
        this.ctx.getTransform = function () {
          return xform
        }

        var savedTransforms = []
        var save = ctx.save
        this.ctx.save = () => {
          savedTransforms.push(xform.translate(0, 0))
          return save.call(this.ctx)
        }
        var restore = ctx.restore
        this.ctx.restore = () => {
          xform = savedTransforms.pop()
          return restore.call(this.ctx)
        }
        var scale = this.ctx.scale
        this.ctx.scale = (sx, sy) => {
          xform = xform.scaleNonUniform(sx, sy)
          return scale.call(this.ctx, sx, sy)
        }
        var rotate = this.ctx.rotate
        this.ctx.rotate = (radians) => {
          xform = xform.rotate((radians * 180) / Math.PI)
          return rotate.call(this.ctx, radians)
        }
        var translate = this.ctx.translate
        this.ctx.translate = (dx, dy) => {
          xform = xform.translate(dx, dy)
          return translate.call(this.ctx, dx, dy)
        }
        var transform = this.ctx.transform
        this.ctx.transform = (a, b, c, d, e, f) => {
          var m2 = svg.createSVGMatrix()
          m2.a = a
          m2.b = b
          m2.c = c
          m2.d = d
          m2.e = e
          m2.f = f
          xform = xform.multiply(m2)
          return transform.call(this.ctx, a, b, c, d, e, f)
        }
        var setTransform = this.ctx.setTransform
        this.ctx.setTransform = (a, b, c, d, e, f) => {
          xform.a = a
          xform.b = b
          xform.c = c
          xform.d = d
          xform.e = e
          xform.f = f
          return setTransform.call(this.ctx, a, b, c, d, e, f)
        }
        var pt = svg.createSVGPoint()
        this.ctx.transformedPoint = (x, y) => {
          pt.x = x
          pt.y = y
          return pt.matrixTransform(xform.inverse())
        }
        resolve(this.ctx)
      })
    },
    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen
    },
  },
}
</script>
<style lang="scss">
.v360-menu-btns {
  padding: 0;
  &:hover {
    background-color: transparent !important;
    color: black !important;
  }
}
.v360-viewport-loader {
  align-items: center;
  .v360-percentage-text {
    font-size: 20px;
  }
}
</style>
