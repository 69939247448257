<template>
  <div class="conatiner-product-page">
    <div>
      <grid-product />
      <modal-regalo />
    </div>
    <div v-if="!productDetail">
      <skeleton-product-desktop />
      <skeleton-product-movil />
    </div>
  </div>
</template>
<script>
import GridProduct from '@/components/productDetail/productPage/GridProduct'
import SkeletonProductDesktop from '@/components/productDetail/productPage/SkeletonProductDesktop'
import SkeletonProductMovil from '@/components/productDetail/productPage/SkeletonProductMovil'
import ModalRegalo from '@/components/Templates/ModalRegalo'

import { mapActions, mapState } from 'vuex'
import { sendFBQEvent } from '@/fbq'
import { GA4SelectItem, GA4ViewItem } from '@/GA4/events'
export default {
  components: {
    GridProduct,
    ModalRegalo,
    SkeletonProductDesktop,
    SkeletonProductMovil,
  },
  computed: {
    ...mapState('products', ['productDetail', 'vitrinaRelacionados']),
  },
  watch: {
    async $route(to, from) {
      if (to.path != from.path) {
        document.title = this.$route.params.url.split('-').toString()
        await this.getProduct({ url: 'product-' + this.$route.params.url })
        this.getRelacionados({ sku: this.$route.params.url.split('-')[0] })
      }
    },
    productDetail() {
      if (this.productDetail) {
        document.title = this.productDetail.titulo
        if (this.$route.params.vitrinaName) {
          GA4SelectItem(
            this.$route.params.vitrinaId,
            this.$route.params.vitrinaName,
          )
        }
        GA4ViewItem()
        sendFBQEvent(this, 'ViewContent', {
          value: this.productDetail.precio,
          currency: 'COP',
          product_id: this.productDetail.producto_sku,
          content_type: 'product_group',
          content_ids: '[' + this.productDetail.producto_sku + ']',
        })
      }
    },
  },
  async mounted() {
    document.title = this.$route.params.url.replaceAll('-', ' ')
    if (this.$route.params.url) {
      this.getRelacionados({ sku: this.$route.params.url.split('-')[0] })
      await this.getProduct({ url: 'product-' + this.$route.params.url })
    }
  },
  methods: {
    ...mapActions('products', ['getProduct', 'getRelacionados']),
  },
}
</script>
<style lang="scss">
.conatiner-product-page {
  margin-right: 1rem;
  margin-left: 1rem;
}
</style>
