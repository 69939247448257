var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"store-clover"},[(_vm.infoCatalogo)?_c('div',{staticClass:"header-store"},[_c('h1',[_vm._v(" "+_vm._s(_vm.infoCatalogo.titulo ?.toLowerCase() .replace(/^\w/, d => d.toUpperCase()) ?? '')+" ")]),_c('p',[_vm._v(_vm._s(_vm.infoCatalogo.descripcion))])]):_c('skeleton-title-store',{staticClass:"header-store"}),(_vm.productsCategory)?_c('filters-store',{key:_vm.gridStore + 'grid-store',attrs:{"grid-store":_vm.gridStore},on:{"changeGrid":function($event){_vm.gridStore = $event}}}):_c('skeleton-filter'),(_vm.estrategiaCatlogo)?_c('div',{key:_vm.gridStore + 'grid-store-w'},[_c('div',{staticClass:"content-products-store"},[_vm._l((_vm.productsCategory.data.slice(
          0,
          _vm.gridStore - 1,
        )),function(product,index){return _c('product-card',{key:index + _vm.gridStore + 'item_card_1',attrs:{"id":`product-${product.producto_sku}-${product.color}`,"grid-store":_vm.gridStore,"index":index,"data-product":product,"vitrinaid":`store-clover`,"vitrinaname":`store clover ${_vm.infoCatalogo.titulo}`}})}),_c('card-estrategia-card',{attrs:{"id":"estrategia-store","grid-store":_vm.gridStore}}),_vm._l((_vm.productsCategory.data.slice(
          _vm.gridStore - 1,
          _vm.productGrid - 1,
        )),function(product,index){return _c('product-card',{key:index + _vm.gridStore + 'item_card_2',attrs:{"id":`product-${product.producto_sku}-${product.color}`,"grid-store":_vm.gridStore,"index":index,"data-product":product,"vitrinaid":`store-clover`,"vitrinaname":`store clover ${_vm.infoCatalogo.titulo}`}})})],2),_c('breakin-cupo',{staticClass:"mb-5"}),_c('div',{staticClass:"content-products-store"},_vm._l((_vm.productsCategory.data.slice(
          _vm.productGrid - 1,
          _vm.productsCategory.length,
        )),function(product,index){return _c('product-card',{key:index + _vm.gridStore + 'item_card_3',attrs:{"id":`product-${product.producto_sku}-${product.color}`,"grid-store":_vm.gridStore,"index":index,"data-product":product,"vitrinaid":`store-clover`,"vitrinaname":`store clover ${_vm.infoCatalogo.titulo}`}})}),1)],1):_vm._e(),(_vm.productsCategory && !_vm.estrategiaCatlogo)?_c('div',{key:_vm.gridStore + '_estrategia'},[_c('div',{staticClass:"content-products-store"},_vm._l((_vm.productsCategory.data.slice(
          0,
          _vm.productGrid,
        )),function(product,index){return _c('product-card',{key:index + _vm.gridStore + 'item_card_4',attrs:{"id":`product-${product.producto_sku}-${product.color}`,"grid-store":_vm.gridStore,"index":index,"data-product":product,"vitrinaid":`store-clover`,"vitrinaname":`store clover ${_vm.infoCatalogo.titulo}`}})}),1),_c('breakin-cupo',{staticClass:"mb-5"}),_c('div',{staticClass:"content-products-store"},_vm._l((_vm.productsCategory.data.slice(
          _vm.productGrid,
          _vm.productsCategory.length,
        )),function(product,index){return _c('product-card',{key:index + _vm.gridStore + 'item_card_5',attrs:{"id":`product-${product.producto_sku}-${product.color}`,"grid-store":_vm.gridStore,"index":index,"data-product":product,"vitrinaid":`store-clover`,"vitrinaname":`store clover ${_vm.infoCatalogo.titulo}`}})}),1)],1):_vm._e(),(!_vm.productsCategory)?_c('div',{staticClass:"w-100"},[_c('skeleton-store',{attrs:{"grid-store":_vm.gridStore}})],1):_vm._e(),(_vm.productsCategory && _vm.productsCategory.last_page > 1)?_c('b-pagination',{staticClass:"pagination-comment",attrs:{"total-rows":_vm.productsCategory.total,"per-page":_vm.productsCategory.per_page,"aria-controls":"products-table","align":"center","hide-goto-end-buttons":true},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),_c('info-clover'),_c('modal-news-latter')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }