<template>
  <div
    class="container-product-bag"
    :class="product.status != 1 ? 'content-product-aviable' : ''"
  >
    <div
      v-if="product.producto_aplica_estrategia == 1"
      class="product-aplica-estrategia"
    >
      <svg
        id="Shopicons_Light_Gift"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          id="Trazado_5027"
          data-name="Trazado 5027"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <g id="Shopicon" transform="translate(1.983 2.184)">
          <path
            id="Trazado_5028"
            data-name="Trazado 5028"
            d="M21.965,8.368H19.791a3,3,0,1,0-5.651,0h-.349a3,3,0,1,0-5.651,0H5.965a2.006,2.006,0,0,0-2,2v12a2.006,2.006,0,0,0,2,2h16a2.006,2.006,0,0,0,2-2v-12A2.006,2.006,0,0,0,21.965,8.368Zm-5-3a2,2,0,1,1-2,2A2,2,0,0,1,16.965,5.368Zm-6,0a2,2,0,1,1-2,2A2,2,0,0,1,10.965,5.368Zm-6,5a1,1,0,0,1,1-1H8.734a2.99,2.99,0,0,0,4.463,0h.061l-4,4,.707.707,3.5-3.5v5.793h-8.5v-6Zm0,12v-5h8.5v6h-7.5A1,1,0,0,1,4.965,22.368Zm18,0a1,1,0,0,1-1,1h-7.5v-6h8.5v-1h-8.5V10.575l3.5,3.5.707-.707-4-4h.061a2.99,2.99,0,0,0,4.463,0h2.768a1,1,0,0,1,1,1Z"
            transform="translate(-3.965 -4.368)"
          />
        </g>
      </svg>
    </div>
    <router-link
      :to="{
        name: 'ProductDetail',
        params: { url: product.url.replace('product-', '') },
      }"
    >
      <img
        :src="product.referencia.imagenes[0]"
        style="aspect-ratio: 168/190"
      />
    </router-link>
    <div class="container-data-product-bag">
      <p class="my-2 title-product">
        <router-link
          :to="{
            name: 'ProductDetail',
            params: { url: product.url.replace('product-', '') },
          }"
          >{{
            product.producto_titulo
              .toLowerCase()
              .replace(/^\w/, d => d.toUpperCase())
          }}</router-link
        ><br class="d-none d-md-flex" />
      </p>
      <p v-if="product.status != 1" class="mb-0">
        <span class="w-100 product-aviable fw-bold"
          >Este producto está agotado</span
        >
      </p>
      <span class="detail-sku-color"
        >SKU: {{ product.producto_sku }} - Color:
        {{ product.referencia.color_nombre }} - Talla:
        {{ product.referencia.talla.name }}
      </span>
      <p
        class="my-2 fw-bold"
        :class="parseInt(product.precio_antes) > 0 ? 'mb-3' : ''"
      >
        ${{ new Intl.NumberFormat('de-DE').format(product.precio) }}
        <span v-if="parseInt(product.precio_antes) > 0">-</span>
        <del
          v-if="parseInt(product.precio_antes) > 0"
          class="fw-bold"
          style="color: #959595"
        >
          ${{
            new Intl.NumberFormat('de-DE').format(product.precio_antes)
          }}</del
        >
      </p>
      <div class="d-flex flex-wrap">
        <div
          v-if="product.producto_aplica_estrategia == 0"
          class="input-product-bag"
        >
          <span class="fw-bold">{{ product.countProduct }}</span>
          <div class="container-actions-input">
            <div class="w-100 d-flex">
              <button class="one-more" @click="addProduct()"></button>
            </div>
            <div class="w-100 d-flex">
              <button class="one-less" @click="deleteOneProduct()"></button>
            </div>
          </div>
        </div>
        <!-- <div class="nota-regalo d-none d-md-flex">
                  <svg id="Shopicon" xmlns="http://www.w3.org/2000/svg" style="width: 18px; min-width: 18px;" width="18.451" height="18.451" viewBox="0 0 18.451 18.451">
                      <path id="Trazado_5028" data-name="Trazado 5028" d="M20.571,8.058H18.565a2.768,2.768,0,1,0-5.213,0h-.322a2.768,2.768,0,1,0-5.213,0H5.81A1.851,1.851,0,0,0,3.965,9.9V20.974A1.851,1.851,0,0,0,5.81,22.819H20.571a1.851,1.851,0,0,0,1.845-1.845V9.9A1.851,1.851,0,0,0,20.571,8.058ZM15.958,5.291a1.845,1.845,0,1,1-1.845,1.845A1.847,1.847,0,0,1,15.958,5.291Zm-5.535,0A1.845,1.845,0,1,1,8.578,7.136,1.847,1.847,0,0,1,10.423,5.291ZM4.888,9.9a.923.923,0,0,1,.923-.923H8.364a2.758,2.758,0,0,0,4.117,0h.057l-3.69,3.69.652.652,3.229-3.229v5.344H4.888V9.9Zm0,11.071V16.361h7.842V21.9H5.81A.923.923,0,0,1,4.888,20.974Zm16.606,0a.923.923,0,0,1-.923.923H13.652V16.361h7.842v-.923H13.652V10.094l3.229,3.229.652-.652-3.69-3.69H13.9a2.758,2.758,0,0,0,4.117,0h2.554a.923.923,0,0,1,.923.923Z" transform="translate(-3.965 -4.368)"/>
                  </svg>
                  <p class="mb-0 ps-2 pe-3">¡Este producto tiene una nota de regalo!</p>
                  <span>Editar</span>
              </div> -->
      </div>
      <span class="delete-product-bag" @click="showDelete = true"
        >Eliminar</span
      >
    </div>
    <!-- <div class="nota-regalo mx-auto d-flex d-md-none">
          <svg id="Shopicon" xmlns="http://www.w3.org/2000/svg" style="width: 18px; min-width: 18px;" width="18.451" height="18.451" viewBox="0 0 18.451 18.451">
              <path id="Trazado_5028" data-name="Trazado 5028" d="M20.571,8.058H18.565a2.768,2.768,0,1,0-5.213,0h-.322a2.768,2.768,0,1,0-5.213,0H5.81A1.851,1.851,0,0,0,3.965,9.9V20.974A1.851,1.851,0,0,0,5.81,22.819H20.571a1.851,1.851,0,0,0,1.845-1.845V9.9A1.851,1.851,0,0,0,20.571,8.058ZM15.958,5.291a1.845,1.845,0,1,1-1.845,1.845A1.847,1.847,0,0,1,15.958,5.291Zm-5.535,0A1.845,1.845,0,1,1,8.578,7.136,1.847,1.847,0,0,1,10.423,5.291ZM4.888,9.9a.923.923,0,0,1,.923-.923H8.364a2.758,2.758,0,0,0,4.117,0h.057l-3.69,3.69.652.652,3.229-3.229v5.344H4.888V9.9Zm0,11.071V16.361h7.842V21.9H5.81A.923.923,0,0,1,4.888,20.974Zm16.606,0a.923.923,0,0,1-.923.923H13.652V16.361h7.842v-.923H13.652V10.094l3.229,3.229.652-.652-3.69-3.69H13.9a2.758,2.758,0,0,0,4.117,0h2.554a.923.923,0,0,1,.923.923Z" transform="translate(-3.965 -4.368)"/>
          </svg>
          <p class="mb-0 px-3">¡Este producto tiene una nota de regalo!</p>
          <span>Editar</span>
      </div> -->
    <div
      class="delete-product-bag-buttons"
      :style="showDelete ? 'top: 0%;' : 'top: -110%;'"
    >
      <div class="w-100 d-flex justify-content-end">
        <span class="delete-product-bag" @click="showDelete = false"
          >Cancelar</span
        >
      </div>
      <div class="container-delete-product">
        <p>¿Estás seguro de eliminar este producto?</p>
        <div>
          <button class="bnt-outline" @click="deleProduct()">
            Eliminar producto
          </button>
          <button class="bnt-primary fw-bold" @click="showDelete = false">
            Conservar producto
          </button>
        </div>
      </div>
    </div>
    <!-- <router-link class="product-aviable-link d-flex d-md-none mx-auto my-3" :to="{name: 'StoreCollection', params: {collection: 'bolsos'},}">Ver sugerencias</router-link> -->
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { GA4DelToCart } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'
export default {
  name: 'CardProductBag',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cant: 1,
      showDelete: false,
    }
  },
  methods: {
    ...mapActions('cart', ['deleteProductTocart', 'addProductTocart']),
    async deleProduct() {
      let data = {
        idProduct: this.product.id_carrito_producto,
        tipo: 1,
      }
      let tempProduct = this.product
      await this.deleteProductTocart(data).then(() => {
        this.showDelete = false
        let productGA4 = {
          item_name: tempProduct.producto_titulo,
          item_id: tempProduct.producto_sku,
          price: parseInt(tempProduct.precio),
          item_brand: tempProduct.marca,
          affiliation: 'Clover',
          item_category: tempProduct.categoria,
          item_category2: tempProduct.subcategoria,
          promotion_name: tempProduct.descuento ? tempProduct.descuento : '',
          discount: parseInt(tempProduct.precio_antes)
            ? parseInt(tempProduct.precio_antes) - parseInt(tempProduct.precio)
            : 0,
          item_variant: tempProduct.referencia.color_id,
          quantity: tempProduct.countProduct,
          currency: 'COP',
        }
        GA4DelToCart(productGA4)
      })
    },
    async deleteOneProduct() {
      let data = {
        idProduct: this.product.id_carrito_producto,
        tipo: 0,
      }
      let tempProduct = this.product
      await this.deleteProductTocart(data).then(() => {
        let productGA4 = {
          item_name: tempProduct.producto_titulo,
          item_id: tempProduct.producto_sku,
          price: parseInt(tempProduct.precio),
          item_brand: tempProduct.marca,
          affiliation: 'Clover',
          item_category: tempProduct.categoria,
          item_category2: tempProduct.subcategoria,
          promotion_name: tempProduct.descuento ? tempProduct.descuento : '',
          discount: parseInt(tempProduct.precio_antes)
            ? parseInt(tempProduct.precio_antes) - parseInt(tempProduct.precio)
            : 0,
          item_variant: tempProduct.referencia.color_id,
          quantity: 1,
          currency: 'COP',
        }
        GA4DelToCart(productGA4)
      })
    },
    async addProduct() {
      let data = {
        sku: this.product.producto_sku,
        color: this.product.referencia.color_id,
        tallaId: this.product.referencia.talla_id,
      }
      await this.addProductTocart(data).then(() => {
        sendFBQEvent(this, 'AddToCart', {
          product_id: this.product.producto_sku,
          value: this.product.precio,
          content_type: 'product',
          content_name: this.product.categoria_slug.toUpperCase() + ' CLOVER',
          content_category: this.product.categoria_slug.toUpperCase(),
          content_ids:
            this.product.producto_sku +
            this.product.referencia.color_id +
            this.product.referencia.talla_id,
          currency: 'COP',
        })
      })
    },
  },
}
</script>
