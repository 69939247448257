<template>
  <div class="__content-product-outstanding">
    <div class="__product-outstanding">
      <div class="col-12 col-lg-6 d-flex justify-content-end mb-4 mb-lg-0">
        <router-link
          :to="{
            name: 'ProductDetail',
            params: {
              url: dataProduct.url.replace('product-', ''),
              vitrinaId: 'producto-destacado',
              vitrinaName: 'Producto destadaco home',
            },
            query: { color: dataProduct.color },
          }"
          class="ms-auto"
        >
          <img
            :src="dataProduct.imagen"
            style="width: 100%; max-width: 610px"
          />
        </router-link>
      </div>
      <div
        class="col-12 col-lg-6 d-flex flex-wrap align-content-center px-lg-5"
      >
        <h2 class="title-outstanding" v-html="dataProduct.producto_titulo"></h2>
        <p class="price-outstanding">
          ${{
            new Intl.NumberFormat('de-DE').format(dataProduct.producto_precio)
          }}
        </p>
        <p class="description-outstanding" v-html="dataProduct.descripcion"></p>
        <div class="d-flex">
          <router-link
            :to="{
              name: 'ProductDetail',
              params: {
                url: dataProduct.url.replace('product-', ''),
                vitrinaId: 'producto-destacado',
                vitrinaName: 'Producto destadaco home',
              },
              query: { color: dataProduct.color },
            }"
            class="w-100"
          >
            <button class="btn-primary-clover px-5">Ver detalles</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataProduct: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {}
  },
}
</script>
<style lang="scss">
.__content-product-outstanding {
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 3rem;
  background: linear-gradient(
    0deg,
    #ffffff 25%,
    #fbfbff 25%,
    #fbfbff 83%,
    #ffffff 83%
  );
  .__product-outstanding {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .title-outstanding {
    width: 100%;
    font-size: 32px;
    margin-bottom: 1.5rem;
  }
  .price-outstanding {
    width: 100%;
    font-size: 24px;
    margin-bottom: 1.5rem;
  }
  .description-outstanding {
    width: 100%;
    color: #818181;
    margin-bottom: 1.5rem;
  }
}
</style>
