<template>
  <div
    class="container-size-guide"
    :class="$store.state.products.showSizeProduct ? 'show-size-guide' : ''"
  >
    <div class="d-flex p-4">
      <img
        src="@/assets/icons/close-modal.svg"
        style="cursor: pointer"
        class="ms-auto"
        @click="
          $store.state.products.showSizeProduct = !$store.state.products
            .showSizeProduct
        "
      />
    </div>
    <h3 class="mb-4 px-5">¿CÓMO MEDIR MI TALLA?</h3>
    <ul class="px-5">
      <li><b>1.</b> Busto, rodea la parte con más volumen.</li>
      <li><b>2.</b> Cintura, rodea la parte más estrecha.</li>
    </ul>
    <div class="table-sizes-product-detail">
      <b-table striped hover borderless :items="sizes"></b-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sizes: [
        {
          talla: 'XS',
          'Busto (cm)': '89-91',
          'Cintura (cm)': '67-69',
          'Cadera (cm)': '92-94',
        },
        {
          talla: 'S',
          'Busto (cm)': '93-95',
          'Cintura (cm)': '71-73',
          'Cadera (cm)': '96-98',
        },
        {
          talla: 'M',
          'Busto (cm)': '97-99',
          'Cintura (cm)': '75-77',
          'Cadera (cm)': '100-102',
        },
        {
          talla: 'L',
          'Busto (cm)': '101-103',
          'Cintura (cm)': '79-81',
          'Cadera (cm)': '104-106',
        },
      ],
    }
  },
}
</script>
<style lang="scss">
.container-size-guide {
  position: fixed;
  max-width: 450px;
  width: 100%;
  right: -100%;
  top: 0;
  z-index: 100;
  background-color: #f4f6f8;
  height: 100vh;
  transition: all 0.5s linear;
  ul {
    list-style: none;
    margin-bottom: 3rem;
  }
  .table-sizes-product-detail {
    text-align: center;
    background-color: rgb(236, 228, 233);
    padding-top: 1.2rem;
    padding-bottom: 1rem;
  }
  &.show-size-guide {
    right: 0 !important;
  }
}
</style>
