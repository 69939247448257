var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skeleton-home"},[_c('div',{staticClass:"d-none d-md-block mb-4"},[_c('b-skeleton-img',{attrs:{"aspect":"1920:543"}})],1),_c('div',{staticClass:"d-block d-md-none mb-4"},[_c('b-skeleton-img',{attrs:{"aspect":"430:645"}})],1),_c('b-skeleton',{staticClass:"mt-5 mb-4 title-category"}),_c('div',{staticClass:"content-skeleton-category mb-5"},[_c('b-skeleton',{staticClass:"category-skeleton category-left"}),_c('div',{staticClass:"category-skeleton",staticStyle:{"aspect-ratio":"28/31"}},[_c('b-skeleton',{staticClass:"mb-auto category-middle"}),_c('b-skeleton',{staticClass:"mt-auto category-middle"})],1),_c('b-skeleton',{staticClass:"category-skeleton category-right"})],1),_c('skeleton-carousel'),_c('skeleton-rompe-nav'),_c('skeleton-carousel'),_c('div',{staticClass:"skeleton-product-outstanding"},[_c('div',{staticClass:"col-12 col-lg-6 mb-4 mb-lg-0"},[_c('b-skeleton-img',{attrs:{"aspect":"961:1088"}})],1),_c('div',{staticClass:"col-12 col-lg-6 ps-lg-5"},[_c('b-skeleton',{staticClass:"title-product-outstanding"}),_c('b-skeleton',{staticClass:"mb-4 w-75 title-product-outstanding"}),_c('b-skeleton',{staticClass:"mb-4 price-product-outstanding"}),_c('b-skeleton',{}),_c('b-skeleton',{}),_c('b-skeleton',{staticClass:"mb-4"}),_c('b-skeleton',{attrs:{"width":"200px","height":"50px"}})],1)]),_c('div',{staticClass:"container-carousel-collections-skeleton"},[_c('b-skeleton',{staticClass:"mb-5 title-category"}),_c('carousel',{staticClass:"content-carousel-collections-skeleton",attrs:{"dots":false,"nav":false,"responsive":{
        0: { items: 1, nav: false, stagePadding: 0 },
        500: { items: 2, nav: false, stagePadding: 0 },
        768: { items: 2, nav: false, stagePadding: 0 },
        991: { items: 4, nav: false, stagePadding: 0 },
        1500: { items: 5, nav: false, stagePadding: 0 },
      }}},[_c('template',{slot:"prev"},[_c('span',{staticClass:"prev nav-button"},[_c('img',{attrs:{"src":require("@/assets/icons/left-carousel.svg"),"alt":"left arrow carousel"}})])]),_c('template',{slot:"next"},[_c('span',{staticClass:"next nav-button"},[_c('img',{attrs:{"src":require("@/assets/icons/right-carousel.svg"),"alt":"right arrow carousel"}})])]),_vm._l((12),function(index){return _c('div',{key:index,staticClass:"item-collections-carousel-skeleton"},[_c('b-skeleton-img',{attrs:{"aspect":"260:330"}})],1)})],2)],1),_c('div',{staticClass:"content-category-bolsos"},[_c('b-skeleton',{staticClass:"mb-4 title-category"}),_c('div',{staticClass:"category-bolsos-carousels"},[_c('carousel',{staticClass:"mb-5",attrs:{"dots":false,"nav":false,"auto-width":true}},_vm._l((12),function(index){return _c('div',{key:index,staticClass:"item-collections-carousel-skeleton"},[_c('b-skeleton',{staticClass:"mx-3",attrs:{"width":"150px","height":"50px"}})],1)}),0),_c('carousel',{staticClass:"content-carousel-bolsos-skeleton",attrs:{"dots":false,"nav":false,"responsive":{
          0: { items: 1, nav: false, stagePadding: 0 },
          500: { items: 2, nav: false, stagePadding: 0 },
          768: { items: 2, nav: false, stagePadding: 0 },
          991: { items: 4, nav: false, stagePadding: 0 },
          1500: { items: 5, nav: false, stagePadding: 0 },
        }}},[_c('template',{slot:"prev"},[_c('span',{staticClass:"prev nav-button"},[_c('img',{attrs:{"src":require("@/assets/icons/left-carousel.svg"),"alt":"left arrow carousel"}})])]),_c('template',{slot:"next"},[_c('span',{staticClass:"next nav-button"},[_c('img',{attrs:{"src":require("@/assets/icons/right-carousel.svg"),"alt":"right arrow carousel"}})])]),_vm._l((12),function(index){return _c('skeleton-card-product',{key:index})})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }