<template>
  <div v-if="data.length > 0" class="__container-bolsos">
    <div class="__content-bolsos">
      <div>
        <h2 class="mb-4 text-center">Tipos de bolsos</h2>
        <carousel
          class="content-section-bolsos"
          :dots="false"
          :nav="false"
          :auto-width="true"
        >
          <button
            v-for="(item, index) in data"
            :key="index"
            :class="categoryBolsoSelect == item.slug ? 'select-btn' : ''"
            class="button-bolso"
            @click="categoryBolsoSelect = item.slug"
          >
            {{ item.descripcion }}
          </button>
        </carousel>
      </div>
      <carousel
        id="carousel-bolsos-home"
        :key="'updateBolsos...' + categoryBolsoSelect"
        :dots="false"
        :nav="false"
        :responsive="{
          0: { items: 1, nav: false, stagePadding: 0 },
          768: { items: 3, nav: false, stagePadding: 60 },
          1500: { items: 4, nav: false, stagePadding: 30 },
          1900: { items: 5, nav: false, stagePadding: 15 },
        }"
        class="content-carousel-bolsos"
      >
        <template slot="prev"
          ><span class="prev nav-button"
            ><img
              src="@/assets/icons/left-carousel.svg"
              alt="left arrow carousel"/></span
        ></template>
        <template slot="next"
          ><span class="next nav-button"
            ><img
              src="@/assets/icons/right-carousel.svg"
              alt="right arrow carousel"/></span
        ></template>
        <div
          v-for="(data1, index) in getCarouselCategory"
          :key="index + 'carousel'"
        >
          <product-card
            :index="index"
            :data-product="data1"
            class="w-100"
            :vitrinaid="`vitrina-bolsos ${categoryBolsoSelect}`"
            :vitrinaname="`vitrina bolsos ${categoryBolsoSelect}`"
          />
        </div>
      </carousel>
    </div>
  </div>
</template>
<script>
import ProductCard from '@/components/Templates/ProductCard.vue'

import { GA4ViewItemList } from '@/GA4/events'
import carousel from 'v-owl-carousel'
export default {
  components: { carousel, ProductCard },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      categoryBolsoSelect: '',
      viewVitrina: false,
    }
  },
  computed: {
    getCarouselCategory() {
      let arrayProducts
      arrayProducts = this.data.find(e => e.slug == this.categoryBolsoSelect)
      return arrayProducts ? arrayProducts.items : []
    },
  },
  watch: {
    categoryBolsoSelect() {
      this.viewVitrina = false
      this.validateScrollViewVitrina()
      window.addEventListener('scroll', this.validateScrollViewVitrina)
    },
  },
  mounted() {
    this.categoryBolsoSelect = this.data[0].slug
    window.addEventListener('scroll', this.validateScrollViewVitrina)
  },
  methods: {
    validateScrollViewVitrina() {
      let vitrina = document.getElementById('carousel-bolsos-home')
      if (
        vitrina &&
        !this.viewvitrina &&
        vitrina.getBoundingClientRect().top -
          (window.innerHeight - vitrina.offsetHeight / 2) <=
          0
      ) {
        this.viewVitrina = true
        this.sendGA4()
        window.removeEventListener('scroll', this.validateScrollViewVitrina)
      }
    },
    sendGA4() {
      GA4ViewItemList(
        this.getCarouselCategory,
        `vitrina-bolsos-${this.categoryBolsoSelect}`,
        `vitrina bolsos ${this.categoryBolsoSelect}`,
      )
    },
  },
}
</script>
<style lang="scss">
.__container-bolsos {
  width: 100%;
  background-color: #f0f5f6;
  max-width: 1920px;
  background-image: url('/assets/bg-bolsos-home.svg');
  background-repeat: no-repeat;
  background-position: bottom left;
  position: relative;
  .__content-bolsos {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    padding-top: 1rem;
    padding-bottom: 1rem;
    .content-section-bolsos {
      margin-bottom: 2.5rem;
      .owl-stage {
        margin-right: auto;
        margin-left: auto;
        .button-bolso {
          background-color: white;
          color: #818181;
          border: none;
          margin-right: 0.5rem;
          margin: 0.5rem;
          min-height: 40px;
          min-width: 144px;
        }
        .select-btn {
          position: relative;
          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            left: 0;
            bottom: 0;
            background-color: black;
          }
          &::after {
            content: '';
            position: absolute;
            bottom: 2px;
            left: 50%;
            transform: translateX(-50%);
            border: 3px solid transparent;
            border-bottom: 3px solid black;
          }
        }
      }
      @media (max-width: 768px) {
        margin-right: -10%;
        margin-left: -10%;
        min-width: 90vw;
      }
    }
    .content-carousel-bolsos {
      position: relative;
      width: 100%;
      .prev {
        cursor: pointer;
        position: absolute;
        left: -3rem;
        top: 50%;
        transform: translateY(-50%);
      }
      .next {
        cursor: pointer;
        position: absolute;
        right: -3rem;
        top: 50%;
        transform: translateY(-50%);
      }
      @media (max-width: 768px) {
        .prev {
          left: -2rem;
        }
        .next {
          right: -2rem;
        }
      }
    }
  }
}
</style>
