<template>
  <div class="content-skeleton-bag">
    <div class="col-12 col-md-7 col-xl-8 pe-md-4 mb-5">
      <b-skeleton
        class="mx-auto mx-4 mb-4 title-skeleton"
        width="100%"
        height="60px"
      ></b-skeleton>
      <div class="pt-3">
        <div v-for="index in 2" :key="index" class="mx-auto px-0 mb-2">
          <div class="d-flex">
            <div style="max-width: 168px; width: 100%">
              <b-skeleton-img class="w-100" aspect="168:190"></b-skeleton-img>
            </div>
            <div class="col ps-3 ps-md-4">
              <b-skeleton class="title-skeleton mb-3" width="100%"></b-skeleton>
              <b-skeleton
                class="mb-3 title-skeleton"
                height="20px"
                width="200px"
              ></b-skeleton>
              <b-skeleton
                class="mb-2 title-skeleton"
                height="20px"
                width="110px"
              ></b-skeleton>
              <b-skeleton
                class="mb-3 title-skeleton"
                height="50px"
                width="100px"
              ></b-skeleton>
              <b-skeleton
                class="title-skeleton"
                height="16px"
                width="90px"
              ></b-skeleton>
            </div>
          </div>
          <hr class="w-100" />
        </div>
      </div>
    </div>
    <div class="col-12 col-md-5 col-xl-4 content-skeleton-resumen">
      <b-skeleton
        class="mx-auto mx-4 mb-4"
        width="300px"
        height="35px"
      ></b-skeleton>
      <b-skeleton
        class="mx-auto mx-4 mb-5"
        width="100%"
        height="50px"
      ></b-skeleton>
      <ul class="p-0 mb-4">
        <li class="d-flex justify-content-between">
          <b-skeleton class="mb-4" width="100px" height="24px"></b-skeleton>
          <b-skeleton class="mb-4" width="100px" height="24px"></b-skeleton>
        </li>
        <li class="d-flex justify-content-between">
          <b-skeleton class="mb-4" width="100px" height="24px"></b-skeleton>
          <b-skeleton class="mb-4" width="100px" height="24px"></b-skeleton>
        </li>
        <li class="d-flex justify-content-between">
          <b-skeleton class="mb-4" width="100px" height="24px"></b-skeleton>
          <b-skeleton class="mb-4" width="100px" height="24px"></b-skeleton>
        </li>
      </ul>
      <b-skeleton
        class="mx-auto mb-5 title-skeleton"
        width="100%"
        height="100px"
      ></b-skeleton>
      <b-skeleton
        class="mx-auto title-skeleton"
        width="100%"
        height="50px"
      ></b-skeleton>
    </div>
    <div class="col-12 col-md-7 col-xl-8 pe-md-4">
      <b-skeleton class="ms-2" width="300px" height="30px"></b-skeleton>
      <carousel
        class="content-carousel-products-skeleton"
        :dots="false"
        :nav="false"
        :responsive="{
          0: { items: 1, nav: false, stagePadding: 0 },
          768: { items: 2, nav: false, stagePadding: 0 },
          1300: { items: 3, nav: false, stagePadding: 0 },
        }"
      >
        <template slot="prev"
          ><span class="prev nav-button"
            ><img
              src="@/assets/icons/left-carousel.svg"
              alt="left arrow carousel"/></span
        ></template>
        <template slot="next"
          ><span class="next nav-button"
            ><img
              src="@/assets/icons/right-carousel.svg"
              alt="right arrow carousel"/></span
        ></template>
        <skeleton-card-product v-for="index in 12" :key="index" />
      </carousel>
    </div>
  </div>
</template>
<script>
import SkeletonCardProduct from '@/components/Templates/SkeletonCardProduct'

import carousel from 'v-owl-carousel'
export default {
  components: { carousel, SkeletonCardProduct },
}
</script>
<style lang="scss">
.content-skeleton-bag {
  padding-top: 2rem;
  max-width: 1800px;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 3rem;
  .content-skeleton-resumen {
    position: relative;
    margin-top: -112px;
    z-index: 1;
    background-color: #f5f5f5;
    padding: 1.5rem;
    margin-bottom: auto;
  }
  .content-carousel-products-skeleton {
    margin-top: 1.5rem;
    position: relative;
    .prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -2rem;
      cursor: pointer;
      img {
        filter: opacity(0.4);
      }
    }
    .next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -2rem;
      cursor: pointer;
      img {
        filter: opacity(0.4);
      }
    }
  }
  @media (max-width: 768px) {
    max-width: 90%;
    .content-carousel-products-skeleton {
      .prev {
        left: -1rem;
      }
      .next {
        right: -1rem;
      }
    }
  }
  @media (max-width: 991px) {
    width: calc(100% - 1rem);
  }
  @media (max-width: 768px) {
    width: calc(100% - 2rem);
    margin-right: auto;
    margin-left: auto;
    .content-skeleton-resumen {
      margin-top: 0;
    }
  }
}
</style>
