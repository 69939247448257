<template>
  <div class="d-block d-md-none">
    <div class="d-flex align-items-center w-100 py-2">
      <b-skeleton class="mx-1 mb-0" width="100px" height="16px"></b-skeleton>
      <span style="color: #e6e6e6">></span>
      <b-skeleton class="mx-1 mb-0" width="100px" height="16px"></b-skeleton>
      <span style="color: #e6e6e6">></span>
      <b-skeleton class="mx-1 mb-0" width="100px" height="16px"></b-skeleton>
    </div>
    <carousel
      class="skeleton-movil-carousel"
      style="margin-left: -1rem; margin-right: -1rem"
      :dots="true"
      :lazy-load="true"
      :nav="false"
      :responsive="{ 0: { items: 1, nav: false, stagePadding: 0 } }"
    >
      <b-skeleton-img
        v-for="index in 7"
        :key="index"
        aspect="961:1088"
      ></b-skeleton-img>
    </carousel>
    <div class="d-flex flex-wrap w-100">
      <div class="d-flex mb-4 w-100">
        <div class="col me-3">
          <b-skeleton class="w-100" height="30px"></b-skeleton>
          <b-skeleton class="w-75" height="30px"></b-skeleton>
        </div>
        <b-skeleton
          class="mb-4 mx-auto"
          width="50px"
          height="50px"
        ></b-skeleton>
      </div>
      <b-skeleton class="mb-4" width="150px" height="30px"></b-skeleton>
      <b-skeleton class="mb-4 w-100" height="100px"></b-skeleton>
      <b-skeleton class="mb-4 mx-auto w-75" height="60px"></b-skeleton>
      <div class="w-100 mb-5" style="border-top: 1px solid #e6e6e6">
        <div
          style="border-bottom: 1px solid #e6e6e6"
          class="p-3 d-flex align-items-center"
        >
          <div class="col me-3">
            <b-skeleton class="w-75" height="30px"></b-skeleton>
          </div>
          <b-skeleton class="mx-auto" width="20px" height="20px"></b-skeleton>
        </div>
        <div
          style="border-bottom: 1px solid #e6e6e6"
          class="p-3 d-flex align-items-center"
        >
          <div class="col me-3">
            <b-skeleton class="w-75" height="30px"></b-skeleton>
          </div>
          <b-skeleton class="mx-auto" width="20px" height="20px"></b-skeleton>
        </div>
        <div
          style="border-bottom: 1px solid #e6e6e6"
          class="p-3 d-flex align-items-center"
        >
          <div class="col me-3">
            <b-skeleton class="w-75" height="30px"></b-skeleton>
          </div>
          <b-skeleton class="mx-auto" width="20px" height="20px"></b-skeleton>
        </div>
      </div>
      <div v-for="index in 3" :key="index" class="w-100 mb-3">
        <b-skeleton-img aspect="961:1088"></b-skeleton-img>
      </div>
      <div class="px-2 col-12 mx-auto mt-4 mb-5">
        <b-skeleton
          class="mb-4 mx-auto"
          width="200px"
          height="30px"
        ></b-skeleton>
        <b-skeleton class="mb-2 mx-auto" height="16px"></b-skeleton>
        <b-skeleton class="mb-2 mx-auto" height="16px"></b-skeleton>
        <b-skeleton class="mb-5 mx-auto" height="16px"></b-skeleton>
        <b-skeleton class="mb-5 mx-auto w-100" height="120px"></b-skeleton>
        <div class="w-100" style="border-top: 1px solid #e6e6e6">
          <div
            v-for="index in 4"
            :key="index"
            class="d-flex flex-wrap w-100 p-4"
            style="border-bottom: 1px solid #e6e6e6"
          >
            <div class="col mb-4">
              <b-skeleton class="" width="150px" height="30px"></b-skeleton>
              <b-form-rating
                v-model="val"
                readonly
                show-clear
                no-border
                inline
                variant="danger"
                icon-empty="heart"
                icon-half="heart-half"
                icon-full="heart-fill"
                class="px-0 py-2"
                color="#e6e6e6"
                precision="1"
              ></b-form-rating>
              <b-skeleton class="" width="100px" height="16px"></b-skeleton>
            </div>
            <div class="col-12 ms-auto">
              <b-skeleton class="w-100" height="16px"></b-skeleton>
              <b-skeleton class="w-100" height="16px"></b-skeleton>
              <b-skeleton class="w-100" height="16px"></b-skeleton>
              <b-skeleton class="w-75" height="16px"></b-skeleton>
            </div>
          </div>
        </div>
        <div class="d-flex w-100 mt-4 justify-content-center">
          <div class="d-flex mt-1">
            <div>
              <b-skeleton
                width="20px"
                height="30px"
                class="mx-1 mb-1"
              ></b-skeleton>
              <b-skeleton width="20px" height="2px" class="mx-1"></b-skeleton>
            </div>
            <b-skeleton width="20px" height="30px" class="mx-1"></b-skeleton>
          </div>
          <b-skeleton
            width="40px"
            height="40px"
            class="mx-2"
            style="border-radius: 50%"
          ></b-skeleton>
        </div>
      </div>
      <skeleton-carousel />
    </div>
  </div>
</template>
<script>
import SkeletonCarousel from '@/components/Templates/SkeletonCarousel'

import carousel from 'v-owl-carousel'
export default {
  components: { carousel, SkeletonCarousel },
  data() {
    return {
      val: 5,
    }
  },
}
</script>
<style lang="scss">
.skeleton-movil-carousel {
  margin-bottom: 1.5rem;
  .owl-dots {
    position: absolute;
    bottom: 0.3rem;
    left: 50%;
    transform: translateX(-50%);
    .owl-dot {
      span {
        background-color: white;
        // box-shadow: 0 3px 6px #00000040;
        height: 5px;
        width: 5px;
      }
      &.active {
        span {
          background-color: #818181;
        }
      }
    }
  }
}
</style>
