<template>
  <div class="info-clover">
    <div class="col-12 col-md-4 mb-4 my-md-0">
      <div class="content-info pb-0 pb-md-5 px-md-4">
        <div class="pe-auto col-md-7 content-img-info">
          <img src="@/assets/icons/info-clover-1.svg" alt="" />
        </div>
        <h3 class="w-100 mt-3 position-relative me-auto">
          Libre de crueldad animal
        </h3>
        <p class="position-relative">
          Todos nuestros bolsos están fabricados en materiales que no contienen
          pieles animales.
        </p>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-4 my-md-0">
      <div class="content-info pb-0 pb-md-5 px-md-4">
        <div class="pe-auto col-md-7 content-img-info">
          <img src="@/assets/icons/info-clover-2.svg" alt="" />
        </div>
        <h3 class="w-100 mt-3 position-relative me-auto">
          Envíos a toda Colombia
        </h3>
        <p class="position-relative">
          Colombia es territorio Clover y por eso llegamos a 800 municipios del
          territorio nacional.
        </p>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-4 my-md-0">
      <div class="content-info pb-0 pb-md-5 px-md-4">
        <div class="pe-auto col-md-7 content-img-info">
          <img src="@/assets/icons/info-clover-3.svg" alt="" />
        </div>
        <h3 class="w-100 mt-3 position-relative me-auto">
          Diferentes medios de pago
        </h3>
        <p class="position-relative">
          Desde efectivo hasta pagos con tarjeta de crédito, así son las
          diferentes maneras de pagar.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
