<template>
  <div
    class="item-product d-flex flex-wrap"
    :style="`width: ${parseInt(100 / gridStore)}%;`"
  >
    <div class="container-imgs-product">
      <a :href="estrategiaCatlogo.url_analytics" @click="clicEstrategia">
        <img
          class="w-100 d-none d-md-flex"
          :src="estrategiaCatlogo.banner_desktop"
          :alt="estrategiaCatlogo.nombre"
        />
        <img
          class="img-product d-flex d-md-none"
          :src="estrategiaCatlogo.banner_movil"
          :alt="estrategiaCatlogo.nombre"
        />
      </a>
    </div>
    <div class="p-3 w-100 text-center">
      <p class="fw-bold mb-2">{{ estrategiaCatlogo.nombre }}</p>
      <div v-html="estrategiaCatlogo.descripcion"></div>
      <a
        style="text-decoration: underline"
        :href="estrategiaCatlogo.url_analytics"
        @click="clicEstrategia"
        ><b>Ver mas</b></a
      >
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { GA4SelectPromotion } from '@/GA4/events'
export default {
  props: {
    gridStore: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapState('products', ['estrategiaCatlogo']),
  },
  methods: {
    clicEstrategia() {
      GA4SelectPromotion({
        nameCreative: `card estrategia ${this.estrategiaCatlogo.nombre}`,
        slot: 'card-estrategia-store',
        id: `card-estrategia-store-${this.estrategiaCatlogo.nombre}`,
        name: this.estrategiaCatlogo.nombre,
        items: [],
      })
    },
  },
}
</script>
